import { ELEMENT_TYPE_STRING } from 'constants';
import { ELEMENT_TYPE_CURRENCY, ELEMENT_TYPE_DATE, ELEMENT_TYPE_ENUMERATED } from 'constants';
import { Grid } from '@mui/material';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import * as React from 'react';
import { FormElementComponent } from 'components/FormElementComponent';
import { DateElementComponent } from 'components/DateElementComponent';
import { EnumeratedElementComponent } from 'components/EnumeratedElementComponent';
import { CurrencyElementComponent } from 'components/CurrencyElementComponent';
import {
  ELEMENT_TYPE_BINARY,
  ELEMENT_TYPE_Float,
  ELEMENT_TYPE_Integer,
  ELEMENT_TYPE_MultiENUMERATED
} from '../constants';
import { BinaryElementComponent } from '../components/BinaryElementComponent';
import { FloatElementComponent } from '../components/FloatElementComponent';
import { IntegerElementComponent } from '../components/IntegerElementComponent';
import { req001 } from '../constants/req001';
import { req002 } from '../constants/req002';
import { req007 } from '../constants/req007';
import { req003 } from '../constants/req003';
import { req004 } from '../constants/req004';
import { req005 } from '../constants/req005';
import { req006 } from '../constants/req006';
import { req008 } from '../constants/req008';
import { req009 } from '../constants/req009';
import { req010 } from '../constants/req010';
import { req011 } from '../constants/req011';
import { req012 } from '../constants/req012';
import { req013 } from '../constants/req013';
import { req014 } from '../constants/req014';
import { req015 } from '../constants/req015';
import { MultiEnumeratedElementComponent } from '../components/MultiEnumeratedElementComponent';
import moment from 'moment';

// eslint-disable-next-line react/prop-types
export const RenderForm = ({ formDataConfig, xs }) => {
  // console.log("let's test my default value object in RenderForm",myDefaultValuesObject);
  // const { control } = useFormContext();
  let dataElementArray = [];
  // console.info("formDataConfigsubmissions",formDataConfig);
  // eslint-disable-next-line react/prop-types
  if (formDataConfig.isDisplayed === true) {
    // console.info("formDataConfigsubmissions",formDataConfig);
    // eslint-disable-next-line react/prop-types
    dataElementArray = formDataConfig.data;
  }
  if (dataElementArray.length === 0) {
    return <p>No elements to render.</p>; // Return a default message
  }

  return (
    dataElementArray
      // .filter((inputDataObject) => inputDataObject.isDisplayed !== false)
      .map((inputDataObject) => {
        // .filter(inputDataObject => inputDataObject.isDisplayed !== false)
        // if (inputDataObject.isDisplayed === false) {
        //   return null;
        // }
        const { isDisplayed } = inputDataObject;
        let element;
        switch (inputDataObject.type) {
          case ELEMENT_TYPE_STRING:
            // element = FormElementComponent(inputDataObject);
            element = (
              <FormElementComponent inputDataObject={inputDataObject} isDisplayed={isDisplayed} />
            );
            break;
          case ELEMENT_TYPE_DATE:
            // element = DateElementComponent(inputDataObject);
            element = (
              <DateElementComponent inputDataObject={inputDataObject} isDisplayed={isDisplayed} />
            );
            break;
          case ELEMENT_TYPE_ENUMERATED:
            // element = EnumeratedElementComponent(inputDataObject);
            element = (
              <EnumeratedElementComponent
                inputDataObject={inputDataObject}
                isDisplayed={isDisplayed}
              />
            );
            break;
          case ELEMENT_TYPE_MultiENUMERATED:
            element = (
              <MultiEnumeratedElementComponent
                inputDataObject={inputDataObject}
                isDisplayed={isDisplayed}
              />
            );
            break;
          case ELEMENT_TYPE_CURRENCY:
            // element = CurrencyElementComponent(inputDataObject);
            element = (
              <CurrencyElementComponent
                inputDataObject={inputDataObject}
                isDisplayed={isDisplayed}
              />
            );

            break;
          case ELEMENT_TYPE_BINARY:
            // element = BinaryElementComponent(inputDataObject);
            element = (
              <BinaryElementComponent inputDataObject={inputDataObject} isDisplayed={isDisplayed} />
            );
            break;
          case ELEMENT_TYPE_Float:
            // element = FloatElementComponent(inputDataObject);
            element = (
              <FloatElementComponent inputDataObject={inputDataObject} isDisplayed={isDisplayed} />
            );
            break;
          case ELEMENT_TYPE_Integer:
            // element = IntegerElementComponent(inputDataObject);
            element = (
              <IntegerElementComponent
                inputDataObject={inputDataObject}
                isDisplayed={isDisplayed}
              />
            );
            break;

          default:
          // code block
        }
        return (
          <Grid item sx={{ padding: '3px' }} xs={xs || 6} key={inputDataObject.id}>
            {element}
          </Grid>
        );
      })
  );
};

export const sanitizeString = (name) => {
  return name.replace(/[\s/]/g, '');
};
export const getUniqueFormElementName = (formElementObject) => {
  return `${formElementObject.family}_${formElementObject.id}`;
};
export const registerFormElement = (inputDataObject) => {
  const { id } = inputDataObject;
  // console.log("id's of different elements", id);
  switch (inputDataObject.family) {
    case 'tombstone':
      // console.log("tombstone in registerformElement",`tombstone.${id}`)
      return `tombstone.${id}`;
    case 'resourcing':
      return `tombstone.resourcing.${id}`;
    case 'risk':
      return `tombstone.risk.${id}`;
    case 'staging':
      return `tombstone.staging.${id}`;
    case 'submission':
      return `submission.${id}`;
    case 'evaluation':
      return `submission.evaluation.${id}`;
    case 'contract':
      return `submission.contract.${id}`;
    case 'contractAmendment':
      // return `submission.contractAmendment.${inputDataObject.elementId - 1}.${id}`;
      // return `submission.contractAmendment[${inputDataObject.elementId - 1}].${id}`;
      return `submission.contract.contractAmendment[${inputDataObject.elementId}].${id}`;
    default:
  }
};
// eslint-disable-next-line no-unused-vars
export const getRequestNumberToBeRendered = (currentFormState) => {
  // if (!currentFormState) {
  //   return null;
  // }
  // console.log('LETS TEST FORM STATE', currentFormState);
  const DE005Value = currentFormState['tombstone']['DE005'];
  const DE009Value = currentFormState['tombstone']['DE009'];
  const DE014Value = currentFormState['tombstone']['DE014'];
  const DE015Value = currentFormState['tombstone']['DE015'];
  const DE016Value = currentFormState['tombstone']['DE016'];
  //
  // console.info('DE005Value', DE005Value);
  // console.info('DE009Value', DE009Value);
  // console.info('DE014Value', DE014Value);
  // console.info('DE015Value', DE015Value);
  // console.info('DE016Value', DE016Value);

  // eslint-disable-next-line no-constant-condition
  if (
    DE005Value === 7 &&
    DE009Value === 9 &&
    DE014Value === 8 &&
    (DE015Value === 'N' || DE015Value === 'Y') &&
    (DE016Value === 'Y' || DE016Value === 'N')
  ) {
    return 'REQ_001';
  }
  if (
    DE005Value === 7 &&
    DE009Value === 24 &&
    DE014Value === 8 &&
    (DE015Value === 'N' || DE015Value === 'Y') &&
    (DE016Value === 'Y' || DE016Value === 'N')
  ) {
    return 'REQ_002';
  }
  if (
    DE005Value === 7 &&
    DE009Value === 25 &&
    DE014Value === 8 &&
    (DE015Value === 'N' || DE015Value === 'Y') &&
    (DE016Value === 'Y' || DE016Value === 'N')
  ) {
    return 'REQ_003';
  }
  if (
    DE005Value === 7 &&
    DE009Value === 26 &&
    DE014Value === 8 &&
    (DE015Value === 'N' || DE015Value === 'Y') &&
    (DE016Value === 'Y' || DE016Value === 'N')
  ) {
    return 'REQ_004';
  }
  if (
    DE005Value === 5 &&
    DE009Value === 9 &&
    DE014Value === 2 &&
    (DE015Value === 'N' || DE015Value === 'Y') &&
    (DE016Value === 'Y' || DE016Value === 'N')
  ) {
    return 'REQ_005';
  }
  if (
    DE005Value === 5 &&
    DE009Value === 24 &&
    DE014Value === 2 &&
    (DE015Value === 'N' || DE015Value === 'Y') &&
    (DE016Value === 'Y' || DE016Value === 'N')
  ) {
    return 'REQ_006';
  }
  if (
    DE005Value === 5 &&
    DE009Value === 25 &&
    DE014Value === 2 &&
    (DE015Value === 'N' || DE015Value === 'Y') &&
    (DE016Value === 'Y' || DE016Value === 'N')
  ) {
    return 'REQ_007';
  }
  if (
    DE005Value === 5 &&
    DE009Value === 26 &&
    DE014Value === 2 &&
    (DE015Value === 'N' || DE015Value === 'Y') &&
    (DE016Value === 'Y' || DE016Value === 'N')
  ) {
    return 'REQ_008';
  }
  if (
    DE005Value === 5 &&
    (DE009Value === 64 ||
      DE009Value === 65 ||
      DE009Value === 66 ||
      DE009Value === 67 ||
      DE009Value === 82) &&
    DE014Value === 8 &&
    (DE015Value === 'N' || DE015Value === 'Y') &&
    (DE016Value === 'Y' || DE016Value === 'N')
  ) {
    return 'REQ_009';
  }
  if (
    DE005Value === 5 &&
    DE009Value === 62 &&
    DE014Value === 8 &&
    (DE015Value === 'N' || DE015Value === 'Y') &&
    (DE016Value === 'Y' || DE016Value === 'N')
  ) {
    return 'REQ_010';
  }
  if (
    DE005Value === 5 &&
    DE009Value === 75 &&
    DE014Value === 8 &&
    (DE015Value === 'N' || DE015Value === 'Y') &&
    (DE016Value === 'Y' || DE016Value === 'N')
  ) {
    return 'REQ_011';
  }
  if (
    DE005Value !== 0 &&
    DE009Value !== 0 &&
    DE014Value !== 0 &&
    DE015Value === 'N' &&
    DE016Value === 'N'
  ) {
    return 'REQ_012';
  }
  if (
    DE005Value !== 0 &&
    DE009Value !== 0 &&
    DE014Value !== 0 &&
    DE015Value === 'Y' &&
    DE016Value === 'N'
  ) {
    return 'REQ_013';
  }
  if (
    DE005Value !== 0 &&
    DE009Value !== 0 &&
    DE014Value !== 0 &&
    DE015Value === 'N' &&
    DE016Value === 'Y'
  ) {
    return 'REQ_014';
  }
  if (
    DE005Value !== 0 &&
    DE009Value !== 0 &&
    DE014Value !== 0 &&
    DE015Value === 'Y' &&
    DE016Value === 'Y'
  ) {
    return 'REQ_015';
  }
  return null;
};
// eslint-disable-next-line no-unused-vars
export const getFormStateDataFromAPIResponse = (apiResponse) => {
  const currentFormState = {};
  return currentFormState;
};
// eslint-disable-next-line no-unused-vars
export const getAPIRequestFromFormStateData = (currentFormState) => {
  const apiRequestInputData = {};
  return apiRequestInputData;
};
export const areDataElementsToDisplay = (elementArray) => {
  // console.log('lets test element Array', elementArray);
  if (elementArray) {
    return elementArray.some((el) => el.isDisplayed === true);
  }
  return false;
};
export const getFormDataDisplayStatus = (currentFormState, allTabsData) => {
  const requestNumberToBeRendered = getRequestNumberToBeRendered(currentFormState);
  // console.log("let's test request number to be rendered", requestNumberToBeRendered);
  const requirementLookupObject = {
    REQ_001: req001,
    REQ_002: req002,
    REQ_003: req003,
    REQ_004: req004,
    REQ_005: req005,
    REQ_006: req006,
    REQ_007: req007,
    REQ_008: req008,
    REQ_009: req009,
    REQ_010: req010,
    REQ_011: req011,
    REQ_012: req012,
    REQ_013: req013,
    REQ_014: req014,
    REQ_015: req015
  };
  // some logic to update the isDisplayed true or false based on above object
  for (const formTabName in requirementLookupObject[requestNumberToBeRendered]) {
    // console.info('formTabname', formTabName);
    for (const formElementId in requirementLookupObject[requestNumberToBeRendered][formTabName]) {
      if (
        requirementLookupObject[requestNumberToBeRendered][formTabName][formElementId][
          'isDisplayed'
        ] === true
      ) {
        // console.info("testing if condition");
        if (allTabsData[formTabName]['isDisplayed'] === false) {
          allTabsData[formTabName]['isDisplayed'] = true;
        }
        const toBeUpdatedIndex = allTabsData[formTabName]['data'].findIndex((formElement) => {
          return formElement.id === formElementId;
        });
        // console.info("TOBEUPDATEDINDEX",toBeUpdatedIndex,formElementId);
        allTabsData[formTabName]['data'][toBeUpdatedIndex]['isDisplayed'] = true;
        // console.log("let's test IsDisplayed property",allTabsData[formTabName]["data"][toBeUpdatedIndex])
      }
      // const toBeUpdatedIndex = allTabsData[formTabName]['data'].findIndex((formElement) => {
      //   return formElement.id === formElementId;
      // });
      // allTabsData[formTabName]['data'][toBeUpdatedIndex]['isDisplayed'] = true;

      //False condition
      if (
        requirementLookupObject[requestNumberToBeRendered][formTabName][formElementId][
          'isDisplayed'
        ] === false
      ) {
        const toBeUpdatedIndex = allTabsData[formTabName]['data'].findIndex((formElement) => {
          return formElement.id === formElementId;
        });
        allTabsData[formTabName]['data'][toBeUpdatedIndex]['isDisplayed'] = false;
      }
    }
  }

  return allTabsData;
};
export const resetForm = async (methods, mySubmissionDefaultValues) => {
  // const { isValid } = await methods.trigger();
  // console.log('lets test get values of submission', methods.getValues());
  // console.log('lets also test isDirty', methods.formState.isDirty);
  // console.log('lets test form validity', isValid);

  //START OF FOR LOOP
  // for (const family in mySubmissionDefaultValues) {
  //   for (const element in mySubmissionDefaultValues[family]) {
  //     if (element !== 'contract' && element !== 'evaluation' && element !== 'teamMembers') {
  //       methods.setValue(`submission.${element}`, mySubmissionDefaultValues[family][element], {
  //         shouldDirty: false
  //       });
  //     }
  //     if (element === 'contract') {
  //       for (const contractID in mySubmissionDefaultValues[family]['contract']) {
  //         methods.setValue(
  //           `submission.contract.${contractID}`,
  //           mySubmissionDefaultValues[family]['contract'][contractID],
  //           { shouldDirty: false }
  //         );
  //       }
  //     }
  //     if (element === 'evaluation') {
  //       for (const evaluationID in mySubmissionDefaultValues[family]['evaluation']) {
  //         methods.setValue(
  //           `submission.evaluation.${evaluationID}`,
  //           mySubmissionDefaultValues[family]['evaluation'][evaluationID],
  //           { shouldDirty: false }
  //         );
  //       }
  //     }
  //   }
  // }
  //END  OF FOR LOOP
  // const currentTeamList = methods.getValues('submission.teamMembers');
  // const filteredCurrentTeamList = currentTeamList.filter((row) => row.id === 123);
  // methods.reset({ defaultValues: mySubmissionDefaultValues });
  methods.reset(mySubmissionDefaultValues);
  // methods.setValue('submission.teamMembers', []);
  // methods.unregister('submission.teamMembers.0.DE060');
  // console.log('lets test get values of submission after for loop', methods.getValues());
  // console.log(
  //   'lets test mydefaultvalues values of submission after for loop',
  //   mySubmissionDefaultValues
  // );
  //
  // console.log('lets also test isDirty after for loop', methods.formState.isDirty);
  // console.log('lets also see dirtyFields', methods.formState.dirtyFields);
  // methods.reset({}, { keepDirty: false });
};
export const removeTimeFromDates = (obj) => {
  for (let key in obj) {
    let value = obj[key];
    if (typeof value === 'string' && value.includes('T') && value.includes('Z')) {
      let dateOnly = value.slice(0, 10);
      obj[key] = dateOnly;
    } else if (typeof value === 'object') {
      removeTimeFromDates(value);
    }
  }
};
export const handleValidationChecker = (regex, value, createError, path, type) => {
  if (type === 'currency') {
    if (!regex.test(value)) {
      // const regexForDecimalHandling
      if (/^-/.test(value)) {
        return createError({
          message: 'Only positive number allowed.',
          path
        });
      } else if (!value.includes('.') && value.length > 11) {
        return createError({
          message: 'Can have maximum of 11 digits.',
          path
        });
      } else if (!/^\d{0,11}(\.\d+)?$/.test(value)) {
        return createError({
          message:
            'Must have at most 11 digits before the decimal point and maximum of 2 digits after the decimal point',
          path
        });
      } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
        return createError({
          message:
            'Must have at most 11 digits before the decimal point and maximum of 2 digits after the decimal point',
          path
        });
      } else {
        return createError({
          message: 'Can have maximum of 11 digits.',
          path
        });
      }
    }
  }
  return true;
};
export const handleValidationCheckerContractAmendment = (regex, value, createError, path, type) => {
  if (type === 'currency') {
    if (!regex.test(value)) {
      if (!value.includes('.') && value.length > 11) {
        return createError({
          message: 'Can have a maximum of 11 digits.',
          path
        });
      } else if (!/^-?\d{0,11}(\.\d+)?$/.test(value)) {
        // Updated regex to allow negative values
        return createError({
          message:
            'Must have at most 11 digits before the decimal point and a maximum of 2 digits after the decimal point.',
          path
        });
      } else if (!/^-?\d+(\.\d{1,2})?$/.test(value)) {
        // Updated regex to allow negative values
        return createError({
          message:
            'Must have at most 11 digits before the decimal point and a maximum of 2 digits after the decimal point.',
          path
        });
      } else {
        return createError({
          message: 'Can have a maximum of 11 digits.',
          path
        });
      }
    }
  }
  return true;
};

export const handleFloatValidationChecker = (regex, value, createError, path, type) => {
  // const regex = /^\$?((\d){0,11})(\.\d{1,2})?$/;
  // const regex = /^\d{0,11}(?:\.\d{1,2})?$/;
  if (type === 'float') {
    if (!regex.test(value)) {
      // const regexForDecimalHandling
      // if (/^-/.test(value)) {
      //   return createError({
      //     message: 'Only positive number allowed.',
      //     path
      //   });
      // } else
      // if (!value.includes('.') && value.length > 4) {
      //   return createError({
      //     message: 'Can have maximum of 4 digits.',
      //     path
      //   });
      // }
      if (
        !value.includes('.') &&
        ((value.includes('-') && value.length > 5) || (!value.includes('-') && value.length > 4))
      ) {
        return createError({
          message: 'Can have maximum of 4 digits.',
          path
        });
      } else if (!/^-?\d{0,4}(\.\d+)?$/.test(value)) {
        return createError({
          message:
            'Must have at most 4 digits before the decimal point and maximum of 2 digits after the decimal point',
          path
        });
      } else if (!/^-?\d+(\.\d{1,2})?$/.test(value)) {
        return createError({
          message:
            'Must have at most 4 digits before the decimal point and maximum of 2 digits after the decimal point',
          path
        });
      } else {
        return createError({
          message: 'Can have maximum of 4 digits.',
          path
        });
      }
    }
  }
  return true;
};
export const handleIntValidationChecker = (
  regex = /^\d{0,5}$/,
  value,
  createError,
  path,
  type,
  message = 'Must have at most 5 digits.'
) => {
  if (type === 'integer') {
    if (!regex.test(value)) {
      // const regexForDecimalHandling
      if (/^-/.test(value)) {
        return createError({
          message: 'Only positive number allowed.',
          path
        });
      } else if (/^\d+$/.test(value)) {
        return createError({
          message: message,
          path
        });
      } else {
        return createError({
          message: 'Invalid Integer: must be a valid number.',
          path
        });
      }
    }
  }
  return true;
};
export const handleIntValidationCheckerContractAmendment = (
  // regex = /^\d{0,5}$/,
  regex = /^-?\d{0,5}$/,
  value,
  createError,
  path,
  type,
  message = 'Must have at most 5 digits.',
  isNegativeAllowed = true
) => {
  if (type === 'integer') {
    if (!regex.test(value)) {
      if (/^-/.test(value) && !isNegativeAllowed) {
        return createError({
          message: 'Only positive number allowed.',
          path
        });
      } else if (/^-?\d+$/.test(value)) {
        return createError({
          message: message,
          path
        });
      } else {
        return createError({
          message: 'Invalid Integer: must be a valid number.',
          path
        });
      }
    }
  }
  return true;
};
// export const executeDeleteOrUndelete = async (procId, deleteFlag) => {
//   if (deleteFlag) {
//     return await execute('DELETE', protectedResources.apiCreateList.getUndeleteTombstone(procId));
//   } else {
//     return await execute('DELETE', protectedResources.apiCreateList.getDeleteTombstone(procId));
//   }
// };
export const formatPrincipalDatesRecursively = (obj) => {
  const formattedObj = {};
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value instanceof Date) {
        formattedObj[key] = moment(value).format('YYYY-MM-DD');
      } else if (typeof value === 'object' && value !== null) {
        formattedObj[key] = formatPrincipalDatesRecursively(value);
      } else {
        formattedObj[key] = value;
      }
    }
  }
  return formattedObj;
};
export const formatSubmissionDatesRecursively = (obj) => {
  const formattedObj = {};
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value instanceof Date) {
        formattedObj[key] = moment(value).format('YYYY-MM-DD');
      } else if (Array.isArray(value)) {
        formattedObj[key] = value;
      } else if (typeof value === 'object' && value !== null) {
        formattedObj[key] = formatSubmissionDatesRecursively(value);
      } else {
        formattedObj[key] = value;
      }
    }
  }
  return formattedObj;
};
