import { RenderForm } from '../../../utils/helper';
import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  StyledBoxContainer,
  StyledCircle,
  StyledCircleContainer,
  StyledGridContainer,
  StyledGridItem,
  StyledHeaderBox
} from '../commonStyles/styled';

function Staging({ data }) {
  // const { control } = useFormContext();
  // const [stagingGroup1, setStagingGroup1] = useState({
  //   ...data,
  //   data: data.data.slice(0, 1)
  // });
  // const [stagingGroup2, setStagingGroup2] = useState({
  //   ...data,
  //   data: data.data.slice(1, 3)
  // });
  // const [stagingGroup3, setStagingGroup3] = useState({
  //   ...data,
  //   data: data.data.slice(3, 5)
  // });
  // const [stagingGroup4, setStagingGroup4] = useState({
  //   ...data,
  //   data: data.data.slice(5, 7)
  // });
  // const [stagingGroup5, setStagingGroup5] = useState({
  //   ...data,
  //   data: data.data.slice(7)
  // });
  const stagingGroup1 = {
    ...data,
    data: data.data.slice(0, 2)
  };
  const stagingGroup2 = {
    ...data,
    data: data.data.slice(2, 4)
  };
  const stagingGroup3 = {
    ...data,
    data: data.data.slice(4, 6)
  };
  const stagingGroup4 = {
    ...data,
    data: data.data.slice(6, 8)
  };
  const stagingGroup5 = {
    ...data,
    data: data.data.slice(8)
  };

  return (
    <>
      {/*<div> I am from Stagging</div>*/}
      {/*{RenderForm(data)}*/}
      {/*{<RenderForm formDataConfig={data} />}*/}

      {/*<Grid container*/}
      {/*      style={{ padding: '5px', border:'1px solid blue' }}*/}
      {/*>*/}
      {/*    <RenderForm formDataConfig={data} />*/}
      {/*</Grid>*/}
      {/*<Typography variant='h5'>Staging</Typography>*/}

      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          {/*<Typography variant="h2">Required for Procurement Creation</Typography>*/}
          <StyledBoxContainer>
            <StyledHeaderBox />
            <Typography variant="h2">Staging</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={stagingGroup1} />}
      </StyledGridContainer>
      {/*<Divider sx={{mt:'1rem',mb:'1rem'}}/>*/}
      {/*<Typography variant='h5'>Document Development Phase</Typography>*/}
      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          {/*<Typography variant="h2">Required for Procurement Creation</Typography>*/}
          <StyledBoxContainer>
            <StyledCircleContainer>
              <StyledCircle />
            </StyledCircleContainer>
            <Typography variant="h3" sx={{ fontSize: '18px' }}>
              Document Development Phase
            </Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={stagingGroup2} />}
      </StyledGridContainer>

      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          {/*<Typography variant="h2">Required for Procurement Creation</Typography>*/}
          <StyledBoxContainer>
            <StyledCircleContainer>
              <StyledCircle />
            </StyledCircleContainer>
            <Typography variant="h3" sx={{ fontSize: '18px' }}>
              Market Phase
            </Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={stagingGroup3} />}
      </StyledGridContainer>

      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          {/*<Typography variant="h2">Required for Procurement Creation</Typography>*/}
          <StyledBoxContainer>
            <StyledCircleContainer>
              <StyledCircle />
            </StyledCircleContainer>
            <Typography variant="h3" sx={{ fontSize: '18px' }}>
              Evaluation Phase
            </Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={stagingGroup4} />}
      </StyledGridContainer>

      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          {/*<Typography variant="h2">Required for Procurement Creation</Typography>*/}
          <StyledBoxContainer>
            <StyledCircleContainer>
              <StyledCircle />
            </StyledCircleContainer>

            <Typography variant="h3" sx={{ fontSize: '18px' }}>
              Award Phase
            </Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={stagingGroup5} />}
      </StyledGridContainer>
    </>
  );
}

export default Staging;
Staging.propTypes = {
  data: PropTypes.object
};
