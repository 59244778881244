import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import UndoIcon from '@mui/icons-material/Undo';
// import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
// import AddCircleOutlined from '@mui/icons-material/AddCircleOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useContext } from 'react';
import { UserContext, USER_TYPE_SUPER } from 'features/auth/userContext';

function createData(vendorName, dataEntryCompleteFlag, deletedFlag, comments, subId) {
  return {
    vendorName,
    dataEntryCompleteFlag,
    deletedFlag,
    comments,
    subId
  };
}

const headCells = [
  {
    id: 'vendorName',
    numeric: false,
    disablePadding: true,
    label: 'Vendor Name'
  },
  {
    id: 'dataEntryCompleteFlag',
    numeric: true,
    disablePadding: false,
    label: 'Data Entry Complete'
  },
  {
    id: 'deletedFlag',
    numeric: true,
    disablePadding: false,
    label: 'Deleted Flag'
  },
  {
    id: 'comments',
    numeric: false,
    disablePadding: false,
    label: 'Comments'
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all submissions'
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired
};

function EnhancedTableToolbar(props) {
  const { numSelected, toggleDrawer, resetFormHandler, selected, navbarDeleteClickHandler } = props;
  const userInfo = useContext(UserContext);
  // const getDisabledState = () => {
  //   if (procData && procData.tombstone) {
  //     return Boolean(procData.tombstone['deleteFlg']);
  //   }
  //   return false;
  // };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}>
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          Submissions Summary
        </Typography>
      )}

      {numSelected > 0 && userInfo.userType == USER_TYPE_SUPER ? (
        <>
          <Tooltip title="Delete Submission">
            <Button
              variant="outlined"
              id="delete"
              sx={(theme) => ({
                color: theme.palette.error.main,
                mr: '10px'
              })}
              onClick={(e) => {
                // console.log('lets see selected', selected);
                navbarDeleteClickHandler(selected, e.currentTarget.id);
              }}
              startIcon={<DeleteIcon />}>
              Delete
            </Button>
          </Tooltip>
          <Tooltip title="Undelete Submission">
            <Button
              variant="outlined"
              id="undelete"
              onClick={(e) => {
                // console.log('lets see selected', selected);
                navbarDeleteClickHandler(selected, e.currentTarget.id);
              }}
              startIcon={<UndoIcon />}>
              Undelete
            </Button>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Add Submission">
          {/*<span>*/}
          <Button
            variant="outlined"
            onClick={(e) => {
              toggleDrawer('right', true)(e);
              resetFormHandler();
            }}
            // disabled={getDisabledState()}
            startIcon={<AddIcon />}>
            ADD
          </Button>
          {/*</span>*/}

          {/* <IconButton>
            <AddCircleOutlined />
          </IconButton> */}
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  toggleDrawer: PropTypes.func,
  resetFormHandler: PropTypes.func,
  selected: PropTypes.array,
  navbarDeleteClickHandler: PropTypes.func
  // procData: PropTypes.object
};
const dense = false;

function SubmissionList({
  toggleDrawer,
  resetFormHandler,
  procData,
  updateSubmissionId,
  navbarDeleteClickHandler
}) {
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    let rows1 = [];
    if (
      procData &&
      procData.tombstone &&
      procData.tombstone.submission &&
      procData.tombstone.submission.length > 0
    ) {
      rows1 = procData.tombstone.submission.map((submissionData) => {
        return createData(
          `${submissionData['DE051']}`,
          submissionData['DE053'] === 'Y',
          Boolean(submissionData['deleteFlg']),
          `${submissionData['DE058']}`,
          `${submissionData['subId']}`
        );
      });
      setRows(rows1);
    }
  }, [procData]);
  // const getDisabledState = () => {
  //   if (procData && procData.tombstone) {
  //     return Boolean(procData.tombstone['deleteFlg']);
  //   }
  //   return false;
  // };

  const handleSelectAllClick = (event) => {
    // console.log('event.target.checked', event.target.checked);
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.subId);
      // console.log('lets print new selected', newSelected);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const visibleRows = React.useMemo(
  //   () => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
  //   []
  // );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          toggleDrawer={toggleDrawer}
          resetFormHandler={resetFormHandler}
          numSelected={selected.length}
          selected={selected}
          navbarDeleteClickHandler={navbarDeleteClickHandler}
          // procData={procData}
        />
        {procData?.tombstone?.submission?.length > 0 && (
          <>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.subId);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // const isDisabled = getDisabledState();

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.vendorName)}
                        // role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        // disabled={getDisabledState()}
                        key={row.subId}
                        // selected={isItemSelected}
                        // sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.subId)}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            cursor: 'pointer',
                            maxWidth: '100%',
                            wordBreak: 'break-word',
                            overflow: 'hidden'
                            // border: '1px solid blue'
                          }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          onClick={(e) => {
                            toggleDrawer('right', true)(e);
                            updateSubmissionId(row.subId);
                            resetFormHandler();
                          }}>
                          {row.vendorName}
                        </TableCell>
                        <TableCell sx={{ width: '200px' }} align="left">
                          <Chip
                            size="small"
                            color={row.dataEntryCompleteFlag ? 'success' : 'default'}
                            label={row.dataEntryCompleteFlag ? 'Yes' : 'No'}
                          />
                        </TableCell>
                        <TableCell sx={{ width: '150px' }} align="left">
                          <Chip
                            size="small"
                            color={row.deletedFlag ? 'success' : 'default'}
                            label={row.deletedFlag ? 'Yes' : 'No'}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip title={row.comments}>
                            <Typography
                              noWrap
                              sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '30rem'
                              }}>
                              {row.comments}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/*{emptyRows > 0 && (*/}
                  {/*  <TableRow*/}
                  {/*    style={{*/}
                  {/*      height: (dense ? 33 : 53) * emptyRows*/}
                  {/*    }}>*/}
                  {/*    <TableCell colSpan={6} />*/}
                  {/*  </TableRow>*/}
                  {/*)}*/}
                </TableBody>
              </Table>
            </TableContainer>
            {/*<TablePagination*/}
            {/*  rowsPerPageOptions={[5, 10, 25]}*/}
            {/*  component="div"*/}
            {/*  count={rows.length}*/}
            {/*  rowsPerPage={rowsPerPage}*/}
            {/*  page={page}*/}
            {/*  onPageChange={handleChangePage}*/}
            {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
            {/*/>*/}
          </>
        )}
      </Paper>
    </Box>
  );
}

export default SubmissionList;

SubmissionList.propTypes = {
  toggleDrawer: PropTypes.func,
  resetFormHandler: PropTypes.func,
  procData: PropTypes.object,
  updateSubmissionId: PropTypes.func,
  navbarDeleteClickHandler: PropTypes.func
};
