import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';
import { alpha } from '@mui/system';

export const StyledFixedNavBar = styled(Paper)`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  padding: 1rem;
  top: 0;
  border-radius: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  //flex-grow: 1;
  //background: orange;
  //border: 1px solid green;
  z-index: 999;
`;
export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.6)
}));
