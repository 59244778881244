import styled from '@emotion/styled';
import { Box, InputAdornment, TextField } from '@mui/material';
import { alpha } from '@mui/system';

// export const StyledInputAdornment = styled(InputAdornment)`
//   position: absolute;
//   bottom: 20%;
//   right:2%;
//
// `;

export const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  position: 'absolute',
  bottom: '10%',
  right: '2%',
  '& .MuiTypography-root': {
    color: alpha(theme.palette.common.black, 0.87)
  }
}));
export const StyledLabelBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%'
}));
export const StyledChildBox = styled(Box)(() => ({
  flex: 1,
  // maxWidth: 'calc(100% - 24px)',
  // maxWidth: 'calc(100% - 0.5px)',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
  // color: error ? 'green' : alpha(theme.palette.common.black, 0.6)
}));
export const StyledTextField = styled(TextField)`
  & textarea::-webkit-scrollbar {
    display: none;
  }
  & textarea {
    paddingright: 30px;
  }
`;
