export const contractAmendmentArray = [
  {
    id: 'DE222',
    name: 'Contract Amendment Approval Request (CAAR) Number',
    family: 'contractAmendment',
    type: 'Integer',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: 3
    },
    description:
      "The sequential number of the amendment for the contract/agreement. The first amendment being '1', the second '2', etc.",
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE223',
    name: 'Contract Amendment Description',
    family: 'contractAmendment',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000
      }
    },
    description: 'The description of the amendment per Section A3 of the CAAR form, or equivalent.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE224',
    name: 'Contract Amendment Business Unit Lead',
    family: 'contractAmendment',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 100
      }
    },
    description:
      'The name of the IO personnel associated with the initial request to amend the contract/agreement.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE225',
    name: 'Contract Value Increases/Decreases to Date ($)',
    family: 'contractAmendment',
    type: 'Currency',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "The aggregated value of all changes to the contract/agreement's value, excluding the current change's value, as cited within Section B1's 'Previous Increase/Decrease' field of the CAAR form. Excluding taxes.",
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE226',
    name: 'Change in Contract Value ($)',
    family: 'contractAmendment',
    type: 'Currency',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "The total value of the change in contract/amendment value as cited within Section B1's 'Amount of New Increase/Decrease' field of the CAAR form. Excluding taxes.",
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE227',
    name: 'Net Change in Value from Base Contract Value (%)',
    family: 'contractAmendment',
    type: 'Integer',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: 4
    },
    description:
      "The aggregated change in the contract/agreement's value expressed as a percentage of the Original Contract/Agreement Value, as cited within Section B1's '% Chg' field of the CAAR form.",
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE228',
    name: 'Amended Contract Value ($)',
    family: 'contractAmendment',
    type: 'Currency',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "The current contract/agreement value net all approved amendments as cited within Section B1's 'Revised Total' field of the CAAR form. Excluding taxes.",
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE229',
    name: 'Procurement Approver',
    family: 'contractAmendment',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "The name of the Procurement personnel who has approved the contract/agreement amendment as cited within Section D's 'Signature 3' field of the CAAR form.",
    hasDescription: true,
    isDisplayed: true,
    list: [
      {
        id: 1,
        name: 'Low'
      },
      {
        id: 2,
        name: 'Elevated'
      },
      {
        id: 3,
        name: 'High'
      }
    ]
  },
  {
    id: 'DE230',
    name: 'Contract Amendment Documents Received Date',
    family: 'contractAmendment',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description:
      'The date on which a CAAR form was fist submitted for action by the Procurement department.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE231',
    name: 'Contract Amendment Documents Completion Date',
    family: 'contractAmendment',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      },
      sequence: true
    },
    description:
      'The date on which the CAAR form was deemed materially complete by Procurement personnel.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE232',
    name: 'Contract Amendment Documents Procurement Approval Date',
    family: 'contractAmendment',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      },
      sequence: true
    },
    description:
      "The date on which Procurement personnel approved the CAAR form as cited within Section D's 'Signature 3' field of the CAAR form.",
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE234',
    name: 'Contract Amendment Filed By',
    family: 'contractAmendment',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The name of the Procurement personnel responsible for filing the CAAR form within the record file.',
    hasDescription: true,
    isDisplayed: true,
    list: [
      {
        id: 1,
        name: 'Low'
      },
      {
        id: 2,
        name: 'Elevated'
      },
      {
        id: 3,
        name: 'High'
      }
    ]
  },
  {
    id: 'DE235',
    name: 'Contract Administrator Comments',
    family: 'contractAmendment',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000
      }
    },
    hasDescription: true,
    description:
      'Use this space to provide any necessary comment or insight regarding the Contract Amendment; this may include rationales for contract amendment, delays, etc.',
    isDisplayed: true
  },
  {
    id: 'DE236',
    name: 'Amendment Lead Required (Y/N)',
    family: 'contractAmendment',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "A binary flag (Yes or No) indicating whether an Amendment Lead is required as a result of the contract/agreement amendment. Flag 'Yes' in the event that a Lead is required, otherwise select 'No'.",
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE237',
    name: 'Targeted Amendment Completion Date',
    family: 'contractAmendment',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      },
      sequence: true
    },
    description:
      'The anticipated final date on which work will be undertaken by the Amendment Lead.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE238',
    name: 'Amendment Lead Assignment Date',
    family: 'contractAmendment',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      },
      sequence: true
    },
    description:
      'The date on which the Amendment Lead was assigned to undertake work associated with the contract/agreement amendment.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE239',
    name: 'Procurement Management Comments',
    family: 'contractAmendment',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000
      }
    },
    hasDescription: true,
    description:
      'Use this space to provide any necessary comment or insight regarding the Amendment; this may include rationales for amending agreement strategy, delays, etc.',
    isDisplayed: true
  },
  {
    id: 'DE240',
    name: 'Amendment Lead',
    family: 'contractAmendment',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description: 'The departmental personnel assigned to conduct the contract/agreement amendment.',
    hasDescription: true,
    isDisplayed: true,
    list: [
      {
        id: 1,
        name: 'Low'
      },
      {
        id: 2,
        name: 'Elevated'
      },
      {
        id: 3,
        name: 'High'
      }
    ]
  },
  {
    id: 'DE241',
    name: 'Amendment Lead Start Date',
    family: 'contractAmendment',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      },
      sequence: true
    },
    description:
      'The earliest date on which the Amendment Lead undertook work associated with the contract/agreement amendment.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE233',
    name: 'Contract Amendment Execution Date',
    family: 'contractAmendment',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      },
      sequence: true
    },
    description:
      'The date on which the amendment(s) to the contract were formally executed by Contracting Authority and the Vendor.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE242',
    name: 'Amendment Completion Date',
    family: 'contractAmendment',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      },
      sequence: true
    },
    description:
      'The date on which the Amendment Lead completed all work on the contract/agreement amendment.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE243',
    name: 'Amendment Lead Comments',
    family: 'contractAmendment',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000
      }
    },
    hasDescription: true,
    description:
      'Use this space to provide any necessary comment or insight regarding the Amendment; this may include rationales for Amendment, delays, etc.',
    isDisplayed: true
  }
];
