import { Typography } from '@mui/material';
import { RenderForm } from '../../../utils/helper';
import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledBoxContainer,
  StyledCircle,
  StyledCircleContainer,
  StyledGridContainer,
  StyledGridItem,
  StyledHeaderBox
} from '../commonStyles/styled';

function Resourcing({ data }) {
  // const { control } = useFormContext();

  // const [procurementLeadGroup, setProcurementLeadGroup] = useState({
  //   ...data,
  //   data: data.data.slice(0, 2)
  // });
  // const [procurementResourcesGroup, setProcurementResourcesGroup] = useState({
  //   ...data,
  //   data: data.data.slice(2)
  // });
  const procurementLeadGroup = {
    ...data,
    data: data.data.slice(0, 3)
  };
  // const procurementLeadAssignmentGroup = {
  //   ...data,
  //   data: data.data.slice(1, 2)
  // };
  const procurementResourcesGroup = {
    ...data,
    data: data.data.slice(3)
  };
  return (
    <>
      {/*<Grid container*/}
      {/*      style={{ padding: '5px' }}*/}
      {/*>*/}
      {/*    <RenderForm formDataConfig={data} />*/}
      {/*</Grid>*/}
      {/*<Box sx={{ padding: '8px' }}>*/}
      {/*  <Typography variant="h2">Resourcing</Typography>*/}
      {/*</Box>*/}
      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledHeaderBox />
            <Typography variant="h2">Resourcing</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
      </StyledGridContainer>

      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledCircleContainer>
              <StyledCircle />
            </StyledCircleContainer>
            <Typography variant="h3">Procurement Lead</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={procurementLeadGroup} />}
        {/*{<RenderForm formDataConfig={procurementLeadAssignmentGroup} xs={12} />}*/}
      </StyledGridContainer>
      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledCircleContainer>
              <StyledCircle />
            </StyledCircleContainer>
            <Typography variant="h3">Broader Procurement Resources</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={procurementResourcesGroup} />}
      </StyledGridContainer>
    </>
  );
}

export default Resourcing;
Resourcing.propTypes = {
  data: PropTypes.object
};
