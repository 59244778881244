import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import './styles.css';

export default function ImportModalInProgressView() {
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress color="info" />
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Processing
        </Typography>
      </Box>
    </React.Fragment>
  );
}
