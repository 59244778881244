import styled from '@emotion/styled';
import { Button, DialogContentText } from '@mui/material';

export const StyledDialogContentTextHeader = styled(DialogContentText)(() => ({
  fontWeight: '600',
  fontSize: '24px',
  lineHeight: '28px',
  color: '#181818'
}));

export const StyledDialogContentTextBody = styled(DialogContentText)(() => ({
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'rgba(0,0,0,0.87)'
}));
export const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.info.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.info.main, // Keep the same background color on hover
    boxShadow: 'none' // Remove the hover box shadow
    // Add any other hover styles you want to disable
  }
}));
