import styled from '@emotion/styled';
import { Box, Button, Tab, Tabs } from '@mui/material';
import { alpha } from '@mui/system';

export const StyledBox = styled(Box)(({ theme }) => ({
  background: alpha(theme.palette.primary.main, 1),
  color: alpha(theme.palette.common.white, 1),
  padding: '9px 2rem',
  display: 'flex',
  alignItems: 'center',
  height: '60px'
}));

export const StyledTabsBox = styled(Box)(() => ({
  minWidth: '90vw',
  maxWidth: '90vw',
  zIndex: 1000
  // borderColor: 'divider'
  // background: alpha(theme.palette.common.black, 0.6),
  // padding: '0px 2rem'
  // color: 'red'
}));
export const StyledTabs = styled(Tabs)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.common.black, 0.6)}`,
  padding: '0px 2rem'
}));
export const StyledTab = styled(Tab)(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.6),
  '&.Mui-selected': {
    color: theme.palette.info.main
  }
}));
export const StyledStickyBox = styled(Box, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== 'top' && prop !== 'bottom'
})(({ top, bottom, theme }) => {
  if (top !== undefined && bottom !== undefined) {
    return `
    position: sticky;
    top: ${top};
    bottom: ${bottom};
    z-index: 99999;
    background-color: ${theme.palette.common.white};
   `;
  } else if (top !== undefined) {
    return `
    position: sticky;
    top: ${top};
    z-index: 99999;
    background-color: ${theme.palette.common.white};
   `;
  } else if (bottom !== undefined) {
    return `
    position: sticky;
    bottom: ${bottom};
    z-index: 99999;
    background-color:#F7F7F7;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    height:55px;
    display:flex;
    align-items:center;
    justify-content:flex-end;
   `;
  }
});
export const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.info.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.info.main, // Keep the same background color on hover
    boxShadow: 'none' // Remove the hover box shadow
    // Add any other hover styles you want to disable
  }
}));
