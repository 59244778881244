import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { StyledButton, StyledDialogContentTextBody, StyledDialogContentTextHeader } from './styled';

export default function SubmissionAlertComponent({
  activeStep,
  popupStatus,
  popupStatusMessage,
  setPopupStatus,
  changeTabToTombstone
}) {
  const [open, setOpen] = React.useState(popupStatus);

  const handleClose = (event, reason) => {
    // console.log('lets print event', event);
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
    setPopupStatus(false);
    if (event.target.id === 'principal') {
      changeTabToTombstone(0);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
        <DialogContent>
          <StyledDialogContentTextHeader id="alert-dialog-description">
            {' '}
            Warning
          </StyledDialogContentTextHeader>
          {popupStatusMessage && (
            <StyledDialogContentTextBody>
              To move to submission step please fill Procurement/Requisition RFx Type, Service Type,
              Procurement/Requisition Method, Technical Evaluation and Financial Evaluation.
            </StyledDialogContentTextBody>
          )}
          <StyledDialogContentTextBody style={{ marginTop: '10px' }}>
            Please save your changes before proceeding to Submission/Evaluation/Contract.
          </StyledDialogContentTextBody>
        </DialogContent>
        <DialogActions>
          {/*<Button onClick={handleClose}>Cancel</Button>*/}
          <Button id="cancel" variant="outlined" onClick={handleClose} autoFocus>
            Cancel
          </Button>
          {activeStep !== 0 && popupStatusMessage && (
            <StyledButton id="principal" variant="outlined" onClick={handleClose} autoFocus>
              Go To Principal Step
            </StyledButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
SubmissionAlertComponent.propTypes = {
  activeStep: PropTypes.number,
  popupStatus: PropTypes.bool,
  popupStatusMessage: PropTypes.bool,
  setPopupStatus: PropTypes.func,
  changeTabToTombstone: PropTypes.func
};
