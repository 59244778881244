import { AppBar, Menu, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { alpha } from '@mui/system';

// export const AppNavBar = styled(AppBar)`
//   height: 60px;
//   background-color: aqua;
//   //overflow: hidden;
//   //display: flex;
//   //justify-content: center;
// `;
export const AppNavBar = styled(AppBar)(() => ({
  // background: alpha(theme.palette.primary.main, 1),
  height: '60px'
}));

export const StyledTypography = styled(Typography)`
  text-align: start;
  width: 50%;
  //border: 2px solid blue;
  //margin: 2% auto;
  margin-right: 30%;
  margin-left: 30%;
  margin-top: 2%;
`;

// export const LightTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.palette.common.white,
//         color: "rgba(0, 0, 0, 0.87)",
//         boxShadow: theme.shadows[1],
//         fontSize: 16,
//     },
// }));
export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));
