import { Box, Button, Typography } from '@mui/material';

import { StyledFixedNavBar, StyledTypography } from './styled';

import PropTypes from 'prop-types';

import { UserContext, USER_TYPE_SUPER } from 'features/auth/userContext';

import { useContext } from 'react';

function FixedNavBar({ cancelClickHandler, deleteClickHandler, procData }) {
  const userInfo = useContext(UserContext);

  const getDisabledState = () => {
    if (procData && procData.tombstone) {
      return Boolean(procData.tombstone['deleteFlg']);
    }

    return false;
  };

  return (
    <>
      <StyledFixedNavBar elevation={3}>
        <Box sx={{ flexGrow: 1 }}>
          <StyledTypography sx={{ flexGrow: 1 }}> Procurement/Requisition No:</StyledTypography>

          <Typography sx={{ flexGrow: 1 }}>
            {procData && procData.tombstone ? procData.tombstone.DE001 : '--'}
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 3 }}>
          <StyledTypography sx={{ flexGrow: 1 }}> Procurement Name:</StyledTypography>

          <Typography sx={{ flexGrow: 1 }}>
            {procData && procData.tombstone ? procData.tombstone.DE002 : '--'}
          </Typography>
        </Box>

        <Box sx={{ p: 1, ml: 'auto' }}>
          {procData && procData.tombstone && procData.tombstone.DE001 && (
            <Button
              sx={{ marginRight: '14px' }}
              variant="text"
              onClick={deleteClickHandler}
              disabled={userInfo.userType != USER_TYPE_SUPER && !getDisabledState() ? true : false}>
              {' '}
              {getDisabledState() ? 'Undelete' : 'Delete'}
            </Button>
          )}

          <Button
            sx={{ marginRight: '14px', border: '1px solid black', zoom: '0.9' }}
            onClick={cancelClickHandler}
            disabled={getDisabledState()}>
            {' '}
            Cancel
          </Button>

          <Button
            sx={{
              background: '#31b0d5',

              borderColor: '#269abc',

              color: 'white',

              '&:hover': {
                background: '#31b0d5',

                borderColor: '#269abc',

                color: 'white'
              }
            }}
            variant="contained"
            type="submit"
            disabled={getDisabledState()}>
            {/*Save*/}

            {procData ? 'Update' : 'Save'}
          </Button>
        </Box>
      </StyledFixedNavBar>
    </>
  );
}

export default FixedNavBar;

FixedNavBar.propTypes = {
  cancelClickHandler: PropTypes.func,

  deleteClickHandler: PropTypes.func,

  procData: PropTypes.object
};
