import { Controller, useFormContext } from 'react-hook-form';
import { TextField, Tooltip } from '@mui/material';
import * as React from 'react';
import { registerFormElement } from '../../utils/helper';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import { NumericFormatCustom } from '../NumericFormatCustom';

export const CurrencyElementComponent = ({ inputDataObject, isDisplayed }) => {
  const { control } = useFormContext();
  const theme = useTheme();

  // const handleLabelClick = () => {
  //     console.log('Label clicked');
  //     console.log('inputRef.current:', inputRef.current);
  //     // inputRef.current.focus();
  //     try {
  //         inputRef.current.focus();
  //     } catch (error) {
  //         console.error('Error focusing input:', error);
  //     }
  // };
  if (!isDisplayed) {
    return null;
  }
  return (
    <>
      {/*<Controller*/}
      {/*  name={registerFormElement(inputDataObject)}*/}
      {/*  control={control}*/}
      {/*  render={({ field: { onChange, value }, fieldState: { error } }) => {*/}
      {/*    return (*/}
      {/*      <FormControl error={!!error} fullWidth>*/}
      {/*        <InputLabel sx={{ pointerEvents: 'pointer' }} htmlFor="outlined-adornment-amount">*/}
      {/*          {*/}
      {/*            <Box*/}
      {/*              sx={{*/}
      {/*                display: 'flex',*/}
      {/*                alignItems: 'center',*/}
      {/*                maxWidth: '100%',*/}
      {/*                color: error*/}
      {/*                  ? theme.palette.error.main*/}
      {/*                  : alpha(theme.palette.common.black, 0.6)*/}
      {/*              }}>*/}
      {/*              <div*/}
      {/*                style={{*/}
      {/*                  flex: 1,*/}
      {/*                  maxWidth: 'calc(100% - 24px)',*/}
      {/*                  overflow: 'hidden',*/}
      {/*                  textOverflow: 'ellipsis'*/}
      {/*                }}>*/}
      {/*                {inputDataObject.name}*/}
      {/*              </div>*/}
      {/*              {inputDataObject.hasDescription && (*/}
      {/*                <Tooltip title={inputDataObject.description}>*/}
      {/*                  <HelpOutlineIcon*/}
      {/*                    fontSize="small"*/}
      {/*                    sx={{ ml: 1, verticalAlign: 'middle', pointerEvents: 'auto' }}*/}
      {/*                  />*/}
      {/*                </Tooltip>*/}
      {/*              )}*/}
      {/*            </Box>*/}
      {/*          }*/}
      {/*        </InputLabel>*/}
      {/*        <OutlinedInput*/}
      {/*          id="outlined-adornment-amount"*/}
      {/*          sx={{*/}
      {/*            color: alpha(theme.palette.common.black, 0.87)*/}
      {/*          }}*/}
      {/*          label={*/}
      {/*            <Box*/}
      {/*              sx={{*/}
      {/*                display: 'flex',*/}
      {/*                alignItems: 'center',*/}
      {/*                maxWidth: '100%'*/}
      {/*              }}>*/}
      {/*              {inputDataObject.name.length > 27 ? (*/}
      {/*                <Tooltip title={inputDataObject.name}>*/}
      {/*                  <Box*/}
      {/*                    sx={{*/}
      {/*                      flex: 1,*/}
      {/*                      maxWidth: 'calc(100% - 24px)',*/}
      {/*                      overflow: 'hidden',*/}
      {/*                      textOverflow: 'ellipsis',*/}
      {/*                      color: error*/}
      {/*                        ? theme.palette.error.main*/}
      {/*                        : alpha(theme.palette.common.black, 0.6)*/}
      {/*                    }}>*/}
      {/*                    {inputDataObject.name}*/}
      {/*                  </Box>*/}
      {/*                </Tooltip>*/}
      {/*              ) : (*/}
      {/*                <Box*/}
      {/*                  sx={{*/}
      {/*                    flex: 1,*/}
      {/*                    color: error*/}
      {/*                      ? theme.palette.error.main*/}
      {/*                      : alpha(theme.palette.common.black, 0.6)*/}
      {/*                  }}>*/}
      {/*                  {inputDataObject.name}*/}
      {/*                </Box>*/}
      {/*              )}*/}
      {/*              {inputDataObject.hasDescription && (*/}
      {/*                <Tooltip title={inputDataObject.description}>*/}
      {/*                  <HelpOutlineIcon fontSize="small" sx={{ ml: 1, verticalAlign: 'middle' }} />*/}
      {/*                </Tooltip>*/}
      {/*              )}*/}
      {/*            </Box>*/}
      {/*          }*/}
      {/*          value={value}*/}
      {/*          inputComponent={NumericFormatCustom}*/}
      {/*          name={inputDataObject.name}*/}
      {/*          onChange={(event) => {*/}
      {/*            onChange(event.target.value);*/}
      {/*          }}*/}
      {/*        />*/}
      {/*        {error?.message && <FormHelperText>{error.message}</FormHelperText>}*/}
      {/*      </FormControl>*/}
      {/*    );*/}
      {/*  }}*/}
      {/*/>*/}
      <Controller
        name={registerFormElement(inputDataObject)}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          // console.log(`lets print value in currency element ${inputDataObject.id}`, typeof value);

          return (
            <TextField
              fullWidth
              autoComplete="off"
              sx={{ m: 0.5 }}
              label={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '100%'
                  }}>
                  <div
                    style={{
                      flex: 1,
                      maxWidth: 'calc(100% - 24px)',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {inputDataObject.name}
                  </div>
                  {inputDataObject.hasDescription && (
                    <Tooltip title={inputDataObject.description}>
                      <HelpOutlineIcon fontSize="small" sx={{ ml: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                  )}
                </div>
              }
              InputLabelProps={{
                style: {
                  pointerEvents: 'auto',
                  color: error ? theme.palette.error.main : alpha(theme.palette.common.black, 0.6)
                },
                shrink: value !== undefined && value !== null
              }}
              InputProps={{
                style: { color: alpha(theme.palette.common.black, 0.87) },
                inputComponent: NumericFormatCustom
              }}
              name={inputDataObject.name}
              variant="outlined"
              // value={value}
              value={value === null ? '' : value}
              // onChange={(event) => {
              //   onChange(event.target.value);
              // }}
              onChange={(e) => {
                // console.log('lets print value in float component', value);
                let myValue;
                if (!e.target.value && e.target.value !== 0 && e.target.value !== '0') {
                  myValue = null;
                } else {
                  myValue = e.target.value;
                }
                onChange(myValue);
              }}
              error={!!error}
              helperText={error ? error.message : null}
            />
          );
        }}
      />
    </>
  );
};
CurrencyElementComponent.propTypes = {
  inputDataObject: PropTypes.object,
  isDisplayed: PropTypes.bool
};
