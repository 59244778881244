import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

export const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      // onValueChange={(values) => {
      //   onChange({
      //     target: {
      //       name: props.name,
      //       value: values.floatValue
      //     }
      //   });
      // }}
      onValueChange={(values) => {
        let { floatValue } = values;
        // console.log("let's see floatvalue in numericformat comp before if ", floatValue);
        // console.log("let's see values in numericformat comp before if ", values);
        if (floatValue === undefined && values.value === '-') {
          // Handle "-" as a special case
          floatValue = '-';
        }
        // console.log("let's see floatvalue in numericformat comp ", floatValue);
        onChange({
          target: {
            name: props.name,
            value: floatValue
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
