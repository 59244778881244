import React from 'react';
// import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
// import { InteractionType } from '@azure/msal-browser';

// import { loginRequest } from 'features/auth/config';
import PropTypes from 'prop-types';
import { UserContext } from 'features/auth/userContext';
import { useContext } from 'react';
import LandingPage from 'pages/landingPage';

export const RouteGuard = ({ children }) => {
  const user = useContext(UserContext);

  return <>{user?.isAuthenticated ? <div>{children}</div> : <LandingPage />}</>;
};
RouteGuard.propTypes = {
  children: PropTypes.node
};
