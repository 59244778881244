import { createContext } from 'react';

export const UserContext = createContext({
  isAuthenticated: false,
  userType: '',
  email: '',
  displayName: ''
});

export const USER_TYPE_SUPER = 'SUPER';
export const USER_TYPE_REGULAR = 'REGULAR';
