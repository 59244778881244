import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearProgressAnimated() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      setProgress(100);
    }, 500);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress color="success" variant="determinate" value={progress} />
    </Box>
  );
}
