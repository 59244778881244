import { Controller, useFormContext } from 'react-hook-form';
import { registerFormElement } from '../../utils/helper';
import { TextField, Tooltip } from '@mui/material';
import * as React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import { NumericFormatCustom } from '../NumericFormatCustom';

export const IntegerElementComponent = ({ inputDataObject, isDisplayed }) => {
  const { control } = useFormContext();
  const theme = useTheme();
  if (!isDisplayed) {
    return null;
  }
  return (
    <>
      <Controller
        name={registerFormElement(inputDataObject)}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            autoComplete="off"
            sx={{ m: 0.5 }}
            label={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '100%'
                }}>
                <div
                  style={{
                    flex: 1,
                    maxWidth: 'calc(100% - 24px)',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                  {inputDataObject.name}
                </div>
                {inputDataObject.hasDescription && (
                  <Tooltip title={inputDataObject.description}>
                    <HelpOutlineIcon fontSize="small" sx={{ ml: 1, verticalAlign: 'middle' }} />
                  </Tooltip>
                )}
              </div>
            }
            InputLabelProps={{
              style: {
                pointerEvents: 'auto',
                color: error ? theme.palette.error.main : alpha(theme.palette.common.black, 0.6)
              },
              shrink: value !== undefined && value !== null
            }}
            InputProps={{
              style: { color: alpha(theme.palette.common.black, 0.87) },
              inputComponent: NumericFormatCustom
            }}
            name={inputDataObject.name}
            variant="outlined"
            // value={value}
            value={value === null ? '' : value}
            // onChange={onChange}
            onChange={(e) => {
              // console.log('lets print value in float component', value);
              let myValue;
              if (!e.target.value && e.target.value !== 0 && e.target.value !== '0') {
                myValue = null;
              } else {
                myValue = e.target.value;
              }
              onChange(myValue);
            }}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
    </>
  );
};
IntegerElementComponent.propTypes = {
  inputDataObject: PropTypes.object,
  isDisplayed: PropTypes.bool
};
