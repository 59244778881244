import { createTheme } from '@mui/material/styles';

const color = 'black';
export const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color
        }
      }
    }
  }
});

export const buttonStyle = { color: 'white', background: '#01a8c4' };
export const headerStyle = {
  background: '#253746',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '10px'
};
export const footerStyle = {
  background: 'white',
  height: '60px',
  display: 'flex',
  bottom: '0px',
  alignItems: 'center',
  paddingLeft: '10px',
  position: 'fixed',
  width: '100%',
  border: '1px solid transparent',
  boxShadow: '1px -2px 20px 0px #aaa5a5'
};
