import { Controller, useFormContext } from 'react-hook-form';
import { registerFormElement } from '../../utils/helper';
import { TextField, Tooltip } from '@mui/material';
import * as React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import { NumericFormatCustom } from '../NumericFormatCustom';

export const FloatElementComponent = ({ inputDataObject, isDisplayed }) => {
  const { control } = useFormContext();
  const theme = useTheme();
  if (!isDisplayed) {
    return null;
  }
  return (
    <>
      <Controller
        // name={getUniqueFormElementName(inputDataObject)}
        name={registerFormElement(inputDataObject)}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          // console.info("error message in float element component",error);
          // console.log('lets print value in float element', inputDataObject.id, typeof value, value);
          // const formValue = value;

          return (
            <TextField
              fullWidth
              autoComplete="off"
              // sx={{ m: 1 }}
              sx={{ m: 0.5 }}
              // type="number"
              // label={inputDataObject.name}
              label={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '100%'
                  }}>
                  <div
                    style={{
                      flex: 1,
                      maxWidth: 'calc(100% - 24px)',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {inputDataObject.name}
                  </div>
                  {inputDataObject.hasDescription && (
                    <Tooltip title={inputDataObject.description}>
                      <HelpOutlineIcon fontSize="small" sx={{ ml: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                  )}
                </div>
              }
              // label={
              //     <div>
              //         {inputDataObject.name}
              //         {inputDataObject.hasDescription && <Tooltip title={inputDataObject.description}>
              //             <HelpOutlineIcon fontSize="small" sx={{ ml: 1,verticalAlign:'middle' }} />
              //         </Tooltip>}
              //
              //     </div>
              // }
              // InputLabelProps={{ style: { pointerEvents: "auto",overflow: "hidden",
              //         textOverflow: "ellipsis",
              //     whiteSpace: "nowrap",
              //     right: "22px", border:"1px dotted blue" } }}
              InputLabelProps={{
                style: {
                  pointerEvents: 'auto',
                  color: error ? theme.palette.error.main : alpha(theme.palette.common.black, 0.6)
                },
                shrink: value !== undefined && value !== null
              }}
              InputProps={{
                style: { color: alpha(theme.palette.common.black, 0.87) },
                inputComponent: NumericFormatCustom
              }}
              name={inputDataObject.name}
              variant="outlined"
              //Value provided to Textfield can't be null
              value={value === null ? '' : value}
              // onChange={(e) => {
              //   // console.log('lets print value in float component', value);
              //   let myValue;
              //   if (!e.target.value && e.target.value !== 0 && e.target.value !== '0' ) {
              //     myValue = null;
              //   } else {
              //     myValue = e.target.value;
              //   }
              //   onChange(myValue);
              // }}

              //Lets Test
              onChange={(e) => {
                // console.log('lets print value in float component before if', e);
                let myValue;
                if (!e.target.value && e.target.value !== 0 && e.target.value !== '0') {
                  myValue = null;
                } else {
                  myValue = e.target.value;
                }
                // console.log('lets print myvalue in float component after if', myValue);
                onChange(myValue);
              }}
              // onChange={(event) => {
              //   const newValue = event.target.value;
              //   let myValue = newValue ? newValue.replace(',', '') : '';
              //   const isInteger = Number.isInteger(Number(myValue));
              //   if (isInteger && myValue !== '') {
              //     console.log('lets print my value in if condition', myValue);
              //     onChange(parseFloat(myValue).toFixed(2));
              //   } else {
              //     console.log('lets print my value in else condition', myValue);
              //     onChange(myValue);
              //   }
              // }}
              error={!!error}
              helperText={error ? error.message : null}
            />
          );
        }}
        // rules={{key:inputDataObject.id}}
      />
    </>
  );
};
FloatElementComponent.propTypes = {
  inputDataObject: PropTypes.object,
  isDisplayed: PropTypes.bool
};
