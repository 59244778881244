import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';

import './styles.css';
import { Typography } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export default function ImportModalRejectReasonView({
  open,
  onrejectResonViewClose,
  rejectReasonList
}) {
  const handleClose = () => {
    onrejectResonViewClose();
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: '10px'
            }}>
            <Typography sx={{ fontSize: '21px', color: 'black', fontWeight: '500' }}>
              Rejected Reason
            </Typography>
            <ClearIcon
              onClick={handleClose}
              sx={{ color: 'black', cursor: 'pointer' }}
              fontSize="large"
            />
          </Box>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 350, border: '1px solid #d6d6d6', boxShadow: 'none' }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Line No.</TableCell>
                  <TableCell>Procurement No.</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Rejected Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rejectReasonList.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.lineNumber}
                    </TableCell>
                    <TableCell>{row.procNumber}</TableCell>
                    <TableCell>{row.message}</TableCell>
                    <TableCell>{row.rejectedValue}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}

ImportModalRejectReasonView.propTypes = {
  open: PropTypes.bool,
  rejectReasonList: PropTypes.array,
  onrejectResonViewClose: PropTypes.func
};
