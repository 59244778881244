import { Box, Button, Typography } from '@mui/material';
import toronto from '../../image/toronto.png';
import IOLogo from '../../image/IO_Logo_White.png';
//import { useNavigate } from 'react-router-dom';
// import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
// import { loginRequest } from 'features/auth/config';

import { protectedResources } from 'features/auth/config';

export default function Logout() {
  //const navigate = useNavigate();
  // const { instance } = useMsal();
  const { loginURLRedirect } = protectedResources.apiCreateList.getAuthConfig();

  const handleLogin = () => {
    window.location.replace(loginURLRedirect);
  };

  // let activeAccount;
  useEffect(() => {
    // if (instance) {
    //   activeAccount = instance.getActiveAccount();
    //   if (activeAccount) {
    //     navigate('/');
    //   }
    // }
    // navigate('/');
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        height: '90vh',
        backgroundImage: `url(${toronto})`,
        objectFit: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        verticalAlign: 'middle',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '0px',
        left: '0px'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'flex-start',
          flexWrap: 'wrap',
          alignItems: 'center'
        }}>
        <Box
          sx={{
            width: '48vh',
            height: '14vh',
            borderRadius: '1vh',
            marginBottom: '4vh',
            backgroundColor: '#253746',
            backgroundImage: `url(${IOLogo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            overflow: 'hidden'
          }}></Box>

        <Typography fontWeight={500} fontSize={'4vh'}>
          You have been logged out successfully!
        </Typography>
        <Button
          sx={{
            color: 'white',
            background: '#01a8c4',
            width: '40vh',
            height: '7vh',
            marginTop: '7vh',
            fontSize: '2vh',
            borderRadius: '1vh'
          }}
          variant="contained"
          onClick={handleLogin}>
          GO TO LOGIN
        </Button>
      </Box>
    </Box>
  );
}
