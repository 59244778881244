export const req007 = {
  submissions: {
    DE051: {
      id: 'DE051',
      family: 'submissions',
      isDisplayed: true
    },
    DE052: {
      id: 'DE052',
      family: 'submissions',
      isDisplayed: true
    },
    DE053: {
      id: 'DE053',
      family: 'submissions',
      isDisplayed: true
    },
    DE054: {
      id: 'DE054',
      family: 'submissions',
      isDisplayed: true
    },

    DE055: {
      id: 'DE055',
      family: 'submissions',
      isDisplayed: true
    },

    DE056: {
      id: 'DE056',
      family: 'submissions',
      isDisplayed: true
    },
    DE057: {
      id: 'DE057',
      family: 'submissions',
      isDisplayed: true
    },
    DE058: {
      id: 'DE058',
      family: 'submissions',
      isDisplayed: true
    },
    DE059: {
      id: 'DE059',
      family: 'submissions',
      isDisplayed: true
    },
    DE060: {
      id: 'DE060',
      family: 'submissions',
      isDisplayed: true
    },
    DE061: {
      id: 'DE061',
      family: 'submissions',
      isDisplayed: true
    },
    DE062: {
      id: 'DE062',
      family: 'submissions',
      isDisplayed: true
    },
    DE063: {
      id: 'DE063',
      family: 'submissions',
      isDisplayed: true
    },
    DE064: {
      id: 'DE064',
      family: 'submissions',
      isDisplayed: false
    },
    DE066: {
      id: 'DE066',
      family: 'submissions',
      isDisplayed: false
    },
    DE067: {
      id: 'DE067',
      family: 'submissions',
      isDisplayed: false
    },
    DE068: {
      id: 'DE068',
      family: 'submissions',
      isDisplayed: false
    },
    DE069: {
      id: 'DE069',
      family: 'submissions',
      isDisplayed: false
    },
    DE070: {
      id: 'DE070',
      family: 'Submission',
      subFamily: 'Evaluation',
      isDisplayed: true
    },
    DE065: {
      id: 'DE065',
      family: 'Evaluation',
      isDisplayed: true
    },
    DE072: {
      id: 'DE072',
      family: 'Evaluation',
      isDisplayed: true
    },
    DE073: {
      id: 'DE073',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE074: {
      id: 'DE074',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE075: {
      id: 'DE075',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE076: {
      id: 'DE076',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE079: {
      id: 'DE079',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE080: {
      id: 'DE080',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE081: {
      id: 'DE081',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE082: {
      id: 'DE082',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE085: {
      id: 'DE085',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE086: {
      id: 'DE086',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE087: {
      id: 'DE087',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE088: {
      id: 'DE088',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE188: {
      id: 'DE188',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE091: {
      id: 'DE091',
      family: 'Evaluation',
      isDisplayed: true
    },
    DE092: {
      id: 'DE092',
      family: 'Evaluation',
      isDisplayed: true
    },
    DE093: {
      id: 'DE093',
      family: 'Evaluation',
      isDisplayed: true
    },
    DE094: {
      id: 'DE094',
      family: 'Evaluation',
      isDisplayed: true
    },
    DE095: {
      id: 'DE095',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE096: {
      id: 'DE096',
      family: 'Evaluation',
      isDisplayed: true
    },
    DE097: {
      id: 'DE097',
      family: 'Evaluation',
      isDisplayed: true
    },
    DE104: {
      id: 'DE104',
      family: 'Evaluation',
      isDisplayed: true
    },
    DE105: {
      id: 'DE105',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE106: {
      id: 'DE106',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE107: {
      id: 'DE107',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE108: {
      id: 'DE108',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE109: {
      id: 'DE109',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE110: {
      id: 'DE110',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE111: {
      id: 'DE111',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE112: {
      id: 'DE112',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE113: {
      id: 'DE113',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE114: {
      id: 'DE114',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE115: {
      id: 'DE115',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE116: {
      id: 'DE116',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE117: {
      id: 'DE117',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE118: {
      id: 'DE118',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE119: {
      id: 'DE119',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE120: {
      id: 'DE120',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE121: {
      id: 'DE121',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE122: {
      id: 'DE122',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE123: {
      id: 'DE123',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE124: {
      id: 'DE124',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE125: {
      id: 'DE125',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE127: {
      id: 'DE127',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE126: {
      id: 'DE126',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE166: {
      id: 'DE166',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE167: {
      id: 'DE167',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE142: {
      id: 'DE142',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE143: {
      id: 'DE143',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE140: {
      id: 'DE140',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE141: {
      id: 'DE141',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE144: {
      id: 'DE144',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE145: {
      id: 'DE145',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE146: {
      id: 'DE146',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE147: {
      id: 'DE147',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE148: {
      id: 'DE148',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE149: {
      id: 'DE149',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE156: {
      id: 'DE156',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE157: {
      id: 'DE157',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE158: {
      id: 'DE158',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE159: {
      id: 'DE159',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE160: {
      id: 'DE160',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE161: {
      id: 'DE161',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE162: {
      id: 'DE162',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE163: {
      id: 'DE163',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE164: {
      id: 'DE164',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE165: {
      id: 'DE165',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE182: {
      id: 'DE182',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE183: {
      id: 'DE183',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE184: {
      id: 'DE184',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE185: {
      id: 'DE185',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE186: {
      id: 'DE186',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE187: {
      id: 'DE187',
      family: 'Evaluation',
      isDisplayed: false
    },
    DE189: {
      id: 'DE189',
      family: 'Contract',
      isDisplayed: true
    },
    DE190: {
      id: 'DE190',
      family: 'Contract',
      isDisplayed: true
    },
    DE191: {
      id: 'DE191',
      family: 'Contract',
      isDisplayed: true
    },
    DE192: {
      id: 'DE192',
      family: 'Contract',
      isDisplayed: false
    },
    DE193: {
      id: 'DE193',
      family: 'Contract',
      isDisplayed: true
    },
    DE194: {
      id: 'DE194',
      family: 'Contract',
      isDisplayed: true
    },
    DE195: {
      id: 'DE195',
      family: 'Contract',
      isDisplayed: true
    },
    DE196: {
      id: 'DE196',
      family: 'Contract',
      isDisplayed: false
    },
    DE197: {
      id: 'DE197',
      family: 'Contract',
      isDisplayed: false
    },
    DE198: {
      id: 'DE198',
      family: 'Contract',
      isDisplayed: false
    },
    DE199: {
      id: 'DE199',
      family: 'Contract',
      isDisplayed: false
    },
    DE200: {
      id: 'DE200',
      family: 'Contract',
      isDisplayed: false
    },
    DE201: {
      id: 'DE201',
      family: 'Contract',
      isDisplayed: false
    },
    DE202: {
      id: 'DE202',
      family: 'Contract',
      isDisplayed: true
    },
    DE203: {
      id: 'DE203',
      family: 'Contract',
      isDisplayed: false
    },
    DE204: {
      id: 'DE204',
      family: 'Contract',
      isDisplayed: false
    },
    DE205: {
      id: 'DE205',
      family: 'Contract',
      isDisplayed: false
    },
    DE206: {
      id: 'DE206',
      family: 'Contract',
      isDisplayed: false
    },
    DE207: {
      id: 'DE207',
      family: 'Contract',
      isDisplayed: false
    },
    DE208: {
      id: 'DE208',
      family: 'Contract',
      isDisplayed: false
    },
    DE209: {
      id: 'DE209',
      family: 'Contract',
      isDisplayed: true
    },
    DE210: {
      id: 'DE210',
      family: 'Contract',
      isDisplayed: false
    }
  }
};
