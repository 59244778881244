const resourcingDataElementArray = [
  {
    id: 'DE022',
    name: 'Procurement/Requisition Lead',
    family: 'resourcing',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    hasDescription: false,
    isDisplayed: true
  },
  {
    id: 'DE023',
    name: 'Procurement Lead Assignment Date',
    family: 'resourcing',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    hasDescription: false,
    isDisplayed: true
  },
  {
    id: 'DE213',
    name: 'Targeted Procurement Lead Assignment Date',
    family: 'resourcing',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {},
    description: `The date on which the resourcing lead has targeted for onboarding a Procurement Lead to the procurement/requisition.`,
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE021',
    name: 'Procurement Leadership Team (PLT) Lead',
    family: 'resourcing',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    hasDescription: false,
    isDisplayed: true
  },
  {
    id: 'DE025',
    name: 'Procurement Directors Team (PDT) Lead',
    family: 'resourcing',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    hasDescription: false,
    isDisplayed: true
  }
];
export default resourcingDataElementArray;
