export const ELEMENT_TYPE_STRING = 'String';
export const ELEMENT_TYPE_DATE = 'date';
export const ELEMENT_TYPE_ENUMERATED = 'Enumerated';
export const ELEMENT_TYPE_MultiENUMERATED = 'MultiEnumerated';
export const ELEMENT_TYPE_CURRENCY = 'Currency';
export const ELEMENT_TYPE_BINARY = 'Binary';
export const ELEMENT_TYPE_Float = 'Float';
export const ELEMENT_TYPE_Integer = 'Integer';
export const NAV_BAR_DIALOG_ALERT = {
  descriptionHeader: 'Are you sure want to leave ?',
  descriptionBody: 'You have unsaved changes on this page.',
  button1Label: 'STAY ON THE PAGE',
  button2Label: 'LEAVE THE PAGE'
};
export const NAV_BAR_DIALOG_DELETE_ALERT = {
  descriptionHeader: 'Delete ?',
  descriptionBody: 'Are you sure, do you want to delete the principal?',
  button1Label: 'DELETE',
  button2Label: 'Cancel'
};
export const NAV_BAR_DIALOG_UNDELETE_ALERT = {
  descriptionHeader: 'Undelete ?',
  descriptionBody: 'Are you sure, do you want to undelete the principal?',
  button1Label: 'Undelete',
  button2Label: 'Cancel'
};
export const NAV_BAR_DIALOG_SUBMISSION_DELETE_ALERT = {
  descriptionHeader: 'Delete ?',
  descriptionBody: 'This action also deletes Evaluation & Contract data. ',
  button1Label: 'DELETE',
  button2Label: 'Cancel'
};
export const NAV_BAR_DIALOG_SUBMISSION_UNDELETE_ALERT = {
  descriptionHeader: 'Undelete ?',
  descriptionBody: 'This action also undeletes Evaluation & Contract data. ',
  button1Label: 'Undelete',
  button2Label: 'Cancel'
};
// export const DIALOG_SUBMISSION_EVALUATION_ALERT = {
//   descriptionHeader: 'Warning ?',
//   descriptionBody: 'This action resets evaluation data. Do you wish to proceed? ',
//   button1Label: 'Proceed',
//   button2Label: 'Cancel'
// };
// export const DIALOG_SUBMISSION_CONTRACT_ALERT = {
//   descriptionHeader: 'Warning ?',
//   descriptionBody: 'This action resets contract data. Do you wish to proceed? ',
//   button1Label: 'Proceed',
//   button2Label: 'Cancel'
// };
export const DIALOG_CONTRACT_AMENDMENT_ALERT = {
  descriptionHeader: 'Warning ?',
  descriptionBody: 'This action deletes all contract amendments. Do you wish to proceed? ',
  button1Label: 'Proceed',
  button2Label: 'Cancel'
};
export const FORM_CHANGE_ALERT = {
  descriptionHeader: 'Warning',
  descriptionBody:
    'The procurement assignment has been changed. This will effectively delete all data entries for Resourcing, Submission, Evaluation and Contract for this procurement. ',
  button1Label: 'Yes',
  button2Label: 'No'
};
export const SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_SUBMISSION = {
  success: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Submission/Evaluation/Contract/Contract Amendment has been Saved Successfully ';
  },
  error: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Please try again ';
  }
};
export const SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_TOMBSTONE = {
  success: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Your record has been saved Successfully ';
  },
  error: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Please try again ';
  }
};
export const SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_TOMBSTONE = {
  success: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Your record has been Updated Successfully ';
  },
  error: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Please try again ';
  }
};
export const SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_SUBMISSION = {
  success: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Submission/Evaluation/Contract/Contract Amendment has been Updated Successfully ';
  },
  error: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Please try again ';
  }
};
export const SEVERITY_WISE_ALERT_MESSAGE_ON_DELETE_TOMBSTONE = {
  success: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Your record has been Deleted Successfully ';
  },
  error: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Please try again ';
  }
};
export const SEVERITY_WISE_ALERT_MESSAGE_ON_DELETE_SUBMISSION = {
  success: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Your record has been Deleted Successfully ';
  },
  error: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Please try again ';
  }
};
export const SEVERITY_WISE_ALERT_MESSAGE_ON_SEARCH = {
  success: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Procurement was found ';
  },
  error: (msg) => {
    if (msg) {
      return msg;
    }
    return 'Your record has not found ';
  }
};
export const CONTRACT_AMENDMENT_ROW = {
  DE222: null,
  DE223: '',
  DE224: '',
  DE225: null,
  DE226: null,
  DE227: null,
  DE228: null,
  DE229: 0,
  DE230: null,
  DE231: null,
  DE232: null,
  DE233: null,
  DE234: 0,
  DE235: '',
  DE236: '',
  DE237: null,
  DE238: null,
  DE239: '',
  DE240: 0,
  DE241: null,
  DE242: null,
  DE243: '',
  deleteFlg: 'N'
};

// export const DE005List = [
//   { id: '1', label: '*Not In List' },
//   { id: '2', label: 'Call-up (Request for Legal Services - RFLS)' },
//   { id: '3', label: 'Call-up (Other)' },
//   { id: '4', label: 'Other (Email or other)' },
//   { id: '5', label: 'Request for Proposals (RFP)' },
//   { id: '6', label: 'Request for Proposals (VOR)' },
//   { id: '7', label: 'Request for Qualifications (RFQ)' },
//   { id: '8', label: 'Request for Qualifications and Standing Offer (RFQSO)' },
//   { id: '9', label: 'Request for Quote (RFQuote)' },
//   { id: '10', label: 'Request for Standing Offer (RFSO)' },
//   { id: '11', label: 'Request for Tender (RFT)' },
//   { id: '12', label: 'Second-Stage Request (SSR)' },
//   { id: '13', label: 'Request for Service (RFS)' }
// ];
// export const DE009List = [
//   { id: '1', label: '*Not In List' },
//   { id: '2', label: 'Appliance Supply and/or Installation' },
//   { id: '3', label: 'Archaeological Assessment' },
//   { id: '4', label: 'Architect of Record' },
//   { id: '5', label: 'Architectural Design' },
//   { id: '6', label: 'Artwork' },
//   { id: '7', label: 'Audit' },
//   { id: '8', label: 'Behavioural Consultant' },
//   { id: '9', label: 'Build-Finance (BF)' },
//   { id: '10', label: 'Building Permit(s)' },
//   { id: '11', label: 'Capital Market Advisory' },
//   { id: '12', label: 'Collaborative and Behavioural Advisory' },
//   { id: '13', label: 'Commissioning Consultant' },
//   { id: '14', label: 'Construction Management (CM)' },
//   { id: '15', label: 'Corporate Training and Courses' },
//   { id: '16', label: 'Cost and Scheduling Consultant' },
//   { id: '17', label: 'Cost Consultant' },
//   { id: '18', label: 'Cost Estimating' },
//   { id: '19', label: 'Cultural Heritage Resource' },
//   { id: '20', label: 'Design Consultant' },
//   { id: '21', label: 'Design Services' },
//   { id: '22', label: 'Design-Bid-Build (DBB)' },
//   { id: '23', label: 'Design-Build (DB)' },
//   { id: '24', label: 'Design-Build-Finance (DBF)' },
//   { id: '25', label: 'Design-Build-Finance-Maintain (DBFM)' },
//   { id: '26', label: 'Design-Build-Finance-Operate-Maintain (DBFOM)' },
//   { id: '27', label: 'Environmental and Geotechnical Consultant' },
//   { id: '28', label: 'Environmental Assessment Advisory' },
//   { id: '29', label: 'Environmental Consultant' },
//   { id: '30', label: 'Facilities Management Advisory' },
//   { id: '31', label: 'Fairness Monitor (FM)' },
//   { id: '32', label: 'Financial and Transaction Process Advisory (FTPA)' },
//   { id: '33', label: 'Furniture Supply' },
//   { id: '34', label: 'General Corporate (Other)' },
//   { id: '35', label: 'General Corporate Advisory' },
//   { id: '36', label: 'General Corporate Support' },
//   { id: '37', label: 'Geotechnical Consultant' },
//   { id: '38', label: 'Hardware' },
//   { id: '39', label: 'Health and Safety Review' },
//   { id: '40', label: 'Heritage Consultant' },
//   { id: '41', label: 'Implementation and Support' },
//   { id: '42', label: 'Independent Certifier (IC)' },
//   { id: '43', label: 'Independent Evaluation' },
//   { id: '44', label: 'Indigenous Advisory' },
//   { id: '45', label: 'Insurance Advisory' },
//   { id: '46', label: 'IT Support' },
//   { id: '47', label: 'Joint Technical Review (JTR)' },
//   { id: '48', label: 'Land Surveying and Ancillary' },
//   { id: '49', label: 'Land Use Planning' },
//   { id: '50', label: 'Lean Consultant' },
//   { id: '51', label: 'Legal Advisory' },
//   { id: '52', label: 'Licenses' },
//   { id: '53', label: 'Market Sounding Advisory' },
//   { id: '54', label: 'Master Planning Consultant' },
//   { id: '55', label: 'Move' },
//   { id: '56', label: 'Natural Heritage Consultant' },
//   { id: '57', label: 'Offboarding' },
//   { id: '58', label: 'Onboarding' },
//   { id: '59', label: "Owner's Engineer" },
//   { id: '60', label: 'Peer Cost Consultant' },
//   { id: '61', label: 'Planning Advisory' },
//   { id: '62', label: 'Planning, Design and Compliance (PDC)' },
//   { id: '63', label: 'Process Advisory' },
//   { id: '64', label: 'Progressive-Design-Bid (pDB)' },
//   { id: '65', label: 'Progressive-Design-Build-Finance (pDBF)' },
//   { id: '66', label: 'Progressive-Design-Build-Finance-Maintain (pDBFM)' },
//   { id: '67', label: 'Progressive-Design-Build-Finance-Operate-Maintain (pDBFOM)' },
//   { id: '68', label: 'Project Management' },
//   { id: '69', label: 'Scheduling Consultant' },
//   { id: '70', label: 'Scope Consultant' },
//   { id: '71', label: 'Software Licenses (including Renewals)' },
//   { id: '72', label: 'Strategic Consultant' },
//   { id: '73', label: 'Supply and Installation' },
//   { id: '74', label: 'Systems and Platforms' },
//   { id: '75', label: 'Technical Advisory (TA)' },
//   { id: '76', label: 'Temporary Staffing' },
//   { id: '77', label: 'Transaction Advisory' },
//   { id: '78', label: 'Translation' },
//   { id: '79', label: 'Value for Money Advisory (VFM)' },
//   { id: '80', label: 'Wayfinding Supply and/or Installation (Signage)' },
//   { id: '81', label: 'Webinar' }
// ];

// export const DE014List = [
//   { id: '1', label: '*Not In List' },
//   { id: '2', label: 'Invitational Competitive' },
//   { id: '3', label: 'Invitational Competitive - Direct Invitational' },
//   {
//     id: '4',
//     label: 'Invitational Competitive - Requisition Pursuant to a Standing Offer Agreement'
//   },
//   { id: '5', label: 'Invitational Competitive - VOR Arrangement (Second-stage) ' },
//   { id: '6', label: 'Non-Competitive - Single Source' },
//   { id: '7', label: 'Non-Competitive - Sole Source' },
//   { id: '8', label: 'Open Competitive' },
//   { id: '9', label: 'Open Competitive - Standing Offer Arrangement' },
//   { id: '10', label: 'Open Competitive - VOR Arrangement (First-stage)' }
// ];
