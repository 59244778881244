import { Controller, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as React from 'react';
import { registerFormElement } from '../../utils/helper';
import { Box, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { StyledDatePicker } from './styled';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';
import { alpha, useTheme } from '@mui/material/styles';
import tombstoneDataElementArray from '../../constants/tombstone';
import resourcingDataElementArray from '../../constants/resourcing';
import stagingDataElementArray from '../../constants/staging';

const myArray = [
  ...tombstoneDataElementArray,
  ...stagingDataElementArray,
  ...resourcingDataElementArray
];

export const DateElementComponent = ({ inputDataObject, isDisplayed }) => {
  const { control, trigger } = useFormContext();
  const theme = useTheme();
  if (!isDisplayed) {
    return null;
  }
  const getDateValue = (value) => {
    // const value = getValues(`tombstone.staging.${inputDataObject.id}`);
    // console.log(`lets check value`, typeof value);
    if (typeof value === 'object') {
      return value;
    } else {
      return parseISO(value);
    }
    // if (inputDataObject.family === 'staging') {
    //   if (value === 'string') {
    //     return parseISO(value);
    //   } else {
    //     return parseISO(value);
    //   }
    // }
    // return value;
  };
  const handleDateChange = async (currentDateFieldName) => {
    // Find the index of the current date field in the DataElementArray
    const currentIndex = myArray.findIndex((element) => element.id === currentDateFieldName);
    // console.log('lets print current index', currentIndex);

    // Check if there is a next date field in the DataElementArray
    if (currentIndex !== -1) {
      // Get the name of the next date field
      // const nextDataElementId = myArray[currentIndex + 1].id;
      // const prevDataElementId = myArray[currentIndex - 1].id;
      // const currentDataElementId = myArray[currentIndex].id;

      // Trigger validation for the next date field
      // trigger(nextDataElementName);
      await trigger(`tombstone.staging.DE027`);
      await trigger(`tombstone.staging.DE028`);
      await trigger(`tombstone.staging.DE029`);
      await trigger(`tombstone.staging.DE030`);
      await trigger(`tombstone.staging.DE031`);
      await trigger(`tombstone.staging.DE032`);
      await trigger(`tombstone.staging.DE033`);
      await trigger(`tombstone.staging.DE034`);
    }
  };
  // function convertToDesiredTimezone(date) {
  //   // Check if the date is valid before attempting conversion
  //   if (!moment(date).isValid()) {
  //     return date; // Or return a default value, depending on your use case
  //   }
  //
  //   // Convert the valid date to the Eastern Time Zone (Canada/Toronto) using Moment.js
  //   const convertedDate = moment(date).tz('America/Toronto');
  //   console.log('Converted Date (Eastern Time Zone):', convertedDate.format('YYYY-MM-DD HH:mm:ss'));
  //   return convertedDate.toDate();
  // }

  return (
    <>
      <Controller
        name={registerFormElement(inputDataObject)}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          // console.log('lets see the value of Date element', value);
          return (
            <LocalizationProvider key={inputDataObject.id} dateAdapter={AdapterDateFns}>
              <StyledDatePicker
                sx={{ m: 0.5 }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                    border: '1px solid yellow'
                  }
                }}
                // label={inputDataObject.name}
                label={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      maxWidth: '100%'
                    }}>
                    {inputDataObject.name.length > 40 ? (
                      <Tooltip title={inputDataObject.name}>
                        <Box
                          sx={{
                            flex: 1,
                            maxWidth: 'calc(100% - 24px)',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: error
                              ? theme.palette.error.main
                              : alpha(theme.palette.common.black, 0.6)
                          }}>
                          {inputDataObject.name}
                        </Box>
                      </Tooltip>
                    ) : (
                      <Box
                        sx={{
                          flex: 1,
                          color: error
                            ? theme.palette.error.main
                            : alpha(theme.palette.common.black, 0.6)
                        }}>
                        {inputDataObject.name}
                      </Box>
                    )}
                    {inputDataObject.hasDescription && (
                      <Tooltip title={inputDataObject.description}>
                        <HelpOutlineIcon
                          fontSize="small"
                          sx={{
                            ml: 1,
                            verticalAlign: 'middle',
                            color: error
                              ? theme.palette.error.main
                              : alpha(theme.palette.common.black, 0.6)
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                }
                value={getDateValue(value)}
                format="yyyy-MM-dd"
                onChange={(newValue) => {
                  // console.log('lets see newValue', newValue);
                  // const convertedDate = convertToDesiredTimezone(newValue);
                  // console.log('lets see newValue', convertedDate);
                  onChange(newValue);
                  // onChange(newValue);
                  if (inputDataObject.validation.isNextDateValid) {
                    handleDateChange(inputDataObject.id);
                  }
                }}
              />
            </LocalizationProvider>
          );
        }}
      />
    </>
  );
};
DateElementComponent.propTypes = {
  inputDataObject: PropTypes.object,
  isDisplayed: PropTypes.bool
};
