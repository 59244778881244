import { areDataElementsToDisplay, RenderForm } from '../../utils/helper';
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import {
  StyledBoxContainer,
  StyledCircle,
  StyledCircleContainer,
  StyledGridContainer,
  StyledGridItem,
  StyledHeaderBox
} from '../../features/procurementAttributes/commonStyles/styled';
import ContractAmendment from 'components/contractAmendment/ContractAmendment';
import { useFieldArray, useFormContext } from 'react-hook-form';
import DialogAlertComponent from '../DialogAlertComponent';
import { DIALOG_CONTRACT_AMENDMENT_ALERT } from '../../constants';

function DrawerTabContract({
  data,
  // resetClickHandler,
  selectedSubmissionId,
  reqFormNumber,
  scrollToBottomHandler,
  resetContractAmendmentHandler,
  savedContractAmendments
}) {
  const [contractGroup, setContractGroup] = useState({
    ...data,
    data: data.data.filter((el) => el.family === 'contract')
  });
  const [contractedVendorGroup, setContractedVendorGroup] = useState(null);
  const [contractDetailsGroup, setContractDetailsGroup] = useState(null);
  const [purchaseOrderGroup, setPurchaseOrderGroup] = useState(null);
  const [contractPOGroup, setContractPOGroup] = useState(null);
  const [contractPOCreationGroup, setContractPOCreationGroup] = useState(null);
  const [contractExtensionGroup, setContractExtensionGroup] = useState(null);
  const [initialRequisitionGroup, setInitialRequisitionGroup] = useState(null);
  const [contractAmendmentDialog, setContractAmendmentDialog] = useState(false);
  const {
    watch,
    control,
    getValues,
    setValue,
    formState: { isDirty }
  } = useFormContext();
  const enabled = watch('submission.contract.DE189');
  // const [myDE189Value, setMyDE189Value] = useState(getValues('submission.contract.DE189'));
  const { remove } = useFieldArray({
    control,
    name: 'submission.contract.contractAmendment'
  });

  useEffect(() => {
    if ((enabled === 'N' || enabled === '') && !selectedSubmissionId) {
      // console.log('I am in useEFFECT SELECTEDSUBMISSION ID', selectedSubmissionId);
      remove();
    }
    //Below condition is useful when the user saves submission and clicks on ADD button couple of times.Now changing state enabled to "N"
    if (
      selectedSubmissionId &&
      isDirty &&
      (enabled === 'N' || enabled === '') &&
      savedContractAmendments &&
      savedContractAmendments.contractAmendment.length === 0
    ) {
      remove();
    }
    const myValues = getValues('submission.contract.contractAmendment');
    if (
      selectedSubmissionId &&
      isDirty &&
      (enabled === 'N' || enabled === '') &&
      savedContractAmendments &&
      savedContractAmendments.contractAmendment.length > 0 &&
      myValues.some((item) => item.deleteFlg === 'N')
    ) {
      setContractAmendmentDialog(() => true);
    }
  }, [enabled, remove]);
  const contractAmendmentDeleteFlag = () => {
    const myValues = getValues('submission.contract.contractAmendment');
    // myValues.forEach((value, index) => {
    //   if (
    //     savedContractAmendments &&
    //     savedContractAmendments.contractAmendment &&
    //     savedContractAmendments.contractAmendment.length > index &&
    //     // savedContractAmendments.contractAmendment[index].DE222 === value.DE222
    //     savedContractAmendments.contractAmendment.some(
    //       (item) => item.contractAmendId === value.contractAmendId
    //     )
    //   ) {
    //     // DE222 is present in savedContractAmendments
    //     setValue(`submission.contract.contractAmendment[${index}].deleteFlg`, 'Y');
    //   } else {
    //     // DE222 is not present in savedContractAmendments
    //     remove(index);
    //   }
    // });
    for (let index = myValues.length - 1; index >= 0; index--) {
      const value = myValues[index];
      if (
        savedContractAmendments &&
        savedContractAmendments.contractAmendment &&
        savedContractAmendments.contractAmendment.length > index &&
        savedContractAmendments.contractAmendment.some(
          (item) => item.contractAmendId === value.contractAmendId
        )
      ) {
        // DE222 is present in savedContractAmendments
        setValue(`submission.contract.contractAmendment[${index}].deleteFlg`, 'Y');
      } else {
        // DE222 is not present in savedContractAmendments
        remove(index);
      }
    }
    resetContractAmendmentHandler();
    // }
  };

  useEffect(() => {
    if (data) {
      setContractGroup(() => ({
        ...data,
        data: data.data.filter((el) => el.family === 'contract')
      }));
    }
  }, [data]);
  useEffect(() => {
    if (contractGroup) {
      // console.log('lets test contract group', contractGroup);
      setContractedVendorGroup(() => ({
        ...contractGroup,
        data: contractGroup.data.slice(0, 1)
      }));
      setContractDetailsGroup(() => ({
        ...contractGroup,
        data: contractGroup.data.slice(1, 9)
      }));
      setPurchaseOrderGroup(() => ({
        ...contractGroup,
        data: contractGroup.data.slice(9, 13)
      }));
      setContractPOGroup(() => ({
        ...contractGroup,
        data: contractGroup.data.slice(13, 14)
      }));
      setContractPOCreationGroup(() => ({
        ...contractGroup,
        data: contractGroup.data.slice(14, 16)
      }));
      setContractExtensionGroup(() => ({
        ...contractGroup,
        data: contractGroup.data.slice(16, 21)
      }));
      setInitialRequisitionGroup(() => ({
        ...contractGroup,
        data: contractGroup.data.slice(21)
      }));
    }
  }, [contractGroup]);
  const deleteContractAmendmentsHandler = () => {
    contractAmendmentDeleteFlag();
    // setMyDE189Value(getValues('submission.contract.DE189'));
    setContractAmendmentDialog(() => false);
  };
  const cancelContractAmendmentsHandler = () => {
    // console.log('lets see myDe189value', myDE189Value);
    setValue(`submission.contract.DE189`, savedContractAmendments['DE189']);
    setContractAmendmentDialog(() => false);
  };

  return (
    <>
      {/*<p>Drawer Tab Contract</p>*/}
      {/*<RenderForm formDataConfig={data}/>*/}
      {/*<Typography variant='h6'>Contract Information</Typography>*/}
      {contractedVendorGroup && areDataElementsToDisplay(contractedVendorGroup.data) && (
        <StyledGridContainer container style={{ flex: 1 }}>
          <StyledGridItem item xs={12}>
            <StyledBoxContainer>
              <StyledHeaderBox />
              <Typography variant="h2" sx={{ mr: 'auto' }}>
                {' '}
                Contract Information
              </Typography>
              {/* <Button
                variant="outlined"
                id="contract"
                onClick={(e) => {
                  resetClickHandler(e);
                }}>
                {' '}
                Reset
              </Button> */}
            </StyledBoxContainer>
          </StyledGridItem>
          {/*<Grid item xs={12} sx={{ padding: '8px' }}>*/}
          {/*  <Typography variant="h5"> </Typography>*/}
          {/*</Grid>*/}
          {<RenderForm formDataConfig={contractedVendorGroup} />}
        </StyledGridContainer>
      )}
      {contractDetailsGroup && areDataElementsToDisplay(contractDetailsGroup.data) && (
        <StyledGridContainer container style={{ flex: 1 }}>
          <StyledGridItem item xs={12}>
            <StyledBoxContainer>
              <StyledCircleContainer>
                <StyledCircle />
              </StyledCircleContainer>
              <Typography variant="h3">Contract Details</Typography>
            </StyledBoxContainer>
          </StyledGridItem>
          {/*<Grid item xs={12} sx={{ padding: '8px' }}>*/}
          {/*  <Typography variant="h3" sx={{ fontSize: '18px' }}>*/}
          {/*    Contract Details*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          <RenderForm formDataConfig={contractDetailsGroup} />
        </StyledGridContainer>
      )}
      {purchaseOrderGroup && areDataElementsToDisplay(purchaseOrderGroup.data) && (
        <StyledGridContainer container style={{ flex: 1 }}>
          <StyledGridItem item xs={12}>
            <StyledBoxContainer>
              <StyledCircleContainer>
                <StyledCircle />
              </StyledCircleContainer>
              <Typography variant="h3">Purchase Order Details</Typography>
            </StyledBoxContainer>
          </StyledGridItem>
          {/*<Grid item xs={12} sx={{ padding: '8px' }}>*/}
          {/*  <Typography variant="h3" sx={{ fontSize: '18px' }}>*/}
          {/*    Purchase Order Details*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          <RenderForm formDataConfig={purchaseOrderGroup} />
          {/*<div>{JSON.stringify(purchaseOrderGroup.data)}</div>*/}
        </StyledGridContainer>
      )}
      {contractPOGroup && areDataElementsToDisplay(contractPOGroup.data) && (
        <Grid container style={{ padding: '5px', flex: 1 }}>
          <RenderForm formDataConfig={contractPOGroup} />
        </Grid>
      )}
      {contractPOCreationGroup && areDataElementsToDisplay(contractPOCreationGroup.data) && (
        <Grid container style={{ padding: '5px', flex: 1 }}>
          <RenderForm formDataConfig={contractPOCreationGroup} />
        </Grid>
      )}
      {contractExtensionGroup && areDataElementsToDisplay(contractExtensionGroup.data) && (
        <StyledGridContainer container style={{ padding: '5px', flex: 1 }}>
          {/*<Grid item xs={12} sx={{ padding: '8px' }}>*/}
          {/*  <Typography variant="h3" sx={{ fontSize: '18px' }}>*/}
          {/*    Contract Extension Details*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          <StyledGridItem item xs={12}>
            <StyledBoxContainer>
              <StyledCircleContainer>
                <StyledCircle />
              </StyledCircleContainer>
              <Typography variant="h3">Contract Extension Details</Typography>
            </StyledBoxContainer>
          </StyledGridItem>
          <RenderForm formDataConfig={contractExtensionGroup} />
        </StyledGridContainer>
      )}
      {initialRequisitionGroup && areDataElementsToDisplay(initialRequisitionGroup.data) && (
        <Grid container style={{ padding: '5px', flex: 1 }}>
          <RenderForm formDataConfig={initialRequisitionGroup} />
        </Grid>
      )}
      {enabled === 'Y' &&
        ['REQ_010', 'REQ_011', 'REQ_012', 'REQ_013', 'REQ_014', 'REQ_015'].includes(
          reqFormNumber
        ) && (
          <Box>
            <ContractAmendment
              data={data}
              scrollToBottomHandler={scrollToBottomHandler}
              // contractAmendmentDeleteFlag={contractAmendmentDeleteFlag}
              savedContractAmendments={savedContractAmendments}
              resetContractAmendmentHandler={resetContractAmendmentHandler}
            />
          </Box>
        )}
      {contractAmendmentDialog && (
        <DialogAlertComponent
          descriptionHeader={DIALOG_CONTRACT_AMENDMENT_ALERT.descriptionHeader}
          descriptionBody={DIALOG_CONTRACT_AMENDMENT_ALERT.descriptionBody}
          popupStatus={contractAmendmentDialog}
          button1Handler={deleteContractAmendmentsHandler}
          button1Label={DIALOG_CONTRACT_AMENDMENT_ALERT.button1Label}
          button2Label={DIALOG_CONTRACT_AMENDMENT_ALERT.button2Label}
          button2Handler={cancelContractAmendmentsHandler}
        />
      )}
      {/* <ContractAmendment data={data} /> */}
    </>
  );
}

export default DrawerTabContract;
DrawerTabContract.propTypes = {
  data: PropTypes.object,
  // resetClickHandler: PropTypes.func,
  selectedSubmissionId: PropTypes.string,
  reqFormNumber: PropTypes.string,
  scrollToBottomHandler: PropTypes.func,
  resetContractAmendmentHandler: PropTypes.func,
  savedContractAmendments: PropTypes.object
};
