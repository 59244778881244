import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';

import './styles.css';

export default function ExportModalFailureView({ reason }) {
  return (
    <React.Fragment>
      <CancelIcon className="failureIcon" />
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Failed
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Failed to export file , Please try again.
      </Typography>

      <hr className="hr" />

      <Typography id="modal-modal-description">{reason}</Typography>
    </React.Fragment>
  );
}

ExportModalFailureView.propTypes = {
  reason: PropTypes.string
};
