// import { LogLevel } from '@azure/msal-browser';
import { config } from 'features/auth/constants';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
// export const msalConfig = {
//   auth: {
//     clientId: config.msalConfig.clientId, // This is the ONLY mandatory field that you need to supply.
//     authority: config.msalConfig.authority, // Defaults to "https://login.microsoftonline.com/common"
//     redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
//     postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
//     clientCapabilities: ['CP1'] // this lets the resource owner know that this client is capable of handling claims challenge.
//   },
//   cache: {
//     cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
//     storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
//   },
//   system: {
//     loggerOptions: {
//       loggerCallback: (level, message, containsPii) => {
//         if (containsPii) {
//           return;
//         }
//         switch (level) {
//           case LogLevel.Error:
//             console.error(message);
//             return;
//           case LogLevel.Info:
//             // console.info(message);
//             return;
//           case LogLevel.Verbose:
//             console.debug(message);
//             return;
//           case LogLevel.Warning:
//             console.warn(message);
//             return;
//         }
//       }
//     }
//   }
// };

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
// 'http://localhost:8080/submissions'

export const protectedResources = {
  apiCreateList: config.urlConfig.apiCreateList
};

// export const loginRequest = {
//   scopes: [...protectedResources.apiCreateList.scopes]
// };

// export const appRoles = config.roleConfig;
