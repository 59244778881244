import styled from '@emotion/styled';

// export const CustomSpan = styled('span')`
//   color: ${(props) => props.theme.palette.secondary.main};
// `;
export const CustomSpan = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main
  // '&:hover': {
  //   backgroundColor: alpha(theme.palette.common.black, 0.15)
  // },
}));
