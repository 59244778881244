import { Controller, useFormContext } from 'react-hook-form';
import { Tooltip } from '@mui/material';
import * as React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { registerFormElement } from '../../utils/helper';
import { StyledChildBox, StyledInputAdornment, StyledLabelBox, StyledTextField } from './styled';
import { alpha, useTheme } from '@mui/material/styles';
import LinkIcon from '@mui/icons-material/Link';
import { useRef } from 'react';
import PropTypes from 'prop-types';

export const FormElementComponent = ({ inputDataObject, isDisplayed }) => {
  const { control } = useFormContext();
  const textFieldRef = useRef();
  const theme = useTheme();
  // console.log(control);

  // console.log('inputDataObject', inputDataObject);
  if (!isDisplayed) {
    return null;
  }
  //below function is used to determine to render component with and without Tooltip
  function isTextOverflowing(element) {
    // console.log('lets print element', element.offsetWidth, element.scrollWidth);
    return element.offsetWidth < element.scrollWidth;
  }

  return (
    <>
      <Controller
        name={registerFormElement(inputDataObject)}
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) =>
          inputDataObject.validation.max.value <= 100 &&
          textFieldRef.current &&
          isTextOverflowing(textFieldRef.current) ? (
            <Tooltip title={value}>
              <StyledTextField
                fullWidth
                autoComplete="off"
                sx={{ m: 0.5 }}
                label={
                  <StyledLabelBox>
                    {inputDataObject.id === 'DE017' && ( // Replace with your desired condition
                      <LinkIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    )}
                    <StyledChildBox>{inputDataObject.name}</StyledChildBox>
                    {inputDataObject.hasDescription && (
                      <Tooltip title={inputDataObject.description}>
                        <HelpOutlineIcon fontSize="small" sx={{ ml: 1, verticalAlign: 'middle' }} />
                      </Tooltip>
                    )}
                  </StyledLabelBox>
                }
                variant="outlined"
                value={value}
                onChange={onChange}
                onBlur={(event) => {
                  onChange(event.target.value.trim());
                  onBlur(event);
                }}
                error={!!error}
                InputLabelProps={{
                  style: {
                    pointerEvents: 'auto',
                    color: error ? theme.palette.error.main : alpha(theme.palette.common.black, 0.6)
                  }
                }}
                inputProps={
                  inputDataObject.validation.max.value > 100
                    ? {}
                    : {
                        style: {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }
                      }
                }
                inputRef={textFieldRef}
                InputProps={
                  inputDataObject.validation.max.value > 100
                    ? {
                        endAdornment: (
                          <StyledInputAdornment position="end">
                            {`${value.length}/${inputDataObject.validation.max.value}`}
                          </StyledInputAdornment>
                        )
                      }
                    : {}
                }
                minRows={inputDataObject.validation.max.value > 100 ? 3 : 1}
                maxRows={inputDataObject.validation.max.value > 100 ? 3 : 1}
                multiline={inputDataObject.validation.max.value > 100}
                helperText={error ? error.message : null}
              />
            </Tooltip>
          ) : (
            <StyledTextField
              fullWidth
              autoComplete="off"
              sx={{ m: 0.5 }}
              label={
                <StyledLabelBox>
                  {inputDataObject.id === 'DE017' && ( // Replace with your desired condition
                    <LinkIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                  )}
                  <StyledChildBox>{inputDataObject.name}</StyledChildBox>
                  {inputDataObject.hasDescription && (
                    <Tooltip title={inputDataObject.description}>
                      <HelpOutlineIcon fontSize="small" sx={{ ml: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                  )}
                </StyledLabelBox>
              }
              variant="outlined"
              value={value}
              onChange={onChange}
              onBlur={(event) => {
                onChange(event.target.value.trim());
                onBlur(event);
              }}
              error={!!error}
              InputLabelProps={{
                style: {
                  pointerEvents: 'auto',
                  color: error ? theme.palette.error.main : alpha(theme.palette.common.black, 0.6)
                }
              }}
              inputProps={
                inputDataObject.validation.max.value > 100
                  ? {}
                  : {
                      style: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }
                    }
              }
              inputRef={textFieldRef}
              InputProps={
                inputDataObject.validation.max.value > 100
                  ? {
                      endAdornment: (
                        <StyledInputAdornment position="end">
                          {`${value.length}/${inputDataObject.validation.max.value}`}
                        </StyledInputAdornment>
                      )
                    }
                  : {}
              }
              minRows={inputDataObject.validation.max.value > 100 ? 3 : 1}
              maxRows={inputDataObject.validation.max.value > 100 ? 3 : 1}
              multiline={inputDataObject.validation.max.value > 100}
              helperText={error ? error.message : null}
            />
          )
        }
        // rules={{key:inputDataObject.id}}
      />
    </>
  );
};
FormElementComponent.propTypes = {
  inputDataObject: PropTypes.object,
  isDisplayed: PropTypes.bool
};
