import { useState } from 'react';
import { Login } from 'features/auth/login';

// import { InteractionType } from '@azure/msal-browser';
// import { useMsal, useMsalAuthentication } from '@azure/msal-react';

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */
const useFetchWithMsal = () => {
  // const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  // const { result, error: msalError } = useMsalAuthentication(InteractionType.Popup, {
  //   ...msalRequest,
  //   account: instance.getActiveAccount(),
  //   redirectUri: '/'
  // });

  /**
   * Execute a fetch request with the given options
   * @param {string} method: GET, POST, PUT, DELETE
   * @param {String} endpoint: The endpoint to call
   * @param {Object} data: The data to send to the endpoint, if any
   * @returns JSON response
   */
  const execute = async (method, endpoint, data) => {
    // if (msalError) {
    //   setError(msalError);
    //   return;
    // }

    // eslint-disable-next-line no-constant-condition
    if (true) {
      try {
        let response = null;

        const headers = new Headers();
        // const bearer = `Bearer ${result.accessToken}`;
        // headers.append('Authorization', bearer);

        if (data) headers.append('Content-Type', 'application/json');

        let options = {
          method: method,
          headers: headers,
          credentials: 'include',
          // credentials: 'same-origin',
          body: data ? JSON.stringify(data) : null
        };

        setIsLoading(true);

        // const rowResponse = await fetch(endpoint, options);
        let rowResponse = null;

        try {
          rowResponse = await fetch(endpoint, options);
        } catch (error) {
          console.log(error);

          Login();
        }
        if (rowResponse.status == 404) {
          Login();
        }
        response = await rowResponse.json();

        setData(response);

        setIsLoading(false);
        return response;
      } catch (e) {
        setError(e);
        setIsLoading(false);
        throw e;
      }
    }
  };

  return {
    isLoading,
    error,
    data,
    execute
    // execute: useCallback(execute, [result, msalError]) // to avoid infinite calls when inside a `useEffect`
  };
};

export default useFetchWithMsal;
