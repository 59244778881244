import { Typography } from '@mui/material';
import { RenderForm } from '../../../utils/helper';
import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledBoxContainer,
  StyledCircle,
  StyledCircleContainer,
  StyledGridContainer,
  StyledGridItem,
  StyledHeaderBox
} from '../commonStyles/styled';

function Risk({ data }) {
  const scheduleAndCostGroup = {
    ...data,
    data: data.data.slice(0, 2)
  };
  const marketParticipationGroup = {
    ...data,
    data: data.data.slice(2, 4)
  };
  const policyProcessandProcedureGroup = {
    ...data,
    data: data.data.slice(4, 6)
  };
  const administrativeGroup = {
    ...data,
    data: data.data.slice(6)
  };
  return (
    <>
      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledHeaderBox />
            <Typography variant="h2">Risk</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
      </StyledGridContainer>

      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledCircleContainer>
              <StyledCircle />
            </StyledCircleContainer>
            <Typography variant="h3">Schedule and Cost</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={scheduleAndCostGroup} />}
        {/*{<RenderForm formDataConfig={procurementLeadAssignmentGroup} xs={12} />}*/}
      </StyledGridContainer>
      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledCircleContainer>
              <StyledCircle />
            </StyledCircleContainer>
            <Typography variant="h3">Market Participation</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={marketParticipationGroup} />}
      </StyledGridContainer>
      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledCircleContainer>
              <StyledCircle />
            </StyledCircleContainer>
            <Typography variant="h3">Policy, Process and Procedure</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={policyProcessandProcedureGroup} />}
      </StyledGridContainer>
      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledCircleContainer>
              <StyledCircle />
            </StyledCircleContainer>
            <Typography variant="h3">Administrative</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={administrativeGroup} />}
      </StyledGridContainer>
    </>
  );
}

export default Risk;
Risk.propTypes = {
  data: PropTypes.object
};
