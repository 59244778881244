const riskDataElementArray = [
  {
    id: 'DE214',
    name: 'Schedule and Cost Risk Rating',
    family: 'risk',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    list: [
      {
        id: 1,
        name: 'Low'
      },
      {
        id: 2,
        name: 'Elevated'
      },
      {
        id: 3,
        name: 'High'
      }
    ],
    hasDescription: true,
    description:
      "The Procurement Lead's assessment as to whether award is likely to be achieved on schedule, gauged against the RFx’s listed schedule, or equivalent and/or whether the targeted budget is exceeded by bids received. Assessments of 'Low' risk indicate award is likely to be achieved on schedule and within budget, 'Elevated' risk indicates moderate or critical risks to schedule or budget are likely or certain, and 'High' risk indicates a critical risk to schedule or budget is certain to occur.",
    isDisplayed: true
  },
  {
    id: 'DE215',
    name: 'Schedule and Cost Risk Notes',
    family: 'risk',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000,
        message: 'Schedule and Cost Risk Notes cannot have more than 1000 characters.'
      }
    },
    hasDescription: true,
    description:
      'A brief overview of any schedule-related elevated or high risks. Identified risks should include a description following the STAR Method: Situation/Task, Action, Result. Unresolved risks may include description elements as they become available - an identified risk with no current action response nor outcome should include an outline of the situation alone and then be updated with actions and results as the Lead becomes aware of them. Risks whose entire lifecycle occurs prior to reporting should include all description elements at point of recording.',
    isDisplayed: true
  },
  {
    id: 'DE216',
    name: 'Market Participation Risk Rating',
    family: 'risk',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    list: [
      {
        id: 1,
        name: 'Low'
      },
      {
        id: 2,
        name: 'Elevated'
      },
      {
        id: 3,
        name: 'High'
      }
    ],
    hasDescription: true,
    description:
      "The Procurement Lead's assessment as to whether the number of engaged vendors are capable of ensuring sufficient and appropriate levels of competition. Assessments of 'Low' risk indicate an appropriate level of competitiveness has been or is likely to be achieved, 'Elevated' risk indicates moderate or critical risks to participation are likely or certain, and 'High' risk indicates a critical risk to participation is certain to occur.",
    isDisplayed: true
  },
  {
    id: 'DE217',
    name: 'Market Participation Risk Notes',
    family: 'risk',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000,
        message: 'Market Participation Risk Notes cannot have more than 1000 characters.'
      }
    },
    hasDescription: true,
    description:
      'A brief overview of any market participation-related elevated or high risks. Identified risks should include a description following the STAR Method: Situation/Task, Action, Result. Unresolved risks may include description elements as they become available - an identified risk with no current action response nor outcome should include an outline of the situation alone and then be updated with actions and results as the Lead becomes aware of them. Risks whose entire lifecycle occurs prior to reporting should include all description elements at point of recording.',
    isDisplayed: true
  },
  {
    id: 'DE218',
    name: 'Policy, Process, Procedure Risk Rating',
    family: 'risk',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    list: [
      {
        id: 1,
        name: 'Low'
      },
      {
        id: 2,
        name: 'Elevated'
      },
      {
        id: 3,
        name: 'High'
      }
    ],
    hasDescription: true,
    description:
      "The Procurement Lead's assessment as to whether uncontemplated or unapproved deviations from the Procurement Policy or its ancillaries have occurred or are likely to occur. Assessments of 'Low' risk indicate the procurement is being conducted pursuant to the Procurement Policy, 'Elevated' risk indicates moderate or critical deviations are likely or certain, and 'High' risk indicates a critical deviation is certain to occur.",
    isDisplayed: true
  },
  {
    id: 'DE219',
    name: 'Policy, Process, Procedure Risk Notes',
    family: 'risk',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000,
        message: 'Policy, Process, Procedure Risk Notes cannot have more than 1000 characters'
      }
    },
    hasDescription: true,
    description:
      'A brief overview of any elevated or high risks stemming from policy, processes, or procedure. Identified risks should include a description following the STAR Method: Situation/Task, Action, Result. Unresolved risks may include description elements as they become available - an identified risk with no current action response nor outcome should include an outline of the situation alone and then be updated with actions and results as the Lead becomes aware of them. Risks whose entire lifecycle occurs prior to reporting should include all description elements at point of recording.',
    isDisplayed: true
  },
  {
    id: 'DE220',
    name: 'Administrative Risk Rating',
    family: 'risk',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    list: [
      {
        id: 1,
        name: 'Low'
      },
      {
        id: 2,
        name: 'Elevated'
      },
      {
        id: 3,
        name: 'High'
      }
    ],
    hasDescription: true,
    description:
      "The Procurement Lead's assessment as to whether critical administrative resources (technological systems or platforms, Standard Operating Procedures, or other tools/assets) are available and in good standing. Assessments of 'Low' risk indicate the no additional time is required to address or remediate the effects of unavailable or administrative assets in poor standing, 'Elevated' risk indicates that a moderate or critical amount of time is likely or certain to be required to deal with such assets, and 'High' risk indicates a critical amount of time is certain to be required.",
    isDisplayed: true
  },
  {
    id: 'DE221',
    name: 'Administrative Risk Notes',
    family: 'risk',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000,
        message: 'Policy, Process, Procedure Risk Notes cannot have more than 1000 characters'
      }
    },
    hasDescription: true,
    description:
      'A brief overview of any administration-related elevated or high risks. Identified risks should include a description following the STAR Method: Situation/Task, Action, Result. Unresolved risks may include description elements as they become available - an identified risk with no current action response nor outcome should include an outline of the situation alone and then be updated with actions and results as the Lead becomes aware of them. Risks whose entire lifecycle occurs prior to reporting should include all description elements at point of recording.',
    isDisplayed: true
  }
];
export default riskDataElementArray;
