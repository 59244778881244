import { protectedResources } from 'features/auth/config';
const { loginURL, loginURLRedirect } = protectedResources.apiCreateList.getAuthConfig();
// const { loginURL } = protectedResources.apiCreateList.getAuthConfig();
export const Login = () => {
  fetch(loginURL, {
    credentials: 'include'
  })
    .then((response) => {
      if (response.ok) {
        RedirectToLogin();
        // console.log('log in successfull');
      } else {
        // console.error(`Request failed with status: ${response.status}`);
        RedirectToLogin();
      }
    })
    .catch((error) => {
      console.error('Fetch error:', error);
      RedirectToLogin();
    });
};

const RedirectToLogin = () => {
  window.location.replace(loginURLRedirect);
};
