import * as React from 'react';
import Box from '@mui/material/Box';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import StepLabel from '@mui/material/StepLabel';
import { StyledStep, StyledStepper } from './styled';
import { alpha } from '@mui/system';
import PropTypes from 'prop-types';

const steps = ['Principal', 'Staging', 'Resourcing', 'Risk', 'Submission/Evaluation/Contract'];
// const StepperSx = {
//   '& .MuiStepConnector-root': {
//     left: 'calc(-50% + 40px)',
//     right: 'calc(50% + 40px)'
//   },
//   '& .MuiStepConnector-line': {
//     marginTop: '25px' // To position the line lower
//   },
//   background: 'red'
// };

export default function NonLinearStepper({ handleStep, activeStep }) {
  // const [activeStep, setActiveStep] = React.useState(0);
  //
  // const handleStep = (step) => () => {
  //     // console.info("active step",step);
  //     setActiveStep(step);
  //     dataFamiliesHandleStep(step);
  // };

  // eslint-disable-next-line react/prop-types
  // const CustomStepIcon = ({ active, completed }) => {
  // eslint-disable-next-line react/prop-types
  const CustomStepIcon = ({ active }) => {
    return (
      <Box
        sx={(theme) => ({
          borderRadius: '50%',
          width: '24px',
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: active
            ? alpha(theme.palette.secondary.main, 1)
            : alpha(theme.palette.secondary.main, 0.38)
        })}
      />
    );
  };

  return (
    <Box sx={{ width: '100%', overflowX: 'hidden' }}>
      <StyledStepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <StyledStep key={label}>
            {/*<Box sx={{border:'1px solid red'}}>*/}
            <Typography
              align="center"
              mb="3px"
              sx={(theme) => ({
                color:
                  activeStep === index
                    ? alpha(theme.palette.common.black, 1)
                    : alpha(theme.palette.common.black, 0.38)
              })}>
              {label}
            </Typography>
            <StepButton sx={{ p: '2px 12px' }} color="inherit" onClick={handleStep(index)}>
              <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
            </StepButton>
            {/*</Box>*/}
          </StyledStep>
        ))}
        {/*{steps.map((label, index) => (*/}
        {/*    <Step key={label} >*/}
        {/*        <StepButton color="inherit" onClick={handleStep(index)}>*/}
        {/*            {label}*/}
        {/*        </StepButton>*/}

        {/*    </Step>*/}
        {/*))}*/}
      </StyledStepper>
    </Box>
  );
}
NonLinearStepper.propTypes = {
  handleStep: PropTypes.func,
  activeStep: PropTypes.number
};
