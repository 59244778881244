import { SearchBar } from '../../components/searchBar';
import { Box } from '@mui/material';
import { StyledTypography } from '../../components/Header/styled';
import ActionAreaCard from '../../components/card';
import { CustomSpan } from './styled';
import toronto from '../../image/toronto.png';
import { useEffect, useState, useContext } from 'react';
// import { useState } from 'react';
// import { useMsal } from '@azure/msal-react';
import { UserContext } from 'features/auth/userContext';

function HomePage() {
  // const { instance } = useMsal();
  const [user, setUser] = useState(null);
  const userInfo = useContext(UserContext);

  useEffect(() => {
    const name = userInfo.displayName ? userInfo.displayName : 'Unknown';
    setUser(() => name);
  }, [userInfo.displayName]);

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 90px)',
        backgroundImage: `url(${toronto})`,
        objectFit: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        verticalAlign: 'middle',
        overflow: 'hidden'
        // border: '2px solid blue'
      }}>
      <StyledTypography variant="h4" gutterBottom>
        Welcome <CustomSpan>{user}</CustomSpan>
      </StyledTypography>
      <SearchBar />
      <ActionAreaCard />
    </Box>
  );
}

export default HomePage;
