import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
export const ThemeContext = createContext();
const ThemeContextProvider = ({ children }) => {
  const [commonVendorOptions, setCommonVendorOptions] = useState([]);
  const [teamMemberOptions, setTeamMemberOptions] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackBarOpenStatus, setSnackBarOpenStatus] = useState(false);
  const [severityLevel, setSeverityLevel] = useState('success');
  // const [cancelClickHandler, setCancelClickHandler] = useState(null);
  const handleSnackBarClose = () => {
    setSnackBarOpenStatus(false);
  };

  return (
    <>
      <ThemeContext.Provider
        value={{
          commonVendorOptions,
          setCommonVendorOptions,
          teamMemberOptions,
          setTeamMemberOptions,
          snackbarMessage,
          setSnackbarMessage,
          snackBarOpenStatus,
          setSnackBarOpenStatus,
          severityLevel,
          setSeverityLevel,
          handleSnackBarClose
          // cancelClickHandler,
          // setCancelClickHandler
        }}>
        {children}
      </ThemeContext.Provider>
    </>
  );
};
export default ThemeContextProvider;
ThemeContextProvider.propTypes = {
  children: PropTypes.node
};
