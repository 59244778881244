import React, { useContext, useRef, useState } from 'react';
import { SearchIconWrapper, StyledInputBase, Search, SearchSendIconWrapper } from './styled';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import { Box } from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { protectedResources } from '../../features/auth/config';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { SEVERITY_WISE_ALERT_MESSAGE_ON_SEARCH } from '../../constants';
import { ThemeContext } from '../../ThemeContext';

export const SearchBar = (params) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  // const [snackBarOpenStatus, setSnackBarOpenStatus] = useState(false);
  // const [severityLevel, setSeverityLevel] = useState('success');
  // const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const inputRef = useRef();
  const { execute } = useFetchWithMsal();
  const { setSnackbarMessage, setSnackBarOpenStatus, setSeverityLevel } = useContext(ThemeContext);
  // const handleSnackBarClose = () => {
  //   setSnackBarOpenStatus(false);
  // };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    // setIsValid(value.trim().length > 0);
    // const isValid = /^\d{2}-\d{4}$/.test(value);
    setIsValid(true);
  };

  const handleSearch = async () => {
    try {
      const procurementDetails = await execute(
        'GET',
        protectedResources.apiCreateList.getProcurementDetails(searchValue)
      );

      // {"status":404,"message":"Tombstone not found with procurement number: 23-0319"}
      if (procurementDetails.status && procurementDetails.status !== 200) {
        setSnackBarOpenStatus(true);
        setSeverityLevel('error');
        // setSnackbarMessage(procurementDetails.message);
        setSnackbarMessage(
          SEVERITY_WISE_ALERT_MESSAGE_ON_SEARCH['error'](procurementDetails.message)
        );
        inputRef.current.focus();
      } else {
        setSnackBarOpenStatus(false);
        navigate({
          pathname: '/common',
          search: createSearchParams({ procId: searchValue }).toString()
        });
      }
    } catch (error) {
      console.log('lets print error', error);
    }
  };
  const handleKeyDown = (event) => {
    // console.log(event);
    if (event.key === 'Enter' && isValid) {
      handleSearch();
    }
  };
  return (
    <Box sx={{ marginRight: '30%', marginLeft: '30%', marginTop: '2%', background: 'white' }}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          inputRef={inputRef}
          defaultValue={params.defaultValue}
          placeholder="Search by Procurement number"
          inputProps={{ 'aria-label': 'search' }}
          value={searchValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        {isValid && (
          <SearchSendIconWrapper>
            <SendIcon
              onClick={() => {
                handleSearch();
              }}
              // tabIndex={0}
            />
          </SearchSendIconWrapper>
        )}
      </Search>
      {/*{snackBarOpenStatus && (*/}
      {/*  <SnackBarAlertComponent*/}
      {/*    alertMessage={SEVERITY_WISE_ALERT_MESSAGE_ON_SEARCH[severityLevel](snackbarMessage)}*/}
      {/*    snackBarOpenStatus={snackBarOpenStatus}*/}
      {/*    severityLevel={severityLevel}*/}
      {/*    handleSnackBarClose={handleSnackBarClose}*/}
      {/*  />*/}
      {/*)}*/}
    </Box>
  );
};
