import {
  Box,
  Typography,
  Button,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  LinearProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { buttonStyle, footerStyle, headerStyle, theme } from './styles';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';

import LinearProgressAnimated from './LinearProgressAnimated';
import ImportModal from './ImportModal';
import './styles.css';
import { ThemeProvider } from '@mui/styles';
import { protectedResources } from 'features/auth/config';

export default function FileImport({ onCancel, onSuccess }) {
  const [files, setFiles] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [openPopup, setOpenPoup] = useState(false);
  const [isTxnCompleted, setisTxnCompleted] = useState(false);
  const [successFlag, setsuccessFlag] = useState(false);
  const [failureCount, setfailureCount] = useState(0);
  const [successCount, setsuccessCount] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [errorAlert, seterrorAlert] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  const [rejectReasonList, setrejectReasonList] = useState([]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles?.length > 1 || rejectedFiles?.length > 1) {
      seterrorAlert(true);
      return;
    }
    setFiles([]);
    setRejected([]);
    if (acceptedFiles?.length) {
      setFiles([
        ...acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }))
      ]);
    }

    if (rejectedFiles?.length) {
      setRejected([...rejectedFiles]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'text/plain': []
    },
    maxFiles: 1,
    maxSize: 1024 * 10000,
    onDrop
  });

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const removeFile = (name) => {
    setFiles((files) => files.filter((file) => file.name !== name));
  };

  // const removeAll = () => {
  //   setFiles([])
  //   setRejected([])
  // }

  const removeRejected = (name) => {
    setRejected((files) => files.filter(({ file }) => file.name !== name));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setOpenPoup(true);
    setisTxnCompleted(false);
    setsuccessFlag(false);

    const formData = new FormData();
    formData.append('fileName', files[0]);
    const URL = protectedResources.apiCreateList.getImportRequest();
    const data = await fetch(URL, {
      method: 'POST',
      body: formData
    })
      .then((response) => {
        setisTxnCompleted(true);
        setsuccessFlag(true);
        return response.json();
      })
      .catch((error) => {
        setisTxnCompleted(true);
        setsuccessFlag(false);
        seterrorMessage(String(error));
      });
    if (data?.totalRecordsProcessed) {
      setfailureCount(data.rejectRecords);
      setsuccessCount(data.successRecords);
      settotalCount(data.totalRecordsProcessed);
      setrejectReasonList(data.messageList);
    } else {
      setsuccessFlag(false);
      seterrorMessage('');
    }
  };

  const onPopupClose = () => {
    setOpenPoup(false);
    onSuccess();
  };
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Box sx={headerStyle}>
          <Typography sx={{ display: 'flex', color: '#fff' }}>Import Procurement</Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <div
            {...getRootProps({
              className: 'draganddrop'
            })}>
            <input {...getInputProps()} />
            <div>
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <div className="draganddropContent">
                  <Box className="uploadIcon">
                    <UploadFileIcon />
                  </Box>
                  <Box display="flex">
                    <Typography sx={{ color: '#3894e6', textDecoration: 'underline' }}>
                      Click to upload
                    </Typography>
                    <Typography sx={{ marginLeft: '3px' }}>or drag and drop</Typography>
                  </Box>
                </div>
              )}
            </div>
          </div>
          <Box className="dropboxfooter">
            <Typography fontSize="0.9rem">Supported format: txt</Typography>
            <Typography fontSize="0.9rem">Max size: 10 MB, 100 procurements per file</Typography>
          </Box>

          {/* Preview */}
          <section className="fileListContainer">
            <ul className="">
              {files.map((file) => (
                <li key={file.name} className="fileList">
                  <List
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper'
                    }}>
                    <ListItem sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                      <ListItemAvatar>
                        <Box className="uploadIcon">
                          <UploadFileIcon />
                        </Box>
                      </ListItemAvatar>
                      <ListItemText
                        primary={file.name}
                        primaryTypographyProps={{ fontWeight: '500' }}
                        secondaryTypographyProps={{ color: 'black' }}
                        secondary={`${Math.floor(file.size / 1000) + 'kb'} - Ready to import`}
                      />
                      <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => removeFile(file.name)} />
                    </ListItem>
                    <Box sx={{ width: '100%', paddingLeft: '56px', paddingRight: '20px' }}>
                      <LinearProgressAnimated />
                    </Box>
                  </List>
                </li>
              ))}
            </ul>

            {rejected.map(({ file, errors }) => (
              <div key={file.name} className="">
                <List
                  sx={{
                    width: '90%',
                    bgcolor: 'background.paper',
                    marginLeft: '4vh'
                  }}>
                  <ListItem sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                    <ListItemAvatar>
                      <Box className="uploadIcon">
                        <UploadFileIcon />
                      </Box>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${file.name} (Failed)`}
                      primaryTypographyProps={{ fontWeight: '500' }}
                      secondaryTypographyProps={{ color: 'black' }}
                      // secondary={errors.map((error) => (
                      //   <li key={error.code}>{error.message.replace('10240000 bytes', '10 MB')}</li>
                      // ))}
                      secondary={
                        <Typography component="div">
                          {errors.map((error) => (
                            <div key={error.code}>
                              <span>
                                {error.message.replace(
                                  'File is larger than 10240000 bytes',
                                  'File size is larger than 10MB.'
                                )}
                              </span>
                            </div>
                          ))}
                        </Typography>
                      }
                    />

                    <CloseIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={() => removeRejected(file.name)}
                    />
                  </ListItem>

                  <Box sx={{ width: '100%', paddingLeft: '56px', paddingRight: '20px' }}>
                    <LinearProgress
                      sx={{ color: '#8b8585' }}
                      color="error"
                      variant="determinate"
                      value={100}
                    />
                  </Box>
                </List>
              </div>
            ))}

            <Box sx={footerStyle}>
              <Stack spacing={2} sx={{ position: 'fixed', right: '5px' }} direction="row">
                <Button onClick={onCancel()} variant="text" size="medium">
                  Cancel
                </Button>
                <Button
                  disabled={!files.length}
                  type="submit"
                  sx={buttonStyle}
                  variant="contained"
                  size="medium">
                  Save
                </Button>
              </Stack>
            </Box>
          </section>
        </form>

        <ImportModal
          open={openPopup}
          failureCount={failureCount}
          successCount={successCount}
          totalCount={totalCount}
          isTxnCompleted={isTxnCompleted}
          successFlag={successFlag}
          onPopupClose={onPopupClose}
          reason={errorMessage}
          rejectReasonList={rejectReasonList}></ImportModal>
      </ThemeProvider>

      <Snackbar open={errorAlert} onClose={() => seterrorAlert(false)} autoHideDuration={5000}>
        <Alert
          onClose={() => seterrorAlert(false)}
          variant="filled"
          severity="error"
          sx={{ width: '100%' }}>
          Multiple file selection is not allowed.
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
FileImport.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
};
