import styled from '@emotion/styled';
import { Card, CardContent, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// export const StyledCard = styled(Card)`
//   max-width: 300px;
//   text-align: center;
//   padding: 0.5rem 0.5rem;
//   margin: 3rem;
//   text-decoration: none;
//   min-width: 282px;
//   //border: 1px solid blue;
//   //max-height: 15rem;
//   //min-height: 12rem;
// `;
export const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: '300px',
  textAlign: 'center',
  padding: '0.5rem 0.5rem',
  margin: '3rem',
  textDecoration: 'none',
  minWidth: '282px',
  height: '171px',
  transition: 'border 5s',
  '&:hover': {
    // borderColor: theme.palette.secondary.main
    border: `1px solid ${theme.palette.primary.main}`,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main /* Change the color to your desired hover color */
    }
  }
}));
export const StyledCardTypogarphy = styled(Typography)`
  max-width: 20rem;
`;
export const StyledCardHeaderTypogarphy = styled(Typography)`
  max-width: 20rem;
  font-size: 16px;
  font-weight: 600;
`;
// export const StyledIconBox = styled(Box)`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   //border:1px solid red;
//   font-size: 2.5rem;
//   margin-top: 0.5rem;
//   //padding:0.25rem;
// `;

export const StyledCardContent = styled(CardContent)({
  // padding: '10px'
});
// export const SharedIcon = styled(AddCircleIcon)`
//
// `;
export const SharedIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: '40px',
  height: '40px'
}));
