import { Outlet, useOutletContext } from 'react-router-dom';
import Header from 'components/Header';
import { useContext, useRef } from 'react';
import SnackBarAlertComponent from '../components/SnackBarAlertComponent';
import { ThemeContext } from '../ThemeContext';
import * as React from 'react';

function RootLayout() {
  const cancelClickHandler_ref = useRef(null);
  const { snackbarMessage, snackBarOpenStatus, severityLevel, handleSnackBarClose } =
    useContext(ThemeContext);

  return (
    <>
      <Header cancelClickHandler_ref={cancelClickHandler_ref} />
      <Outlet context={{ cancelClickHandler_ref }} />
      {snackBarOpenStatus && (
        <SnackBarAlertComponent
          alertMessage={snackbarMessage}
          snackBarOpenStatus={snackBarOpenStatus}
          severityLevel={severityLevel}
          handleSnackBarClose={handleSnackBarClose}
        />
      )}
    </>
  );
}

export default RootLayout;

export function useCancelClickHandler_ref() {
  return useOutletContext();
}
