import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './styles.css';
export default function ExportModalSuccessView({ totalCount }) {
  return (
    <React.Fragment>
      <CheckCircleIcon className="sucessIcon" />
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Success
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Your file has been exported successfully
      </Typography>
      <Box className="spacingText">
        <Typography sx={{ mt: 2, fontSize: '16px', fontWeight: '500' }}>
          {`Number of procurements exported`}
        </Typography>
        <Typography sx={{ mt: 2, fontSize: '16px', fontWeight: '500' }}>{totalCount}</Typography>
      </Box>
    </React.Fragment>
  );
}

ExportModalSuccessView.propTypes = {
  open: PropTypes.bool,
  isTxnCompleted: PropTypes.bool,
  successFlag: PropTypes.bool,
  totalCount: PropTypes.number
};
