import { areDataElementsToDisplay, RenderForm } from '../../utils/helper';
import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import EditableGrid from '../EditableGrid';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  StyledBoxContainer,
  StyledCircle,
  StyledCircleContainer,
  StyledGridContainer,
  StyledGridItem,
  StyledHeaderBox
} from '../../features/procurementAttributes/commonStyles/styled';

function DrawerTabSubmission({ data, rows, setRows, selectedSubmissionId }) {
  const [submissionGroup, setSubmissionGroup] = useState(null);
  const [vendorGroup, setVendorGroup] = useState(null);
  const [preEvaluationGroup, setPreEvaluationGroup] = useState(null);
  const [commentGroup, setCommentGroup] = useState(null);
  const [financialGroup, setFinancialGroup] = useState(null);
  const [teamGroup, setTeamGroup] = useState(null);
  const [autoFoucusFlag, setautoFoucusFlag] = useState(false);
  const { control, setValue, getValues } = useFormContext();
  // const [rows, setRows] = useState(() => {
  //   const currentteamMembers = getValues('submission.teamMembers');
  //   return currentteamMembers.map((team, index) => {
  //     return {
  //       id: index + 1,
  //       DE060: team.DE060,
  //       DE061: team.DE061,
  //       DE062: team.DE062,
  //       selected: false
  //     };
  //   });
  //   // return [];
  // });
  // let isThisFirstRenderofZeroLength = false;
  useEffect(() => {
    const teamMembers = getValues('submission.teamMembers');
    // console.log('lets test teamMembers', teamMembers);
    // console.log('lets test rows', rows);
    if (rows.length === 0 && teamMembers.length > 0 && selectedSubmissionId === null) {
      setValue('submission.teamMembers', []);
    } else {
      // Below ternary codition is useful when we are creating a submission for the first time and
      // when we want to create a new team information entry
      const updatedRows = teamMembers
        ? teamMembers.map((team, index) => {
            return {
              id: index + 1,
              DE060: team.DE060,
              DE061: team.DE061,
              DE062: team.DE062,
              selected: false
            };
          })
        : [];
      // console.log('lets test teaminformation rows', updatedRows);
      setRows(updatedRows);
    }
    // const updatedRows = teamMembers
    //   ? teamMembers.map((team, index) => {
    //       return {
    //         id: index + 1,
    //         DE060: team.DE060,
    //         DE061: team.DE061,
    //         DE062: team.DE062,
    //         selected: false
    //       };
    //     })
    //   : [];
    // setRows(updatedRows);
  }, [getValues('submission.teamMembers')]);
  // React.useEffect(() => {
  //   remove(0);
  // }, [remove]);

  const { append, fields } = useFieldArray({
    control,
    name: 'submission.teamMembers'
  });
  // const submissionGroup = {
  //   ...data,
  //   data: data.data.filter((el) => el.family === 'submission')
  // };
  useEffect(() => {
    if (data) {
      setSubmissionGroup(() => ({
        ...data,
        data: data.data.filter((el) => el.family === 'submission')
      }));
    }
  }, [data]);
  useEffect(() => {
    if (submissionGroup) {
      // console.log('lets test submission group', submissionGroup);
      setVendorGroup(() => ({
        ...submissionGroup,
        data: submissionGroup.data.slice(0, 2)
      }));
      setPreEvaluationGroup(() => ({
        ...submissionGroup,
        data: submissionGroup.data.slice(2, 8)
      }));
      setCommentGroup(() => ({
        ...submissionGroup,
        data: submissionGroup.data.slice(8, 9)
      }));
      setFinancialGroup(() => ({
        ...submissionGroup,
        data: submissionGroup.data.slice(9, 15)
      }));
      setTeamGroup(() => ({
        ...submissionGroup,
        data: submissionGroup.data.slice(15)
      }));
    }
  }, [submissionGroup]);
  const handleAddRow = () => {
    setautoFoucusFlag(true);
    // append({ DE060: '', DE062: '' });
    const newRow = {
      id: rows.length + 1,
      DE060: '',
      DE061: [],
      DE062: 0,
      selected: false
    };

    setRows((prevRows) => [...prevRows, newRow]);
    // eslint-disable-next-line no-unused-vars
    const { id, ...filteredObject } = newRow;
    // append({ DE060: '', DE062: '' });
    // console.log('lets see filyered Object', filteredObject);
    append(filteredObject);
  };
  const handleDeleteRows = () => {
    // console.log('lets print rows', rows);
    const filteredRows = rows.filter((row) => !row.selected);
    // console.log('lets see filtered rows', filteredRows);
    setRows(filteredRows);
    const currentTeamList = getValues('submission.teamMembers');
    // console.log('lets see current teamlist', currentTeamList);
    // const filteredCurrentTeamList = currentTeamList.filter((row) => !row.selected);
    const filteredCurrentTeamList = currentTeamList.filter(
      (row) => row.selected === false || row.selected === 'false'
    );
    // console.log('lets see filtered current teamlist', filteredCurrentTeamList);
    setValue('submission.teamMembers', filteredCurrentTeamList);
    // setValue('submission.teamMembers', []);
    // const teamMembers = getValues('submission.teamMembers');
    // console.log('lets also test teamMembers', teamMembers);

    // const removedRowIds = rows.filter((row) => row.selected).map((row) => row.id - 1);
    // const removedRows = rows.filter((row) => row.selected);

    // removedRowIds.forEach((rowId) => {
    //   remove(`submission.teamMembers.${rowId}`);
    // });

    // Update the default values in useForm
    // const currentteamMembers = getValues('submission.teamMembers'); // Get the current form values

    // console.log('lets what getvales is returning', currentteamMembers);
    // console.log('lets also test filtered rows', filteredRows);
    // // const updatedValues = {
    //   submission: {
    //     ...currentValues.submission,
    //     teamMembers: filteredRows.map((row) => ({
    //       DE060: row.DE060,
    //       DE062: row.DE062
    //     }))
    //   }
    // };
    // reset(updatedValues);
  };

  return (
    <>
      {/*<Typography variant='h5'>Submission Details</Typography>*/}
      {/*<Typography variant='h6'> Vendor Information</Typography>*/}
      {/*<RenderForm formDataConfig={data}/>*/}
      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledHeaderBox />
            <Typography variant="h2"> Submission Details</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
      </StyledGridContainer>

      {vendorGroup && areDataElementsToDisplay(vendorGroup.data) && (
        <StyledGridContainer container style={{ flex: 1 }}>
          <StyledGridItem item xs={12}>
            <StyledBoxContainer>
              <StyledCircleContainer>
                <StyledCircle />
              </StyledCircleContainer>
              <Typography variant="h3">Vendor Information</Typography>
            </StyledBoxContainer>
          </StyledGridItem>
          <RenderForm formDataConfig={vendorGroup} />
        </StyledGridContainer>
      )}
      {commentGroup && areDataElementsToDisplay(commentGroup.data) && (
        <StyledGridContainer container style={{ flex: 1 }}>
          <StyledGridItem item xs={12}>
            <StyledBoxContainer>
              <StyledCircleContainer>
                <StyledCircle />
              </StyledCircleContainer>
              <Typography variant="h3">Submission Pre-Evaluation Assessment</Typography>
            </StyledBoxContainer>
          </StyledGridItem>

          {/*<Grid item xs={12} sx={{ padding: '8px' }}>*/}
          {/*  <Typography variant="h3" sx={{ fontSize: '18px' }}>*/}
          {/*    Submission Pre-Evaluation Assessment*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          {preEvaluationGroup && <RenderForm formDataConfig={preEvaluationGroup} />}
          <RenderForm formDataConfig={commentGroup} xs={12} />
        </StyledGridContainer>
      )}
      {financialGroup && areDataElementsToDisplay(financialGroup.data) && (
        <StyledGridContainer container style={{ flex: 1 }}>
          <StyledGridItem item xs={12}>
            <StyledBoxContainer>
              <StyledCircleContainer>
                <StyledCircle />
              </StyledCircleContainer>
              <Typography variant="h3">Financial Bid Information</Typography>
            </StyledBoxContainer>
          </StyledGridItem>
          {/*<Grid item xs={12} sx={{ padding: '8px' }}>*/}
          {/*  <Typography variant="h3" sx={{ fontSize: '18px' }}>*/}
          {/*    Financial Bid Information*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          <RenderForm formDataConfig={financialGroup} />
        </StyledGridContainer>
      )}
      {teamGroup && areDataElementsToDisplay(teamGroup.data) && (
        <StyledGridContainer container style={{ padding: '5px', flex: 1 }}>
          {/*<Grid item xs={6}>*/}
          {/*  <Typography variant="h3" sx={{ fontSize: '18px' }}>*/}
          {/*    Team Information*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          <StyledGridItem item xs={6}>
            <StyledBoxContainer>
              <StyledCircleContainer>
                <StyledCircle />
              </StyledCircleContainer>
              <Typography variant="h3">Team Information</Typography>
            </StyledBoxContainer>
          </StyledGridItem>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {getValues('submission.teamMembers')?.some((el) => el.selected === true) && (
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteRows}
                sx={{ mr: '5px' }}>
                Delete
              </Button>
            )}
            <Button
              // sx={{ ml: 'auto', border: '1px solid blue' }}
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddRow}>
              Add
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ padding: '8px' }}>
            {/*<RenderForm formDataConfig={financialGroup} />*/}
            <EditableGrid
              teamGroup={teamGroup}
              rows={rows}
              setRows={setRows}
              fields={fields}
              autoFoucusFlag={autoFoucusFlag}
            />
          </Grid>
        </StyledGridContainer>
      )}
    </>
  );
}

export default DrawerTabSubmission;
DrawerTabSubmission.propTypes = {
  data: PropTypes.object,
  rows: PropTypes.array,
  setRows: PropTypes.func,
  selectedSubmissionId: PropTypes.string
};
