import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import RenderDrawerComponent from '../../../components/RenderDrawerComponent';

function Submission({ data, requestFormHandler, procData, updateProcurementData, reqFormNumber }) {
  // console.log('LETS SEE REQFORM NUMBER', reqFormNumber);

  return (
    <>
      {/*<div> I am from Submission</div>*/}
      <Grid item xs={12}>
        {/*{renderForm(data)}*/}
        <RenderDrawerComponent
          data={data}
          requestFormHandler={requestFormHandler}
          procData={procData}
          updateProcurementData={updateProcurementData}
          reqFormNumber={reqFormNumber}
        />
      </Grid>
    </>
  );
}

export default Submission;
Submission.propTypes = {
  data: PropTypes.object,
  requestFormHandler: PropTypes.func,
  procData: PropTypes.object,
  updateProcurementData: PropTypes.func,
  reqFormNumber: PropTypes.string
};
