import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { StyledButton, StyledDialogContentTextBody, StyledDialogContentTextHeader } from './styled';

export default function DialogAlertComponent({
  descriptionHeader,
  descriptionBody,
  button1Label,
  button2Label,
  popupStatus,
  button1Handler,
  button2Handler,
  id
}) {
  const [open, setOpen] = React.useState(popupStatus);

  let deleteButtonStyle = {};
  button1Label == 'DELETE'
    ? (deleteButtonStyle = {
        color: 'red',
        borderColor: 'white',
        '&:hover': {
          background: '#80808061',
          borderColor: 'white',
          color: 'red'
        }
      })
    : (deleteButtonStyle = {});

  const button1ClickHandler = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
    if (id) {
      button1Handler(event);
    } else {
      button1Handler();
    }
  };
  const button2ClickHandler = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
    button2Handler(event);
  };

  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minWidth: '443px',
            height: '155px'
          }
        }}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
        <DialogContent>
          <StyledDialogContentTextHeader id="alert-dialog-description">
            {descriptionHeader}
          </StyledDialogContentTextHeader>
          <StyledDialogContentTextBody>{descriptionBody}</StyledDialogContentTextBody>
        </DialogContent>
        <DialogActions sx={{ marginBottom: '10px', marginRight: '10px' }}>
          {/*<Button onClick={handleClose}>Cancel</Button>*/}

          <Button
            id={id || ''}
            sx={deleteButtonStyle}
            variant="outlined"
            onClick={button1ClickHandler}
            autoFocus>
            {button1Label}
          </Button>

          <StyledButton
            sx={{ borderColor: 'white' }}
            variant="outlined"
            onClick={button2ClickHandler}
            autoFocus>
            {button2Label}
          </StyledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
DialogAlertComponent.propTypes = {
  descriptionHeader: PropTypes.string,
  descriptionBody: PropTypes.string,
  button1Label: PropTypes.string,
  button2Label: PropTypes.string,
  popupStatus: PropTypes.bool,
  button1Handler: PropTypes.func,
  button2Handler: PropTypes.func,
  id: PropTypes.string
};
