import { createTheme } from '@mui/material/styles';
// import PPNeueMontrealMedium from '../fonts/PPNeueMontrealMedium.otf';
// import PPNeueMontrealBook from '../fonts/PPNeueMontrealBook.otf';
const primaryColor = '#253746';
const secondaryColor = '#b5be00';
const bodyTextColor = '#54565b';
const infoTextColor = '#00a6b6';
// 33ae97
// const hoverbgColor = '#818a91';
const theme = createTheme({
  palette: {
    text: {
      primary: bodyTextColor,
      secondary: secondaryColor
    },
    primary: {
      main: primaryColor,
      contrastText: secondaryColor
    },
    info: {
      main: infoTextColor,
      contrastText: secondaryColor
    },
    secondary: {
      main: secondaryColor,
      contrastText: primaryColor
    }
  },
  hover: {
    main: primaryColor
  },
  typography: {
    fontFamily: ['Roboto'].join(','),
    h1: {
      fontSize: '24px',
      fontWeight: '600',
      letterSpacing: '0em'
    },
    h2: {
      fontSize: '20px',
      fontWeight: '500',
      letterSpacing: '0em',
      color: '#222222'
    },
    h3: {
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0em',
      color: '#222222'
    }
    // dialogBody: {
    //   fontSize: '14px',
    //   fontWeight: '400',
    //   letterSpacing: '0em'
    //   // color: '#222222'
    // }
    // fontWeightRegular: 350
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'html,body': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0
        },
        '*': {
          boxSizing: 'inherit'
        },
        '*::before': {
          boxSizing: 'inherit'
        },
        '*::after': {
          boxSizing: 'inherit'
        }
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          color: secondaryColor
          // backgroundColor: secondaryColorgroundColor: secondaryColor
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: infoTextColor
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          // border: '1px solid blue',
          '&.Mui-disabled': {
            opacity: 0.3
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            // -webkit-autofill:hover-webkit-autofill:focus-webkit-autofill:active
            // transitionDelay: '9999s'
            // transitionProperty: 'backgroundColor, #fff'
            // boxShadow: '0 0 0 30px white inset !important',
            // height: '0px'
            // backgroundColor: '#FF5733',
            // transition: 'backgroundColor 5000s easeInOut 0s'
            transition: 'background-color 5000s ease-in-out 0s'

            // transition: theme.transitions.create(['backgroundColor'])

            // transition: 'all 5000s ease-in-out 0s'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: primaryColor
        }
      }
    }
  }
});

export default theme;
