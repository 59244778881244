import { Box, Typography, Stack, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { buttonStyle, footerStyle, headerStyle, theme } from './styles';
import ExportModal from './ExportModal';
import { protectedResources } from 'features/auth/config';
import { ThemeProvider } from '@mui/material/styles';

export default function FileExport({ onCancel, onSuccess }) {
  const [openPopup, setOpenPoup] = useState(false);
  const [isTxnCompleted, setisTxnCompleted] = useState(false);
  const [successFlag, setsuccessFlag] = useState(false);
  const [totalCount, settotalCount] = useState(0);
  const [fromDate, setfromDate] = useState();
  const [toDate, settoDate] = useState();
  const [errorMsg, seterrorMsg] = useState();
  const [fieldValidatation, setfieldValidatation] = useState('');

  const handleExport = async (e) => {
    e.preventDefault();
    setOpenPoup(true);
    setisTxnCompleted(false);
    setsuccessFlag(false);

    const URL = protectedResources.apiCreateList.getExportRequest(
      fromDate.format('YYYY-MM-DD'),
      toDate.format('YYYY-MM-DD')
    );
    fetch(URL, {
      method: 'GET',
      credentials: 'include'
    })
      .then((response) => {
        settotalCount(Number(response.headers.get('X-Line-Count')));
        return response.blob();
      })
      .then((blob) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute('download', 'IO_Data_Export.txt');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
        setisTxnCompleted(true);
        setsuccessFlag(true);
      })
      .catch((error) => {
        seterrorMsg(String(error));
        settotalCount(0);
        setisTxnCompleted(true);
        setsuccessFlag(false);
      });
  };
  const onPopupClose = () => {
    setOpenPoup(false);
    onSuccess();
  };
  const validateDateRange = (start, end) => {
    if (start && end && start > end) {
      setfieldValidatation('To date must be on or after the from date');
    } else {
      setfieldValidatation('');
    }
  };
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Box sx={headerStyle}>
          <Typography sx={{ display: 'flex', color: '#fff' }}>Export Procurement</Typography>
        </Box>

        <Box padding={'16px'}>
          <Typography fontWeight="bold">Select date range</Typography>
          <Typography>
            The export file will contain only the most recent record for procurements updated within
            the date parameters.
          </Typography>
          <Typography marginBottom={'10px'}>
            Date range must be maximum of one quarter in order for the export file size to be
            manageable.
          </Typography>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            display="flex"
            sx={{ marginLeft: '5%', width: '90%', justifyContent: 'space-between' }}>
            <DatePicker
              format="YYYY-MM-DD"
              label="From Date"
              onChange={(newValue) => {
                setfromDate(newValue);
                validateDateRange(newValue, toDate);
              }}
            />
            <DatePicker
              label="To Date"
              minDate={fromDate}
              format="YYYY-MM-DD"
              onChange={(newValue) => {
                settoDate(newValue);
                validateDateRange(fromDate, newValue);
              }}
              slotProps={{
                textField: {
                  helperText: fieldValidatation
                }
              }}
            />
          </Box>
        </LocalizationProvider>

        <Box sx={footerStyle}>
          <Stack spacing={2} sx={{ position: 'fixed', right: '5px' }} direction="row">
            <Button sx={{ color: 'black' }} onClick={onCancel()} variant="text" size="medium">
              Cancel
            </Button>
            <Button
              disabled={!(fromDate && toDate && !fieldValidatation)}
              onClick={handleExport}
              sx={buttonStyle}
              variant="contained"
              size="medium">
              Export
            </Button>
          </Stack>
        </Box>
        <ExportModal
          onPopupClose={onPopupClose}
          isTxnCompleted={isTxnCompleted}
          open={openPopup}
          successFlag={successFlag}
          totalCount={totalCount}
          errorMsg={errorMsg}
        />
      </ThemeProvider>
    </Fragment>
  );
}

FileExport.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
};
