import { protectedResources } from 'features/auth/config';
import { USER_TYPE_SUPER, USER_TYPE_REGULAR } from './userContext';

const { adminUserObject, regularUserObject, schema_group, schema_email, schema_displayName } =
  protectedResources.apiCreateList.getAuthConfig();

export const processAuthResponse = (response) => {
  let responseDefault = { isAuthenticated: false, userType: '', email: '', displayName: '' };
  try {
    const cliamedGroups = response?.principal?.attributes[schema_group];
    const email = response?.principal?.attributes[schema_email]
      ? response?.principal?.attributes[schema_email][0]
      : '';
    const displayName = response?.principal?.attributes[schema_displayName]
      ? response?.principal?.attributes[schema_displayName][0]
      : '';

    if (cliamedGroups && cliamedGroups.indexOf(adminUserObject) != -1) {
      responseDefault = {
        isAuthenticated: true,
        userType: USER_TYPE_SUPER,
        email: email,
        displayName: displayName
      };
    } else if (cliamedGroups && cliamedGroups.indexOf(regularUserObject) != -1) {
      responseDefault = {
        isAuthenticated: true,
        userType: USER_TYPE_REGULAR,
        email: email,
        displayName: displayName
      };
    } else {
      responseDefault = {
        isAuthenticated: false,
        userType: '',
        email: email,
        displayName: displayName
      };
    }
  } catch (error) {
    console.log(error);
  }
  return responseDefault;
};
