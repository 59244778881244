import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBarAlertComponent({
  alertMessage,
  severityLevel,
  snackBarOpenStatus,
  handleSnackBarClose
}) {
  const [open, setOpen] = React.useState(snackBarOpenStatus);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    if (handleSnackBarClose) {
      handleSnackBarClose();
    }
  };

  return (
    <Stack spacing={2} sx={{ width: '100%', zIndex: 999999 }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severityLevel} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
SnackBarAlertComponent.propTypes = {
  alertMessage: PropTypes.string.isRequired,
  severityLevel: PropTypes.string.isRequired,
  snackBarOpenStatus: PropTypes.bool.isRequired,
  handleSnackBarClose: PropTypes.func
};
