import { Controller, useFormContext } from 'react-hook-form';
import { Tooltip } from '@mui/material';
import { TextField } from '@mui/material';
import * as React from 'react';
import { registerFormElement } from '../../utils/helper';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';
import { StyledAutocomplete, StyledLabelBox } from './styled';

const myList = [
  // { id: 1, name: "'The Shawshank Redemption'" },
  // { id: 2, name: 'The Godfather' },
  // { id: 3, name: 'The Dark Knight' },
  // { id: 4, name: 'Part II' }
];
// const myList = ['The Shawshank Redemption', 'The Godfather', 'The Dark Knight', 'Part II'];
export const EnumeratedElementComponent = ({ inputDataObject, isDisplayed }) => {
  const { control } = useFormContext();
  if (!isDisplayed) {
    return null;
  }
  return (
    <>
      <Controller
        // name={inputDataObject.name.replace(/[\s/]/g, "")}
        // name={getUniqueFormElementName(inputDataObject)}
        name={registerFormElement(inputDataObject)}
        control={control}
        // defaultValue={dataSetDescription}
        render={({ field: { onChange, value } }) => {
          // console.log('value in ENUMERATEDCOMPONENT:', typeof value);

          return (
            <StyledAutocomplete
              disablePortal
              id="combo-box-demo"
              options={inputDataObject.list ? inputDataObject.list : myList}
              // options={
              //   inputDataObject.list
              //     ? inputDataObject.list.sort((a, b) => a.name.localeCompare(b.name))
              //     : myList
              // }
              fullWidth
              value={
                value !== 0
                  ? inputDataObject.list
                    ? inputDataObject.list.find((list) => {
                        return value === list.id;
                      })
                    : myList
                  : null
              }
              getOptionLabel={(option) => {
                // console.log('lets test option', option);
                return option.name;
              }}
              // value={null}
              // sx={{ m: 1 }}
              sx={{ m: 0.5 }}
              // onChange={(event, newValue) => {
              //   const selectedIndex = newValue ? myList.indexOf(newValue) : null;
              //   onChange(selectedIndex);
              // }}
              onChange={(event, newValue) => {
                // console.log('NEWVALUE SHOULD BE OBJECT', newValue);
                onChange(newValue ? newValue.id : 0);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <StyledLabelBox>
                      {inputDataObject.name}
                      {inputDataObject.hasDescription && (
                        <Tooltip title={inputDataObject.description}>
                          <HelpOutlineIcon
                            fontSize="small"
                            sx={{ ml: 1, verticalAlign: 'middle', pointerEvents: 'auto' }}
                          />
                        </Tooltip>
                      )}
                    </StyledLabelBox>
                  }
                />
              )}
            />
          );
        }}
        // rules={{key:inputDataObject.id}}
      />
    </>
  );
};
EnumeratedElementComponent.propTypes = {
  inputDataObject: PropTypes.object,
  isDisplayed: PropTypes.bool
};
