import styled from '@emotion/styled';
import { Step, Stepper } from '@mui/material';
import { alpha } from '@mui/system';

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  '& .MuiStepConnector-root': {
    left: `calc(-30% + 40px)`,
    right: `calc(70% + 40px)`
  },
  '& .MuiStepConnector-line': {
    marginTop: '25px ' // To position the line lower
  },
  // "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
  //     borderColor: "red",
  //
  // },
  backgroundColor: alpha(theme.palette.common.black, 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.15)
  },
  height: '80px',
  // border: '1px solid rgba(223, 223, 223, 0.6)',
  // border:'1px solid blue',
  display: 'flex',
  alignItems: 'center'
}));
export const StyledStep = styled(Step)(() =>
  // {
  //   theme,
  //   activeStep, stepIndex
  // }
  ({
    // border:'1px solid red',
    // height:'10px',
    // width:'10px',
    // color: alpha(theme.palette.common.black, 0.15),
    transition: 'all 0.3s'
    // '&:hover': {
    //     backgroundColor: alpha(theme.palette.common.white, 1),
    // },
    // ...(activeStep === stepIndex && {
    //     color: 'green',
    //     fontWeight: 'bold',
    // }),
    // border:'1px solid blue',
  })
);
