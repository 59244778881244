import { createBrowserRouter } from 'react-router-dom';
import RootLayout from 'pages';
import HomePage from 'pages/home';
// import AuthenticationPage from "pages/auth";
import Common from 'pages/procurement';
// import { appRoles } from 'features/auth/config';
import { RouteGuard } from '../components/RouteGuard';
import Logout from 'pages/logout';
// import { config } from 'features/auth/constants';

const AppRouter = createBrowserRouter([
  {
    path: '/',
    element: (
      <RouteGuard>
        <>
          <RootLayout />
        </>
      </RouteGuard>
    ),
    id: 'root',
    children: [
      {
        index: true,
        element: (
          <RouteGuard>
            <>
              <HomePage />
            </>
          </RouteGuard>
        )
      },
      {
        path: 'common',
        element: (
          <RouteGuard>
            <>
              <Common />
            </>
          </RouteGuard>
        )
      }
    ]
  },
  {
    path: '/logout',
    element: <Logout />
  }
]);

export default AppRouter;
