import styled from '@emotion/styled';
// import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { alpha } from '@mui/system';

// export const StyledDesktopDatePicker = styled(DatePicker)`
//   && .MuiFormLabel-root.MuiInputLabel-root {
//     pointer-events: auto;
//   },
//
// `;
export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    color: alpha(theme.palette.common.black, 0.87)
  },
  '&& .MuiFormLabel-root.MuiInputLabel-root': {
    pointerEvents: 'auto'
  }
  // '& .MuiTypography-root.MuiDayCalendar-weekDayLabel': {
  //   /* Your styles here */
  //   color: alpha(theme.palette.common.black, 0.87)
  // }
  // '& .MuiPickersCalendarHeader-root': {
  //   color: alpha(theme.palette.common.black, 0.38)
  // }
}));
