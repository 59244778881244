import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';

export const StyledGridContainer = styled(Grid)(() => ({
  padding: '5px'
  // border: '1px solid blue'
}));
export const StyledGridItem = styled(Grid)(() => ({
  padding: '8px',
  margin: '5px 0px'
  // border: '1px solid red'
}));
export const StyledBoxContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));
export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  height: '20px',
  width: '4px',
  backgroundColor: theme.palette.secondary.main,
  marginRight: '8px'
}));
export const StyledCircleContainer = styled(Box)(({ theme }) => ({
  height: '10px',
  width: '10px',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '50%',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  marginRight: '8px',
  position: 'relative'
}));
export const StyledCircle = styled(Box)(({ theme }) => ({
  height: '5px',
  width: '5px',
  backgroundColor: theme.palette.common.white,
  borderRadius: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
  left: '50%',
  top: '50%'
}));
