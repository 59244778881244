const prod = {
  urlConfig: {
    apiCreateList: {
      createTombstoneEndpoint:
        'https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/tombstones',
      getSubmissionEndpoint: (procNum) => {
        const procNumber = procNum;
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/submissions?procNumber=${procNumber}`;
      },
      getCommonVendorEndpoint: (value) => {
        const myValue = value;
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/commonVendorNameLookup?cmnVenNmValue=${myValue}`;
      },
      getAllLookupsEndPoint:
        'https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/lookups',
      getProcurementDetails: (value) => {
        const myValue = value;
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/tombstone?procNumber=${myValue}`;
      },
      getUpdateTombstone: (value) => {
        const myValue = value;
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/updateTombstones?procNumber=${myValue}`;
      },
      getUpdateSubmission: (procNum, subId) => {
        const procNumber = procNum;
        const submissionId = subId;
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/updateSubmission?procNumber=${procNumber}&subId=${submissionId}`;
      },
      getDeleteTombstone: (value) => {
        const myValue = value;
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/deleteTombstone?procNumber=${myValue}&undeleteFlag=false`;
      },
      getUndeleteTombstone: (value) => {
        const myValue = value;
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/deleteTombstone?procNumber=${myValue}&undeleteFlag=true`;
      },
      getDeleteSubmission: (value) => {
        const myValue = value;
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/deleteSubmission?subId=${myValue}&undeleteFlag=false`;
      },
      getUndeleteSubmission: (value) => {
        const myValue = value;
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/deleteSubmission?subId=${myValue}&undeleteFlag=true`;
      },
      getCommonVendorLookupById: (id) => {
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/commonVendorNameLookupById?id=${id}`;
      },
      getExportRequest: (from, to) => {
        const myFrom = from;
        const myTo = to;
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/exportTombstone?startDate=${myFrom}&endDate=${myTo}`;
      },
      getImportRequest: () => {
        return `https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/importTombstone`;
      },
      getAuthConfig: () => {
        // const authConfig = {
        //   adminUserObject: '6b7ea313-1ee8-42b5-b5c0-5ea81a94f3e7',
        //   regularUserObject: '6e6ba9f7-1e6a-47a6-a9cf-17a6bd3171ed',
        //   schema_group: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groups',
        //   schema_email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
        //   schema_displayName: 'http://schemas.microsoft.com/identity/claims/displayname',
        //   getUserURL: 'https://twedi.mastechinfotrellis.com/api/procurement/initiate/userinfo',
        //   loginURL: 'https://twedi.mastechinfotrellis.com/api/procurement/initiate/saml',
        //   loginURLRedirect: 'https://twedi.mastechinfotrellis.com/initiate/saml',
        //   logoutURL: 'https://twedi.mastechinfotrellis.com/api/procurement/saml/logout'
        // };
        // IO PROD AD CONFIG
        const authConfig = {
          adminUserObject: '3261bdee-2389-41c8-a992-ba7fba44ba60',
          regularUserObject: '77cc94a6-0247-4d5f-8405-a235b6fd7b73',
          schema_group: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groups',
          schema_email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
          schema_displayName:
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/displayname/displayname',
          getUserURL:
            'https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/initiate/userinfo',
          loginURL:
            'https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/initiate/saml',
          loginURLRedirect: 'https://twedi.mastechinfotrellis.com/saml',
          logoutURL:
            'https://twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/saml/logout'
        };
        //END OF PROD IO AD CONFIG
        return authConfig;
      }

      // scopes: ['api://c08646f9-bdf3-4454-9c82-12ac59ba9101/io-prod-web-ac-req-scope']
    }
  }
};
const dev = {
  urlConfig: {
    apiCreateList: {
      createTombstoneEndpoint:
        'https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/tombstones',
      getSubmissionEndpoint: (procNum) => {
        const procNumber = procNum;
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/submissions?procNumber=${procNumber}`;
      },
      getCommonVendorEndpoint: (value) => {
        const myValue = value;
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/commonVendorNameLookup?cmnVenNmValue=${myValue}`;
      },
      getAllLookupsEndPoint:
        'https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/lookups',
      getProcurementDetails: (value) => {
        const myValue = value;
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/tombstone?procNumber=${myValue}`;
      },
      getUpdateTombstone: (value) => {
        const myValue = value;
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/updateTombstones?procNumber=${myValue}`;
      },
      getUpdateSubmission: (procNum, subId) => {
        const procNumber = procNum;
        const submissionId = subId;
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/updateSubmission?procNumber=${procNumber}&subId=${submissionId}`;
      },
      getDeleteTombstone: (value) => {
        const myValue = value;
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/deleteTombstone?procNumber=${myValue}&undeleteFlag=false`;
      },
      getUndeleteTombstone: (value) => {
        const myValue = value;
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/deleteTombstone?procNumber=${myValue}&undeleteFlag=true`;
      },
      getDeleteSubmission: (value) => {
        const myValue = value;
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/deleteSubmission?subId=${myValue}&undeleteFlag=false`;
      },
      getUndeleteSubmission: (value) => {
        const myValue = value;
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/deleteSubmission?subId=${myValue}&undeleteFlag=true`;
      },
      getCommonVendorLookupById: (id) => {
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/commonVendorNameLookupById?id=${id}`;
      },
      getExportRequest: (from, to) => {
        const myFrom = from;
        const myTo = to;
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/exportTombstone?startDate=${myFrom}&endDate=${myTo}`;
      },
      getImportRequest: () => {
        return `https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/importTombstone`;
      },
      getAuthConfig: () => {
        const authConfig = {
          adminUserObject: '6b7ea313-1ee8-42b5-b5c0-5ea81a94f3e7',
          regularUserObject: '6e6ba9f7-1e6a-47a6-a9cf-17a6bd3171ed',
          schema_group: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groups',
          schema_email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
          schema_displayName: 'http://schemas.microsoft.com/identity/claims/displayname',
          getUserURL:
            'https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/initiate/userinfo',
          loginURL:
            'https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/initiate/saml',
          loginURLRedirect: 'https://dev-twedi.mastechinfotrellis.com/saml',
          logoutURL:
            'https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/saml/logout'
        };
        // IO DEV AD CONFIG
        // const authConfig = {
        //   adminUserObject: '3261bdee-2389-41c8-a992-ba7fba44ba60',
        //   regularUserObject: '77cc94a6-0247-4d5f-8405-a235b6fd7b73',
        //   schema_group: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groups',
        //   schema_email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
        //   schema_displayName:
        //     'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/displayname/displayname',
        //   getUserURL:
        //     'https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/initiate/userinfo',
        //   loginURL:
        //     'https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/initiate/saml',
        //   loginURLRedirect: 'https://dev-twedi.mastechinfotrellis.com/saml',
        //   logoutURL:
        //     'https://dev-twedi.mastechinfotrellis.com/iocrud/api/procurement/iocrud/saml/logout'
        // };
        //END OF DEV IO AD CONFIG
        return authConfig;
      }
      // DEV END
      // scopes: ['api://22f0e012-3d2e-4c87-b9b6-4e199a613558/approle']
    }
  }
};

const local = {
  urlConfig: {
    apiCreateList: {
      // createTombstoneEndpoint: 'http://localhost:80/iocrud/api/procurement/tombstones',
      createTombstoneEndpoint: 'http://localhost:8080/tombstones',
      getSubmissionEndpoint: (procNum) => {
        const procNumber = procNum;
        // return `http://localhost:80/iocrud/api/procurement/submissions?procNumber=${procNumber}`;
        return `http://localhost:8080/submissions?procNumber=${procNumber}`;
      },
      getCommonVendorEndpoint: (value) => {
        const myValue = value;
        // return `http://localhost:80/iocrud/api/procurement/commonVendorNameLookup?cmnVenNmValue=${myValue}`;
        return `http://localhost:8080/commonVendorNameLookup?cmnVenNmValue=${myValue}`;
      },
      // getAllLookupsEndPoint: 'http://localhost:80/iocrud/api/procurement/lookups',
      getAllLookupsEndPoint: 'http://localhost:8080/lookups',
      getProcurementDetails: (value) => {
        const myValue = value;
        // return `http://localhost:80/iocrud/api/procurement//tombstone?procNumber=${myValue}`;
        return `http://localhost:8080/tombstone?procNumber=${myValue}`;
      },
      getUpdateTombstone: (value) => {
        const myValue = value;
        // return `http://localhost:80/iocrud/api/procurement/updateTombstones?procNumber=${myValue}`;
        return `http://localhost:8080/updateTombstones?procNumber=${myValue}`;
      },
      getUpdateSubmission: (procNum, subId) => {
        const procNumber = procNum;
        const submissionId = subId;
        // return `http://localhost:80/iocrud/api/procurement/updateSubmission?procNumber=${procNumber}&subId=${submissionId}`;
        return `http://localhost:8080/updateSubmission?procNumber=${procNumber}&subId=${submissionId}`;
      },
      getDeleteTombstone: (value) => {
        const myValue = value;
        // return `http://localhost:80/iocrud/api/procurement/deleteTombstone?procNumber=${myValue}&undeleteFlag=false`;
        return `http://localhost:8080/deleteTombstone?procNumber=${myValue}&undeleteFlag=false`;
      },
      getUndeleteTombstone: (value) => {
        const myValue = value;
        // return `http://localhost:80/iocrud/api/procurement/deleteTombstone?procNumber=${myValue}&undeleteFlag=true`;
        return `http://localhost:8080/deleteTombstone?procNumber=${myValue}&undeleteFlag=true`;
      },
      getDeleteSubmission: (value) => {
        const myValue = value;
        // return `http://localhost:80/iocrud/api/procurement/deleteSubmission?subId=${myValue}&undeleteFlag=false`;
        return `http://localhost:8080/deleteSubmission?subId=${myValue}&undeleteFlag=false`;
      },
      getUndeleteSubmission: (value) => {
        const myValue = value;
        // return `http://localhost:80/iocrud/api/procurement/deleteSubmission?subId=${myValue}&undeleteFlag=true`;
        return `http://localhost:8080/deleteSubmission?subId=${myValue}&undeleteFlag=true`;
      },
      getCommonVendorLookupById: (id) => {
        // return `http://localhost:80/iocrud/api/procurement/commonVendorNameLookupById?id=${id}`;
        return `http://localhost:8080/commonVendorNameLookupById?id=${id}`;
      },
      getExportRequest: (from, to) => {
        const myFrom = from;
        const myTo = to;
        // return `http://localhost:80/iocrud/api/procurement/exportTombstone?startDate=${myFrom}&endDate=${myTo}`;
        return `http://localhost:8080/exportTombstone?startDate=${myFrom}&endDate=${myTo}`;
      },
      getImportRequest: () => {
        // return `http://localhost:80/iocrud/api/procurement/importTombstone`;
        return `http://localhost:8080/importTombstone`;
      },
      getAuthConfig: () => {
        // const authConfig = {
        //   adminUserObject: '6b7ea313-1ee8-42b5-b5c0-5ea81a94f3e7',
        //   regularUserObject: '6e6ba9f7-1e6a-47a6-a9cf-17a6bd3171ed',
        //   schema_group: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groups',
        //   schema_email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
        //   schema_displayName: 'http://schemas.microsoft.com/identity/claims/displayname',
        //   getUserURL: 'http://localhost:80/api/procurement/initiate/userinfo',
        //   loginURL: 'http://localhost:80/api/procurement/initiate/saml',
        //   loginURLRedirect: 'http://localhost:8080/initiate/saml',
        //   logoutURL: 'http://localhost:80/api/procurement/saml/logout'
        // };
        const authConfig = {
          adminUserObject: '6b7ea313-1ee8-42b5-b5c0-5ea81a94f3e7',
          regularUserObject: '6e6ba9f7-1e6a-47a6-a9cf-17a6bd3171ed',
          schema_group: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groups',
          schema_email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
          schema_displayName: 'http://schemas.microsoft.com/identity/claims/displayname',
          // getUserURL: 'http://localhost:80/iocrud/api/procurement/iocrud/initiate/userinfo',
          // loginURL: 'http://localhost:80/iocrud/api/procurement/iocrud/initiate/saml',
          // loginURLRedirect: 'http://localhost:80/saml',
          getUserURL: 'http://localhost:8080/iocrud/initiate/userinfo',
          loginURL: 'http://localhost:8080/iocrud/initiate/saml',
          loginURLRedirect: 'http://localhost:8080/saml',
          logoutURL: 'https://localhost:80/iocrud/api/procurement/iocrud/saml/logout'
        };
        return authConfig;
      }
      //    scopes: ['api://c08646f9-bdf3-4454-9c82-12ac59ba9101/io-prod-web-ac-req-scope']
    }
  }
  // msalConfig: {
  //   clientId: 'c08646f9-bdf3-4454-9c82-12ac59ba9101', // This is the ONLY mandatory field that you need to supply.
  //   authority: 'https://login.microsoftonline.com/31f0a28d-0d71-49bc-82df-897c64f85c63'
  // }
  // roleConfig: {
  //   TaskAdmin: 'SuperUser',
  //   TaskUser: 'ProcurementLead'
  // }
};
let exportedConfig = local;
// eslint-disable-next-line no-undef
if (process.env.REACT_APP_UI_ENV === 'dev') {
  exportedConfig = dev;
}

// eslint-disable-next-line no-undef
if (process.env.REACT_APP_UI_ENV === 'prod') {
  exportedConfig = prod;
}

export const config = exportedConfig;
