import { areDataElementsToDisplay, RenderForm } from '../../utils/helper';
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  StyledBoxContainer,
  StyledGridContainer,
  StyledGridItem,
  StyledHeaderBox
} from '../../features/procurementAttributes/commonStyles/styled';

function DrawerTabEvaluation({
  data
  // resetClickHandler
}) {
  const [evaluationGroup, setEvaluationGroup] = useState(null);
  const [evaluationOverviewGroup, setEvaluationOverviewGroup] = useState(null);
  const [financialEvaluationGroup, setFinancialEvaluationGroup] = useState(null);
  const [technicalEvaluationGroup, setTechnicalEvaluationGroup] = useState(null);
  const [evaluationVendorSuccessfulSubgroup, setEvaluationVendorSuccessfulSubgroup] =
    useState(null);
  const [evaluationVendorRankSubgroup, setEvaluationVendorRankSubgroup] = useState(null);
  const [evaluationTechnicalScoreSubgroup, setEvaluationTechnicalScoreSubgroup] = useState(null);
  const [evaluationFinancialScoreSubgroup, setEvaluationFinancialScoreSubgroup] = useState(null);
  const [evaluationOverallScoreSubgroup, setEvaluationOverallScoreSubgroup] = useState(null);
  const [evaluationFinancialRatingSubgroup, setEvaluationFinancialRatingSubgroup] = useState(null);
  const [evaluationPhase1Subgroup, setEvaluationPhase1Subgroup] = useState(null);
  const [evaluationPhase2VendorSuccessfulSubgroup, setEvaluationPhase2VendorSuccessfulSubgroup] =
    useState(null);
  const [evaluationPhase2VendorRankSubgroup, setEvaluationPhase2VendorRankSubgroup] =
    useState(null);
  const [evaluationPhase2TechnicalScore, setEvaluationPhase2TechnicalScore] = useState(null);

  //TechnicalEvaluation SubGrouping begins
  const [technicalApplicantOverallSubgroup, setTechnicalApplicantOverallSubgroup] = useState(null);
  const [technicalFacilitiesSubgroup, setTechnicalFacilitiesSubgroup] = useState(null);
  const [technicalOperationsSubgroup, setTechnicalOperationsSubgroup] = useState(null);
  const [technicalMeSubgroup, setTechnicalMeSubgroup] = useState(null);
  const [technicalPhase1ProponentSubgroup, setTechnicalPhase1ProponentSubgroup] = useState(null);
  const [technicalPhase1FacilitiesSubgroup, setTechnicalPhase1FacilitiesSubgroup] = useState(null);
  const [technicalPhase1OperationsSubgroup, setTechnicalPhase1OperationsSubgroup] = useState(null);
  const [technicalRespondentSubgroup, setTechnicalRespondentSubgroup] = useState(null);
  const [technicalUnderstandingSubgroup, setTechnicalUnderstandingSubgroup] = useState(null);
  const [technicalOrganizationSubgroup, setTechnicalOrganizationSubgroup] = useState(null);
  const [technicalCommonSubgroup, setTechnicalCommonSubgroup] = useState(null);

  //Financial Evaluation SubGrouping Begins

  const [financialTransactionSubgroup, setFinancialTransactionSubgroup] = useState(null);

  // const [financialUnderstandingSubgroup, setFinancialUnderstandingSubgroup] = useState(null);
  // const [financialImplementationSubgroup, setFinancialImplementationSubgroup] = useState(null);

  // const evaluationGroup = {
  //   ...data,
  //   data: data.data.filter((el) => el.family === 'evaluation')
  // };
  useEffect(() => {
    if (data) {
      setEvaluationGroup(() => ({
        ...data,
        data: data.data.filter((el) => el.family === 'evaluation')
      }));
    }
  }, [data]);
  useEffect(() => {
    if (evaluationGroup) {
      // console.log('lets test evaluation group', evaluationGroup);
      setEvaluationOverviewGroup(() => ({
        ...evaluationGroup,
        data: evaluationGroup.data.slice(0, 26)
      }));
      setFinancialEvaluationGroup(() => ({
        ...evaluationGroup,
        data: evaluationGroup.data.slice(67)
      }));
      setTechnicalEvaluationGroup(() => ({
        ...evaluationGroup,
        data: evaluationGroup.data.slice(26, 67)
      }));
    }
  }, [evaluationGroup]);
  useEffect(() => {
    if (evaluationOverviewGroup) {
      // console.log('lets test Evauation overview Group', evaluationOverviewGroup);
      setEvaluationVendorSuccessfulSubgroup(() => ({
        ...evaluationOverviewGroup,
        data: evaluationOverviewGroup.data.slice(0, 2)
      }));
      setEvaluationVendorRankSubgroup(() => ({
        ...evaluationOverviewGroup,
        data: evaluationOverviewGroup.data.slice(2, 4)
      }));
      setEvaluationTechnicalScoreSubgroup(() => ({
        ...evaluationOverviewGroup,
        data: evaluationOverviewGroup.data.slice(4, 6)
      }));
      setEvaluationFinancialScoreSubgroup(() => ({
        ...evaluationOverviewGroup,
        data: evaluationOverviewGroup.data.slice(6, 8)
      }));
      setEvaluationOverallScoreSubgroup(() => ({
        ...evaluationOverviewGroup,
        data: evaluationOverviewGroup.data.slice(8, 10)
      }));
      setEvaluationFinancialRatingSubgroup(() => ({
        ...evaluationOverviewGroup,
        data: evaluationOverviewGroup.data.slice(10, 11)
      }));
      setEvaluationPhase1Subgroup(() => ({
        ...evaluationOverviewGroup,
        data: evaluationOverviewGroup.data.slice(11, 17)
      }));
      setEvaluationPhase2VendorSuccessfulSubgroup(() => ({
        ...evaluationOverviewGroup,
        data: evaluationOverviewGroup.data.slice(17, 19)
      }));
      setEvaluationPhase2VendorRankSubgroup(() => ({
        ...evaluationOverviewGroup,
        data: evaluationOverviewGroup.data.slice(19, 20)
      }));
      setEvaluationPhase2TechnicalScore(() => ({
        ...evaluationOverviewGroup,
        data: evaluationOverviewGroup.data.slice(20)
      }));
    }
  }, [evaluationOverviewGroup]);
  useEffect(() => {
    if (technicalEvaluationGroup) {
      // console.log('lets test technical evaluation group', technicalEvaluationGroup);
      setTechnicalApplicantOverallSubgroup(() => ({
        ...technicalEvaluationGroup,
        data: technicalEvaluationGroup.data.slice(0, 8)
      }));
      setTechnicalFacilitiesSubgroup(() => ({
        ...technicalEvaluationGroup,
        data: technicalEvaluationGroup.data.slice(8, 10)
      }));
      setTechnicalOperationsSubgroup(() => ({
        ...technicalEvaluationGroup,
        data: technicalEvaluationGroup.data.slice(10, 12)
      }));
      setTechnicalMeSubgroup(() => ({
        ...technicalEvaluationGroup,
        data: technicalEvaluationGroup.data.slice(12, 13)
      }));
      setTechnicalPhase1ProponentSubgroup(() => ({
        ...technicalEvaluationGroup,
        data: technicalEvaluationGroup.data.slice(13, 21)
      }));
      setTechnicalPhase1FacilitiesSubgroup(() => ({
        ...technicalEvaluationGroup,
        data: technicalEvaluationGroup.data.slice(21, 23)
      }));
      setTechnicalPhase1OperationsSubgroup(() => ({
        ...technicalEvaluationGroup,
        data: technicalEvaluationGroup.data.slice(23, 29)
      }));
      setTechnicalRespondentSubgroup(() => ({
        ...technicalEvaluationGroup,
        data: technicalEvaluationGroup.data.slice(29, 31)
      }));
      setTechnicalUnderstandingSubgroup(() => ({
        ...technicalEvaluationGroup,
        data: technicalEvaluationGroup.data.slice(31, 33)
      }));
      setTechnicalOrganizationSubgroup(() => ({
        ...technicalEvaluationGroup,
        data: technicalEvaluationGroup.data.slice(33, 37)
      }));
      setTechnicalCommonSubgroup(() => ({
        ...technicalEvaluationGroup,
        data: technicalEvaluationGroup.data.slice(37)
      }));
    }
  }, [technicalEvaluationGroup]);
  useEffect(() => {
    if (financialEvaluationGroup) {
      // setFinancialRespondentSubgroup(() => ({
      //   ...financialEvaluationGroup,
      //   data: financialEvaluationGroup.data.slice(0, 6)
      // }));
      setFinancialTransactionSubgroup(() => ({
        ...financialEvaluationGroup,
        data: financialEvaluationGroup.data.slice(0)
      }));
      // setFinancialOrganizationSubgroup(() => ({
      //   ...financialEvaluationGroup,
      //   data: financialEvaluationGroup.data.slice(10, 14)
      // }));
      // setFinancialUnderstandingSubgroup(() => ({
      //   ...financialEvaluationGroup,
      //   data: financialEvaluationGroup.data.slice(14, 20)
      // }));
      // setFinancialImplementationSubgroup(() => ({
      //   ...financialEvaluationGroup,
      //   data: financialEvaluationGroup.data.slice(20)
      // }));
    }
  }, [financialEvaluationGroup]);
  return (
    <>
      {/*<p>Drawer Tab Eval</p>*/}
      {/*<RenderForm formDataConfig={data}/>*/}
      {/*<Typography variant='h6'> Evaluation Overview</Typography>*/}

      {/*<Grid container style={{ padding: '5px',flex: 1 }}>*/}
      {/*    {<RenderForm formDataConfig={evaluationGroup}/>}*/}
      {/*</Grid>*/}
      {evaluationOverviewGroup && areDataElementsToDisplay(evaluationOverviewGroup.data) && (
        <StyledGridContainer container style={{ flex: 1 }}>
          <StyledGridItem item xs={12} sx={{ mr: 'auto' }}>
            <StyledBoxContainer>
              <StyledHeaderBox />
              <Typography variant="h2" sx={{ mr: 'auto' }}>
                {' '}
                Evaluation Overview
              </Typography>
              {/* <Button
                variant="outlined"
                id="evaluation"
                onClick={(e) => {
                  resetClickHandler(e);
                }}>
                {' '}
                Reset
              </Button> */}
            </StyledBoxContainer>
          </StyledGridItem>
          {/*<StyledGridItem item xs={4}>*/}
          {/*  <Button*/}
          {/*    variant="outlined"*/}
          {/*    id="evaluation"*/}
          {/*    onClick={(e) => {*/}
          {/*      resetClickHandler(e);*/}
          {/*    }}>*/}
          {/*    {' '}*/}
          {/*    Reset*/}
          {/*  </Button>*/}
          {/*</StyledGridItem>*/}
          {/*<RenderForm formDataConfig={evaluationOverviewGroup} />*/}
          {areDataElementsToDisplay(evaluationVendorSuccessfulSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={evaluationVendorSuccessfulSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(evaluationVendorRankSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={evaluationVendorRankSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(evaluationTechnicalScoreSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={evaluationTechnicalScoreSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(evaluationFinancialScoreSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={evaluationFinancialScoreSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(evaluationOverallScoreSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={evaluationOverallScoreSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(evaluationFinancialRatingSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={evaluationFinancialRatingSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(evaluationPhase1Subgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={evaluationPhase1Subgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(evaluationPhase2VendorSuccessfulSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={evaluationPhase2VendorSuccessfulSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(evaluationPhase2VendorRankSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={evaluationPhase2VendorRankSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(evaluationPhase2TechnicalScore?.data) && (
            <Grid container>
              <RenderForm formDataConfig={evaluationPhase2TechnicalScore} />
            </Grid>
          )}
        </StyledGridContainer>
      )}
      {technicalEvaluationGroup && areDataElementsToDisplay(technicalEvaluationGroup.data) && (
        <StyledGridContainer container style={{ flex: 1 }}>
          <StyledGridItem item xs={12}>
            <StyledBoxContainer>
              <StyledHeaderBox />
              <Typography variant="h2">Technical Evaluation</Typography>
            </StyledBoxContainer>
          </StyledGridItem>
          {/*<Grid item xs={12} sx={{ padding: '8px' }}>*/}
          {/*  <Typography variant="h5">Technical Evaluation</Typography>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12}>*/}
          {/*  <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} />*/}
          {/*</Grid>*/}
          {/*<RenderForm formDataConfig={technicalEvaluationGroup} />*/}
          {areDataElementsToDisplay(technicalApplicantOverallSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={technicalApplicantOverallSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(technicalFacilitiesSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={technicalFacilitiesSubgroup} xs={12} />
            </Grid>
          )}
          {areDataElementsToDisplay(technicalOperationsSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={technicalOperationsSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(technicalMeSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={technicalMeSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(technicalPhase1ProponentSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={technicalPhase1ProponentSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(technicalPhase1FacilitiesSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={technicalPhase1FacilitiesSubgroup} xs={12} />
            </Grid>
          )}
          {areDataElementsToDisplay(technicalPhase1OperationsSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={technicalPhase1OperationsSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(technicalRespondentSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={technicalRespondentSubgroup} xs={12} />
            </Grid>
          )}
          {areDataElementsToDisplay(technicalUnderstandingSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={technicalUnderstandingSubgroup} />
            </Grid>
          )}
          {areDataElementsToDisplay(technicalOrganizationSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={technicalOrganizationSubgroup} xs={12} />
            </Grid>
          )}
          {areDataElementsToDisplay(technicalCommonSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={technicalCommonSubgroup} />
            </Grid>
          )}
        </StyledGridContainer>
      )}
      {financialEvaluationGroup && areDataElementsToDisplay(financialEvaluationGroup.data) && (
        <Grid container style={{ flex: 1 }}>
          {/*<Grid item xs={12} sx={{ padding: '8px' }}>*/}
          {/*  <Typography variant="h5">Financial Evaluation</Typography>*/}
          {/*</Grid>*/}
          <StyledGridItem item xs={12}>
            <StyledBoxContainer>
              <StyledHeaderBox />
              <Typography variant="h2"> Financial Evaluation</Typography>
            </StyledBoxContainer>
          </StyledGridItem>
          {/*<Grid item xs={12}>*/}
          {/*  <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} />*/}
          {/*</Grid>*/}
          {areDataElementsToDisplay(financialTransactionSubgroup?.data) && (
            <Grid container>
              <RenderForm formDataConfig={financialTransactionSubgroup} />
            </Grid>
          )}

          {/*{areDataElementsToDisplay(financialUnderstandingSubgroup?.data) && (*/}
          {/*  <Grid container>*/}
          {/*    <RenderForm formDataConfig={financialUnderstandingSubgroup} />*/}
          {/*  </Grid>*/}
          {/*)}*/}
          {/*{areDataElementsToDisplay(financialImplementationSubgroup?.data) && (*/}
          {/*  <Grid container>*/}
          {/*    <RenderForm formDataConfig={financialImplementationSubgroup} xs={12} />*/}
          {/*  </Grid>*/}
          {/*)}*/}
          {/*{financialEvaluationGroup && <div>{JSON.stringify(financialEvaluationGroup)}</div>}*/}
        </Grid>
      )}
    </>
  );
}

export default DrawerTabEvaluation;
DrawerTabEvaluation.propTypes = {
  data: PropTypes.object
  // resetClickHandler: PropTypes.func
};
