import styled from '@emotion/styled';
import { Autocomplete, Box } from '@mui/material';
import { alpha } from '@mui/system';

export const StyledLabelBox = styled(Box)(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.6)
  // display: 'flex',
  // alignItems: 'center',
  // maxWidth: '100%'
}));
export const StyledMultiAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-input': {
    color: alpha(theme.palette.common.black, 0.87)
  }
}));
// export const StyledChildBox = styled(Box)(() => ({
//   flex: 1,
//   maxWidth: 'calc(100% - 50px)',
//   overflow: 'hidden',
//   textOverflow: 'ellipsis',
//   pointerEvents: 'auto'
// }));
