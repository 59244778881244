import { Box } from '@mui/material';
import toronto from '../../image/toronto.png';

export default function LandingPage() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '90vh',
        backgroundImage: `url(${toronto})`,
        objectFit: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        verticalAlign: 'middle',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '0px',
        left: '0px'
      }}></Box>
  );
}
