import * as React from 'react';

import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { StyledDialogContentTextBody, StyledDialogContentTextHeader } from './styled';

export default function FormChangeAlertComponent({
  descriptionHeader,
  descriptionBody,
  button1Label,
  button2Label,
  popupStatus,
  button1Handler,
  button2Handler
}) {
  const [open, setOpen] = React.useState(popupStatus);

  const button1ClickHandler = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
    button1Handler();
  };
  const button2ClickHandler = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
    button2Handler(event);
  };

  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minWidth: '443px',
            height: '200px'
            // border: '1px solid green'
          }
        }}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
        <DialogContent>
          <StyledDialogContentTextHeader id="alert-dialog-description">
            {descriptionHeader}
          </StyledDialogContentTextHeader>
          <StyledDialogContentTextBody>{descriptionBody}</StyledDialogContentTextBody>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: '10px'
              // border: '1px solid red',
              // padding: '20px 24px'
            }}>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              sx={(theme) => ({
                marginRight: 'auto',
                color: theme.palette.primary.main,
                // border: '1px solid red',
                p: '9px',
                mr: '10px'
              })}>
              Do you wish to proceed?
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={(event) => {
                if (event.target.value === 'Y') {
                  // Perform operation when "Yes" is selected
                  button1ClickHandler();
                } else if (event.target.value === 'N') {
                  // Perform operation when "No" is selected
                  button2ClickHandler();
                }
              }}
              sx={{ flexDirection: 'row' }}>
              <FormControlLabel
                value="Y"
                control={
                  <Radio
                    sx={(theme) => ({
                      color: theme.palette.primary.main
                    })}
                  />
                }
                label={button1Label}
              />
              <FormControlLabel
                value="N"
                control={
                  <Radio
                    sx={(theme) => ({
                      color: theme.palette.primary.main
                    })}
                  />
                }
                label={button2Label}
              />
            </RadioGroup>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
FormChangeAlertComponent.propTypes = {
  descriptionHeader: PropTypes.string,
  descriptionBody: PropTypes.string,
  button1Label: PropTypes.string,
  button2Label: PropTypes.string,
  popupStatus: PropTypes.bool,
  button1Handler: PropTypes.func,
  button2Handler: PropTypes.func
};
