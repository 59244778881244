// This component is for setting up the routing for HomePage
import { Avatar, Divider, MenuItem, Toolbar, Typography } from '@mui/material';
import IOlogo4 from 'image/IO_Logo_White.png';
// import img from "image/IOlogo2.png"
// import {Link} from "react-router-dom";
import { AppNavBar, StyledMenu } from '../Header/styled';
import { useEffect, useState, useContext } from 'react';
// import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
// import { loginRequest } from 'features/auth/config';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// import { ThemeContext } from '../../ThemeContext';
import { UserContext } from 'features/auth/userContext';
import { protectedResources } from 'features/auth/config';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

const Header = ({ cancelClickHandler_ref }) => {
  // const { instance } = useMsal();
  // const { cancelClickHandler } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [initials, setInitials] = useState(null);
  const [fullName, setfullName] = useState(null);
  const userInfo = useContext(UserContext);
  const { logoutURL } = protectedResources.apiCreateList.getAuthConfig();
  const { execute } = useFetchWithMsal();

  const navigate = useNavigate();
  // const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // let activeAccount;

  // if (instance) {
  //   activeAccount = instance.getActiveAccount();
  //   console.log('lets test Active Account', activeAccount);
  // }
  const getInitials = (name) => {
    const words = name.split(' ');
    const initials = words.map((word) => word.charAt(0)).join('');
    return initials.toUpperCase();
  };

  useEffect(() => {
    setfullName(`${userInfo.displayName} ${userInfo.userType == 'SUPER' ? '(Super User)' : ''}`);
    setInitials(() => getInitials(userInfo.displayName));
  }, [userInfo.displayName, userInfo.userType]);

  const handleLogin = () => {
    navigate('/');
  };

  const handleLogout = async () => {
    try {
      const response = await execute('GET', logoutURL);
      return response;
    } catch (error) {
      console.log(error);
    }
    navigate('/logout');
    // instance
    //   .logoutPopup({
    //     mainWindowRedirectUri: '/logout', // redirects the top level app after logout
    //     account: instance.getActiveAccount()
    //   })
    //   .catch((error) => console.log(error));
  };

  // const handleLogoutRedirect = () => {
  //   instance.logoutRedirect().catch((error) => console.log(error));
  // };

  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <>
      <AppNavBar position="static" elevation={0}>
        <Toolbar
          disableGutters
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <img
            alt="Company Logo"
            src={IOlogo4}
            style={{
              width: '157.74px',

              // objectFit: 'scale-down',
              // border: '1px solid red',
              marginLeft: '1rem',
              cursor: 'pointer'
              // backgroundPosition: 'center',
              // border: 'transparent'
            }}
            onClick={() => {
              // console.log(cancelClickHandler_ref.current);
              if (cancelClickHandler_ref.current) {
                cancelClickHandler_ref.current();
                return;
              }
              navigate('/');
            }}
          />
          <Avatar
            onClick={handleClick}
            sx={{
              marginRight: '1rem',
              '&:hover': {
                cursor: 'pointer'
              }
            }}>
            {initials}
          </Avatar>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button'
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {/* Render appropriate menu items based on authentication status */}
            {userInfo.isAuthenticated
              ? [
                  <Typography key="displayname" sx={{ padding: '6px 16px' }}>
                    {fullName}
                  </Typography>,
                  <Divider key="divider" sx={{ my: 0.5 }} />,
                  <MenuItem key="signout" onClick={handleLogout} disableRipple>
                    Sign out
                  </MenuItem>
                ]
              : [
                  <Divider key="divider" sx={{ my: 0.5 }} />,
                  <MenuItem key="signin" onClick={handleLogin} disableRipple>
                    Sign in
                  </MenuItem>
                ]}
          </StyledMenu>
          {/*<AuthenticatedTemplate>*/}
          {/*  <StyledMenu*/}
          {/*    id="demo-customized-menu"*/}
          {/*    MenuListProps={{*/}
          {/*      'aria-labelledby': 'demo-customized-button'*/}
          {/*    }}*/}
          {/*    anchorEl={anchorEl}*/}
          {/*    open={open}*/}
          {/*    onClose={handleClose}>*/}
          {/*    <Typography sx={{ padding: '6px 16px' }}>{fullName}</Typography>*/}
          {/*    <Divider sx={{ my: 0.5 }} />*/}
          {/*    <MenuItem onClick={handleLogoutPopup} disableRipple>*/}
          {/*      Sign out*/}
          {/*    </MenuItem>*/}
          {/*  </StyledMenu>*/}
          {/*</AuthenticatedTemplate>*/}
          {/*<UnauthenticatedTemplate>*/}
          {/*  <StyledMenu*/}
          {/*    id="demo-customized-menu"*/}
          {/*    MenuListProps={{*/}
          {/*      'aria-labelledby': 'demo-customized-button'*/}
          {/*    }}*/}
          {/*    anchorEl={anchorEl}*/}
          {/*    open={open}*/}
          {/*    onClose={handleClose}>*/}
          {/*    <Divider sx={{ my: 0.5 }} />*/}
          {/*    <MenuItem onClick={handleLoginRedirect} disableRipple>*/}
          {/*      Sign in*/}
          {/*    </MenuItem>*/}
          {/*  </StyledMenu>*/}
          {/*</UnauthenticatedTemplate>*/}
        </Toolbar>
      </AppNavBar>
    </>
  );
};

export default Header;
Header.propTypes = {
  cancelClickHandler_ref: PropTypes.shape({ current: PropTypes.any })
};
