import styled from '@emotion/styled';
import { DialogContentText } from '@mui/material';

export const StyledDialogContentTextHeader = styled(DialogContentText)(() => ({
  fontWeight: '600',
  fontSize: '24px',
  lineHeight: '28px',
  color: '#181818'
}));

export const StyledDialogContentTextBody = styled(DialogContentText)(() => ({
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '21px',
  paddingTop: '5px',
  color: 'rgba(0,0,0,0.87)'
}));
