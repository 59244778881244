import { Box } from '@mui/material';
import * as React from 'react';
// import TabPanel from 'features/procurementAttributes/tabpanel';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Tombstone from './tombstone';
import tombstoneDataElementArray from 'constants/tombstone';
import resourcingDataElementArray from 'constants/resourcing';
import stagingDataElementArray from 'constants/staging';
import riskDataElementArray from 'constants/risk';
import Resourcing from './resourcing';
import Staging from './staging';
import Submission from './submission';
import {
  formatPrincipalDatesRecursively,
  getFormDataDisplayStatus,
  getRequestNumberToBeRendered,
  handleValidationChecker
} from 'utils/helper';
import { useCallback, useContext, useEffect, useState } from 'react';
import { submissionDataElementArray } from '../../constants/submission';
import FixedNavBar from '../../components/FixedNavBar';
import NonLinearStepper from '../../components/NonLinearStepper';
import SubmissionAlertComponent from '../../components/SubmissionAlertComponent';
import DialogAlertComponent from '../../components/DialogAlertComponent';
import {
  FORM_CHANGE_ALERT,
  NAV_BAR_DIALOG_ALERT,
  NAV_BAR_DIALOG_DELETE_ALERT,
  NAV_BAR_DIALOG_UNDELETE_ALERT,
  SEVERITY_WISE_ALERT_MESSAGE_ON_DELETE_TOMBSTONE,
  SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_TOMBSTONE,
  SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_TOMBSTONE
  // SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_TOMBSTONE
} from '../../constants';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { protectedResources } from '../auth/config';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
// import SnackBarAlertComponent from '../../components/SnackBarAlertComponent';
import { useCancelClickHandler_ref } from '../../pages';
import { ThemeContext } from '../../ThemeContext';
import FormChangeAlertComponent from '../../components/FormChangeAlertComponent';
import Risk from './risk';
// import PropTypes from 'prop-types';
// import Header from '../../components/Header';
// import { ThemeContext } from '../../ThemeContext';

// const myDefaultValues = {};
const myDefaultValuesObject = {
  tombstone: {
    resourcing: {},
    staging: {},
    risk: {}
  }
};

const DataElementArray = [
  ...tombstoneDataElementArray,
  ...resourcingDataElementArray,
  ...stagingDataElementArray,
  ...riskDataElementArray
];

function DataFamilies() {
  // const [value, setValue] = React.useState(0);
  // const [loadedTabs, setLoadedTabs] = useState([0]);
  const [validationSchema, setValidationSchema] = useState(null);
  const [validationSchemaObject, setValidationSchemaObject] = useState({});
  const [resourcingSchemaObject, setResourcingSchemaObject] = useState({});
  const [stagingSchemaObject, setStagingSchemaObject] = useState({});
  const [riskSchemaObject, setRiskSchemaObject] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupStatusMessage, setPopupStatusMessage] = useState(false);
  const [reqFormNumber, setReqFormNumber] = useState(null);
  const [procData, setProcData] = useState(null);
  const [update, setUpdate] = useState(false);
  const [navBarCancelClickedStatus, setNavBarCancelClickedStatus] = useState(false);
  const [navBarDeleteClickedStatus, setNavBarDeleteClickedStatus] = useState(false);
  const [formChangeAlert, setFormChangeAlert] = useState(false);
  //BElow condition to make sure for Alert is not being set to for the fist time on Save
  const [lookUpValues, setLookUpValues] = useState({});
  //Below is to show only when form set changes
  const [lookUpForm, setLookUpForm] = useState(null);
  //Below is to show formChange alert only during update
  const [formReset, setFormReset] = useState(false);
  const [allTabsData, setAllTabsData] = useState({
    tombstone: {
      isDisplayed: true,
      data: [...tombstoneDataElementArray]
    },
    resourcing: {
      isDisplayed: true,
      data: [...resourcingDataElementArray]
    },
    staging: {
      isDisplayed: true,
      data: [...stagingDataElementArray]
    },
    risk: {
      isDisplayed: true,
      data: [...riskDataElementArray]
    },
    submissions: {
      isDisplayed: false,
      data: [...submissionDataElementArray]
    }
  });
  // const [snackBarOpenStatus, setSnackBarOpenStatus] = useState(false);
  // const [severityLevel, setSeverityLevel] = useState('success');
  const { cancelClickHandler_ref } = useCancelClickHandler_ref();
  const { setSnackbarMessage, setSnackBarOpenStatus, setSeverityLevel } = useContext(ThemeContext);

  // const { setCancelClickHandler } = useContext(ThemeContext);
  const { execute } = useFetchWithMsal();

  if (Object.keys(myDefaultValuesObject.tombstone).length === 3) {
    DataElementArray.map((inputObject) => {
      switch (inputObject.type.toLowerCase()) {
        case 'string':
          switch (inputObject.family) {
            case 'tombstone':
              myDefaultValuesObject[inputObject.family][inputObject.id] = '';
              break;
            case 'resourcing':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = '';
              break;
            case 'staging':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = '';
              break;
            case 'risk':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = '';
              break;
            default:
          }

          break;
        case 'date':
          switch (inputObject.family) {
            case 'tombstone':
              myDefaultValuesObject[inputObject.family][inputObject.id] = null;
              break;
            case 'resourcing':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = null;
              break;
            case 'staging':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = null;
              break;
            default:
          }

          break;
        case 'currency':
          switch (inputObject.family) {
            case 'tombstone':
              myDefaultValuesObject[inputObject.family][inputObject.id] = null;
              break;
            case 'resourcing':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = null;
              break;
            case 'staging':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = null;
              break;
            default:
          }
          break;
        case 'enumerated':
          switch (inputObject.family) {
            case 'tombstone':
              myDefaultValuesObject[inputObject.family][inputObject.id] = 0;
              break;
            case 'resourcing':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = 0;
              break;
            case 'staging':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = 0;
              break;
            case 'risk':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = 0;
              break;
            default:
          }
          break;
        case 'binary':
          switch (inputObject.family) {
            case 'tombstone':
              myDefaultValuesObject[inputObject.family][inputObject.id] = '';
              break;
            case 'resourcing':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = '';
              break;
            case 'staging':
              myDefaultValuesObject['tombstone'][inputObject.family][inputObject.id] = '';
              break;
            default:
          }
          break;
        default:
        // code block
      }
    });
  }

  const resolver = useCallback(yupResolver(validationSchema), [validationSchema]);
  // const resolver = useCallback(() => yupResolver(validationSchema), [validationSchema]);
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const methods = useForm({
    resolver,
    // ...(validationSchema && { resolver: yupResolver(validationSchema) }),
    defaultValues: myDefaultValuesObject
  });
  const { control } = methods;
  const watchFields = useWatch({
    control,
    name: [
      'tombstone.DE005',
      'tombstone.DE009',
      'tombstone.DE014',
      'tombstone.DE015',
      'tombstone.DE016'
    ]
  });

  // START OF ADDING LIST ARRAY TO EACH ELEMENT
  useEffect(() => {
    (async () => {
      try {
        const lookupArray = await execute(
          'GET',
          protectedResources.apiCreateList.getAllLookupsEndPoint
        );
        // console.log('lets print lookup array', lookupArray);
        for (let [key, value] of Object.entries(lookupArray)) {
          // Find the corresponding element in tombstoneDataElementArray
          const tombstoneElementIndex = tombstoneDataElementArray.findIndex(
            (element) => element.id === key
          );
          // If a corresponding element is found, add the list property
          if (tombstoneElementIndex !== -1) {
            // Check if the key is "DE0006" and value is an array
            if (key === 'DE006' && Array.isArray(value)) {
              // Find the object with id === 7 in the array and remove it
              const indexToDelete = value.findIndex((item) => item.id === 7);
              if (indexToDelete !== -1) {
                value.splice(indexToDelete, 1);
              }
            }
            tombstoneDataElementArray[tombstoneElementIndex].list = value;
          }
          // Find the corresponding element in stagingDataElementArray
          const stagingElementIndex = stagingDataElementArray.findIndex(
            (element) => element.id === key
          );
          // If a corresponding element is found, add the list property
          if (stagingElementIndex !== -1) {
            stagingDataElementArray[stagingElementIndex].list = value;
          }
          // START
          const riskElementIndex = riskDataElementArray.findIndex((element) => element.id === key);
          // If a corresponding element is found, add the list property
          if (riskElementIndex !== -1) {
            riskDataElementArray[riskElementIndex].list = value;
          }
          // END
          const resourcingElementIndex = resourcingDataElementArray.findIndex(
            (element) => element.id === key
          );
          // If a corresponding element is found, add the list property
          if (resourcingElementIndex !== -1) {
            resourcingDataElementArray[resourcingElementIndex].list = value;
          }
          const submissionElementIndex = submissionDataElementArray.findIndex(
            (element) => element.id === key
          );
          // If a corresponding element is found, add the list property
          if (submissionElementIndex !== -1) {
            submissionDataElementArray[submissionElementIndex].list = value;
          }
          if (key === 'DE021') {
            // IDs to check in submissionDataElementArray
            const idsToUpdate = ['DE229', 'DE234', 'DE240'];

            // Loop through the IDs and update the corresponding elements
            idsToUpdate.forEach((id) => {
              const index = submissionDataElementArray.findIndex((element) => element.id === id);
              if (index !== -1) {
                submissionDataElementArray[index].list = value;
              }
            });
          }
        }
        setAllTabsData({
          tombstone: {
            isDisplayed: true,
            // data: [...tombstoneDataElementArray]
            data: [...tombstoneDataElementArray]
          },
          resourcing: {
            isDisplayed: true,
            data: [...resourcingDataElementArray]
          },
          staging: {
            isDisplayed: true,
            data: [...stagingDataElementArray]
          },
          risk: {
            isDisplayed: true,
            data: [...riskDataElementArray]
          },
          submissions: {
            isDisplayed: false,
            data: [...submissionDataElementArray]
          }
        });
      } catch (error) {
        console.log(error);
      }
      // console.log('values of searchPArams', searchparams.get('procId'));
      if (searchparams.get('procId')) {
        let procId = searchparams.get('procId');
        updateProcurementData(procId);
      }
    })();
  }, []);
  // END OF ADDING LIST ARRAY TO EACH ELEMENT

  useEffect(() => {}, []);

  useEffect(() => {}, [methods.formState.isDirty]);
  useEffect(() => {
    const regex = /^\d{0,11}(?:\.\d{1,2})?$/;
    // console.log('lets see useEffect is getting executed');

    DataElementArray.map((inputDataObject, index) => {
      let label = inputDataObject.name;
      let name = inputDataObject.id;
      switch (inputDataObject.type.toLowerCase()) {
        case 'string':
          switch (inputDataObject.family) {
            case 'tombstone':
              setValidationSchemaObject((prevState) => {
                let newSchema = yup
                  .string()
                  .max(
                    inputDataObject.validation.max.value,
                    `${label} must be at most ${inputDataObject.validation.max.value} characters.`
                  );
                if (inputDataObject.validation.isRequired) {
                  newSchema = newSchema.required(`${label} is required.`);
                }
                return {
                  ...prevState,
                  [name]: newSchema
                };
              });

              break;
            case 'staging':
              if (inputDataObject.validation !== null) {
                setStagingSchemaObject((prevState) => ({
                  ...prevState,
                  [name]: yup
                    .string()
                    .max(
                      inputDataObject.validation.max.value,
                      `${label} must be at most ${inputDataObject.validation.max.value} characters.`
                    )
                }));
              }
              break;
            case 'resourcing':
              if (inputDataObject.validation !== null) {
                setResourcingSchemaObject((prevState) => ({
                  ...prevState,
                  [name]: yup
                    .string()
                    .max(
                      inputDataObject.validation.max.value,
                      `${label} must be at most ${inputDataObject.validation.max.value} characters.`
                    )
                }));
              }
              break;
            case 'risk':
              if (inputDataObject.validation !== null) {
                setRiskSchemaObject((prevState) => ({
                  ...prevState,
                  [name]: yup
                    .string()
                    .max(
                      inputDataObject.validation.max.value,
                      `${label} must be at most ${inputDataObject.validation.max.value} characters.`
                    )
                }));
              }
              break;
            default:
          }
          break;
        case 'date':
          switch (inputDataObject.family) {
            case 'tombstone':
              setValidationSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .date()
                  .nullable()
                  .test('two-digits-year', 'Year should be at least 4 digits.', function (value) {
                    // console.log('lets see value in the schema', value);
                    if (!value) {
                      return true;
                    }
                    const year = value.getFullYear().toString();
                    return year.length === 4;
                  })
                  // .max(new Date(), 'Date cannot be in the future.')
                  .typeError('Please enter a valid Date.')
              }));
              break;
            case 'staging':
              setStagingSchemaObject((prevState) => {
                const data = methods.getValues();
                // const reqFORM = getRequestNumberToBeRendered(data);
                // console.log('lets print req form', reqFORM);
                let newStagingSchemaObject = {
                  ...prevState,
                  [name]: yup
                    .date()
                    .nullable()
                    .test('two-digits-year', 'Year should be at least 4 digits.', function (value) {
                      if (!value) {
                        return true;
                      }
                      const year = value.getFullYear().toString();
                      return year.length === 4;
                    })
                    // .max(new Date(), 'Date cannot be in the future.')
                    .typeError('Please enter a valid Date.')
                };
                if (
                  inputDataObject.family.toLowerCase() === 'staging' &&
                  inputDataObject?.validation?.sequence === true &&
                  getRequestNumberToBeRendered(data) !== 'REQ_009'
                ) {
                  newStagingSchemaObject[name] = newStagingSchemaObject[name].test(
                    'min-date',
                    `${label} must be greater than or equal to prior dates.`,
                    function (value) {
                      // console.log('lets see value', value);
                      if (!value) {
                        return true;
                      }
                      // let prevDataElementLabel;
                      let prevDataElementName;
                      const targetId = 'DE211';
                      const targetIndex = DataElementArray.findIndex(
                        (element) => element.id === targetId
                      );
                      for (let i = index - 1; i > targetIndex; i--) {
                        // console.log('lets see targetIndex', targetIndex);
                        if (this.parent[DataElementArray[i].id] !== null) {
                          // console.log('lets see parent', this.parent);
                          // prevDataElementLabel = DataElementArray[i].name;
                          prevDataElementName = DataElementArray[i].id;
                          break;
                        }
                      }
                      if (prevDataElementName) {
                        const minDate = this.parent[prevDataElementName];
                        if (value < minDate) {
                          // console.log('lets see value', value);
                          // console.log('lets see minDate', minDate);
                          return false;
                        }
                      }
                      return true;
                    }
                  );
                  // let prevDataElementLabel = DataElementArray[index - 1].name;
                  // let prevDataElementName = DataElementArray[index - 1].id;
                  // newStagingSchemaObject[name] = newStagingSchemaObject[name]
                  // .min(
                  //   yup.ref(`${prevDataElementName}`),
                  //   `${label} must be on or after ${prevDataElementLabel}.`
                  // )
                  // .test(
                  //   'previous-date-exists',
                  //   `${prevDataElementLabel} must be entered on or before ${label}.`,
                  //   function (value) {
                  //     if (!value) {
                  //       return true;
                  //     }

                  //     const previousDateValue = this.parent[prevDataElementName];
                  //     return previousDateValue !== null;
                  //   }
                  // );
                }
                return newStagingSchemaObject;
              });
              break;
            case 'resourcing':
              setResourcingSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .date()
                  .nullable()
                  .test('two-digits-year', 'Year should be at least 4 digits.', function (value) {
                    if (!value) {
                      return true;
                    }
                    const year = value.getFullYear().toString();
                    return year.length === 4;
                  })
                  // .max(new Date(), 'Date cannot be in the future.')
                  .typeError('Please enter a valid Date.')
              }));
              break;
            default:
          }
          break;
        case 'currency':
          switch (inputDataObject.family) {
            case 'tombstone':
              setValidationSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test(
                    'numeric',
                    'Please enter a valid number.',
                    function (value, { createError, path }) {
                      if (value === undefined || value === null || value === '') {
                        return true; // pass validation if value is empty or undefined
                      }
                      if (value) {
                        return handleValidationChecker(regex, value, createError, path, 'currency');
                      }
                    }
                  )
              }));
              break;
            case 'staging':
              setStagingSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test(
                    'numeric',
                    'Please enter a valid number.',
                    function (value, { createError, path }) {
                      if (value === undefined || value === null || value === '') {
                        return true; // pass validation if value is empty or undefined
                      }
                      if (value) {
                        return handleValidationChecker(regex, value, createError, path, 'currency');
                      }
                    }
                  )
              }));

              break;
            case 'resourcing':
              setResourcingSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test(
                    'numeric',
                    'Please enter a valid number.',
                    function (value, { createError, path }) {
                      if (value === undefined || value === null || value === '') {
                        return true; // pass validation if value is empty or undefined
                      }
                      if (value) {
                        return handleValidationChecker(regex, value, createError, path, 'currency');
                      }
                    }
                  )
              }));

              break;
            default:
          }
          break;
        case 'enumerated':
          break;
        default:
      }
    });
  }, [watchFields]);
  //Below useEffect is to handle when form changes using formChangeAlert
  useEffect(() => {
    // console.log('lets see before If condition');
    // check if it is the first time the form is being reset
    if (procData !== null && Object.keys(procData).length > 0) {
      const data = methods.getValues();
      const getCurrentForm = getRequestNumberToBeRendered(data);
      // console.log('lets print currentForm', getCurrentForm);
      // console.log('lets print lookUpForm', lookUpForm);
      const isFormChanged = () => {
        if (lookUpForm) {
          return lookUpForm !== getCurrentForm;
        } else {
          return false;
        }
      };
      // console.log("let's print isFormChanged", isFormChanged());
      // console.log('lets print formReset', formReset);
      // const isLockedFieldModified =
      //   (lookUpValues['DE005'] !== 0 && watchFields[0] !== lookUpValues['DE005']) ||
      //   (lookUpValues['DE009'] !== 0 && watchFields[1] !== lookUpValues['DE009']) ||
      //   (lookUpValues['DE014'] !== 0 && watchFields[2] !== lookUpValues['DE014']) ||
      //   (lookUpValues['DE015'] !== '' && watchFields[3] !== lookUpValues['DE015']) ||
      //   (lookUpValues['DE016'] !== '' && watchFields[4] !== lookUpValues['DE016']);

      if (procData && isFormChanged() && formReset) {
        // it is not the first time, set formChangeAlert to true
        // console.log("let's print watchFields", watchFields);
        // console.log("let's print isFormChanged", isFormChanged());
        // console.log('lets print lookup values', lookUpForm);
        setFormChangeAlert(true);
      }
    }
  }, [watchFields, formReset]);

  useEffect(() => {
    setValidationSchema(() => {
      // console.log('validationSchemaObjecttesting:', validationSchemaObject);
      return yup.object().shape({
        tombstone: yup.object().shape({
          ...validationSchemaObject,
          resourcing: yup.object().shape({ ...resourcingSchemaObject }),
          staging: yup.object().shape({ ...stagingSchemaObject }),
          risk: yup.object().shape({ ...riskSchemaObject })
        })
      });
    });
  }, [stagingSchemaObject, validationSchemaObject, resourcingSchemaObject, riskSchemaObject]);
  //Below method is for updating ProcData when new submission gets added in Render Drawer component
  const updateProcurementData = async (procId) => {
    try {
      const procurementDetails = await execute(
        'GET',
        protectedResources.apiCreateList.getProcurementDetails(procId)
      );
      // console.log('lets print procurement details', procurementDetails);
      setProcData(() => {
        return { tombstone: { ...procurementDetails.tombstone } };
      });
      // setProcData(() => {
      //   return procurementDetails;
      // });
      //BElow is for handling Update Cancel Conditions in CancelClickHandler
      setUpdate(true);
      let deepCopy = JSON.parse(JSON.stringify(procurementDetails));
      deepCopy = { tombstone: { ...deepCopy.tombstone } };
      if (deepCopy && deepCopy.tombstone) {
        delete deepCopy.tombstone.submission;
        // const data = methods.getValues();
        setLookUpForm(() => {
          return getRequestNumberToBeRendered(deepCopy);
        });
        // console.log('lets print deep copy', deepCopy);
        // methods.reset(deepCopy);
        setLookUpValues((prev) => {
          return {
            ...prev,
            DE005: deepCopy['tombstone']['DE005'],
            DE009: deepCopy['tombstone']['DE009'],
            DE014: deepCopy['tombstone']['DE014'],
            DE015: deepCopy['tombstone']['DE015'],
            DE016: deepCopy['tombstone']['DE016']
          };
        });
        setFormReset(true);
        methods.reset({
          tombstone: {
            ...deepCopy.tombstone,
            resourcing: {
              ...deepCopy.tombstone.resourcing
            },
            staging: {
              ...deepCopy.tombstone.staging
            },
            risk: {
              ...deepCopy.tombstone.risk
            }
          }
        });
        // console.log("let's print getVAlues", methods.getValues());
      }
    } catch (error) {
      console.log('lets print error', error);
      navigate('/');
      /* empty */
    }
  };

  const getShowPopupStatus = () => {
    const lookUpFields = [
      'tombstone.DE009',
      'tombstone.DE005',
      'tombstone.DE014',
      'tombstone.DE015',
      'tombstone.DE016'
    ];
    const lookupElementsArray = methods.getValues(lookUpFields);
    const popupDisplay = lookupElementsArray.some((element) => element === 0 || element === '');
    setPopupStatusMessage(popupDisplay);
    if (popupDisplay || procData === null || methods.formState.isDirty) {
      return true;
    }
    return false;
  };
  const changeTabToTombstone = (step) => {
    setActiveStep(step);
  };

  const handleStep = (step) => () => {
    if (step === 4) {
      setPopupStatus(() => getShowPopupStatus());
      if (getShowPopupStatus()) {
        return;
      }
    }
    setActiveStep(step);
    // if (!loadedTabs.includes(step)) {
    //   setLoadedTabs([...loadedTabs, step]);
    // }
  };
  const requestFormHandler = () => {
    const data = methods.getValues();
    const updatedDataElementsData = getFormDataDisplayStatus(data, allTabsData);
    const formNumber = getRequestNumberToBeRendered(data);
    // console.log('testing request form handler');
    // console.log('lets test form number', formNumber);
    setReqFormNumber(formNumber);
    setAllTabsData((prevState) => {
      return {
        ...prevState,
        submissions: updatedDataElementsData['submissions']
      };
    });
    // Has nested if to handle initial condition and when the 5 form fields are updated
    if (reqFormNumber) {
      if (formNumber !== reqFormNumber) {
        return true;
      }
    }
    return false;
  };
  const cancelClickHandler = function () {
    //Below if codition is for CREATE Operation
    if (methods.formState.isDirty && procData === null) {
      setNavBarCancelClickedStatus(true);
      return;
    }
    //Below if codition is for UPDATE Operation
    if (methods.formState.isDirty && update) {
      setNavBarCancelClickedStatus(true);
      return;
    }
    // console.log('let prints form state isDirty after if condition', methods.formState.isDirty);
    navigate('/');
  };

  //Below useEffect is to give access to the cancelClickHAndler in Header Tab
  useEffect(() => {
    cancelClickHandler_ref.current = cancelClickHandler;
  }, [procData, update]);

  //Below method is for deleting Procurement
  const deleteClickHandler = () => {
    // console.log('deleteClickHandler executed');
    setNavBarDeleteClickedStatus(true);
  };
  //Below function is to stop submitting the form when procurement is deleted and also label change for Dialog
  const getDisabledState = () => {
    if (procData && procData.tombstone) {
      return Boolean(procData.tombstone['deleteFlg']);
    }
    return false;
  };
  const deleteHandler = async () => {
    setNavBarDeleteClickedStatus(false);
    // Logic to impliment delete
    if (procData) {
      const {
        tombstone: { DE001 }
      } = procData;
      try {
        // const response = await execute(
        //   'DELETE',
        //   protectedResources.apiCreateList.getDeleteTombstone(DE001)
        // );
        // const response = await executeDeleteOrUndelete(DE001, getDisabledState());
        let response;
        let deleteFlag = getDisabledState();
        if (deleteFlag) {
          response = await execute(
            'DELETE',
            protectedResources.apiCreateList.getUndeleteTombstone(DE001)
          );
        } else {
          response = await execute(
            'DELETE',
            protectedResources.apiCreateList.getDeleteTombstone(DE001)
          );
        }
        if (response.status && response.status !== 200) {
          setSnackBarOpenStatus(true);
          setSeverityLevel('error');
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_DELETE_TOMBSTONE['error'](response.message)
          );
        } else {
          // setSnackBarOpenStatus(false);
          setSnackBarOpenStatus(true);
          setSeverityLevel('success');
          // setSnackbarMessage(response.message);
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_DELETE_TOMBSTONE['success'](response.message)
          );
          // navigate('/');
          updateProcurementData(DE001);
        }
      } catch (error) {
        // console.log("let's see error", error);
        setSnackBarOpenStatus(true);
        setSeverityLevel('error');
        setSnackbarMessage(SEVERITY_WISE_ALERT_MESSAGE_ON_DELETE_TOMBSTONE['error']());
      }
    }
  };
  const stayPageHandler = () => {
    // Prop to set DialogAlert
    setNavBarCancelClickedStatus(false);
    setNavBarDeleteClickedStatus(false);
  };
  const revertLookupElementsHandler = () => {
    setFormChangeAlert(false);
    // setFormReset(false);
    // console.log('lets get values', methods.getValues());
    const myValues = methods.getValues();
    if (myValues && myValues.tombstone) {
      methods.reset({
        tombstone: {
          ...myValues.tombstone,
          DE005: lookUpValues['DE005'],
          DE009: lookUpValues['DE009'],
          DE014: lookUpValues['DE014'],
          DE015: lookUpValues['DE015'],
          DE016: lookUpValues['DE016']
        }
      });
    }
    // console.log('lets get values', methods.getValues());
  };
  const leavePageHandler = () => {
    // Prop to set DialogAlert
    setNavBarCancelClickedStatus(false);
    navigate('/');
  };

  //Below function is for when when the user clicks YES on Dialog
  const submitDataHandler = () => {
    setFormChangeAlert(false);
    if (!getDisabledState()) {
      const myValues = methods.getValues();
      if (myValues && myValues.tombstone) {
        setFormReset(false);
        methods.reset({
          tombstone: {
            ...myValues.tombstone,
            resourcing: {
              // ...myValues.tombstone.resourcing,
              DE022: 0,
              DE023: null,
              DE021: 0,
              DE025: 0
            }
          }
        });
      }
      methods.handleSubmit(onSaveDetails)();
    }
  };
  // const handleSnackBarClose = () => {
  //   setSnackBarOpenStatus(false);
  // };

  const onSaveDetails = async (data) => {
    // console.info('data of form: ', data);
    // let dataCopy = JSON.parse(JSON.stringify(data));
    let dataCopy = formatPrincipalDatesRecursively(data);
    // removeTimeFromDates(dataCopy.tombstone);
    // console.log('lets check dataCopy', dataCopy);
    // console.log('lets see formatted dates', dataCopy);
    if (procData) {
      const {
        tombstone: { DE001 }
      } = procData;
      try {
        const response = await execute(
          'PUT',
          protectedResources.apiCreateList.getUpdateTombstone(DE001),
          dataCopy
        );
        if (response && response.tombstone) {
          const {
            tombstone: { DE001 }
          } = response;
          // setProcData(response);
          setSnackBarOpenStatus(true);
          setSeverityLevel('success');
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_TOMBSTONE['success'](response.message)
          );
          // setFirstReset(true);
          // const currentValues = methods.getValues();
          // console.log('lets see currentVAlues', watchFields);
          // setLookUpValues({
          //   DE005: watchFields[0],
          //   DE009: watchFields[1],
          //   DE014: watchFields[2],
          //   DE015: watchFields[3],
          //   DE016: watchFields[4]
          // });
          updateProcurementData(DE001);
          // const currentValues = methods.getValues();
          // console.log('lets see currentVAlues in update', currentValues);
          // setLookUpValues({
          //   DE005: currentValues['tombstone']['DE005'],
          //   DE009: currentValues['tombstone']['DE009'],
          //   DE014: currentValues['tombstone']['DE014'],
          //   DE015: currentValues['tombstone']['DE015'],
          //   DE016: currentValues['tombstone']['DE016']
          // });
        } else {
          setSnackBarOpenStatus(true);
          setSeverityLevel('error');
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_TOMBSTONE['error'](response.message)
          );
        }
      } catch (error) {
        // console.log('lets see the ereor', error);
        setSnackBarOpenStatus(true);
        setSeverityLevel('error');
        setSnackbarMessage(SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_TOMBSTONE['error']());
      }
    } else {
      try {
        const response = await execute(
          'POST',
          protectedResources.apiCreateList.createTombstoneEndpoint,
          dataCopy
        );
        if (response && response.tombstone) {
          const {
            tombstone: { DE001 }
          } = response;
          // setProcData(response);
          setSnackBarOpenStatus(true);
          setSeverityLevel('success');
          // setSnackbarMessage(response.message);
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_TOMBSTONE['success'](response.message)
          );
          // setFirstReset(false);
          updateProcurementData(DE001);
          // const currentValues = methods.getValues();
          // console.log('lets see currentVAlues', currentValues);
          //Below is for handling FormChange alert
          // setLookUpValues({
          //   DE005: currentValues['tombstone']['DE005'],
          //   DE009: currentValues['tombstone']['DE009'],
          //   DE014: currentValues['tombstone']['DE014'],
          //   DE015: currentValues['tombstone']['DE015'],
          //   DE016: currentValues['tombstone']['DE016']
          // });
        } else {
          setSnackBarOpenStatus(true);
          setSeverityLevel('error');
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_TOMBSTONE['error'](response.message)
          );
          setProcData(null);
        }
      } catch (error) {
        // console.log('lets see the ereor', error);
        setSnackBarOpenStatus(true);
        setSeverityLevel('error');
        setSnackbarMessage(SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_TOMBSTONE['error']());
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 60px)',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
            width: 0,
            height: 0
          }
        }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSaveDetails)}>
            <FixedNavBar
              cancelClickHandler={cancelClickHandler}
              deleteClickHandler={deleteClickHandler}
              procData={procData}
            />
            <Box sx={{ mt: '0.5rem', pl: 2, pr: 2, height: '80px' }}>
              <NonLinearStepper activeStep={activeStep} handleStep={handleStep} />
            </Box>
            {/*<h3 style={{ padding: '10px' }}>{watchFields}</h3>*/}
            {/*<h3 style={{ padding: '10px' }}>{JSON.stringify(lookUpValues)}</h3>*/}

            {/*<TabPanel value={activeStep} index={0}>*/}
            {/*  {loadedTabs.includes(0) && <Tombstone data={allTabsData.tombstone} />}*/}
            {/*</TabPanel>*/}
            {/*<TabPanel value={activeStep} index={1}>*/}
            {/*  {loadedTabs.includes(1) && <Staging data={allTabsData.staging} />}*/}
            {/*</TabPanel>*/}
            {/*<TabPanel value={activeStep} index={2}>*/}
            {/*  {loadedTabs.includes(2) && <Resourcing data={allTabsData.resourcing} />}*/}
            {/*</TabPanel>*/}
            <Box>
              {activeStep === 0 && <Tombstone data={allTabsData.tombstone} />}
              {activeStep === 1 && <Staging data={allTabsData.staging} />}
              {activeStep === 2 && <Resourcing data={allTabsData.resourcing} />}
              {activeStep === 3 && <Risk data={allTabsData.risk} />}
            </Box>

            {formChangeAlert && (
              <FormChangeAlertComponent
                descriptionHeader={FORM_CHANGE_ALERT.descriptionHeader}
                descriptionBody={FORM_CHANGE_ALERT.descriptionBody}
                popupStatus={formChangeAlert}
                button1Handler={submitDataHandler}
                button1Label={FORM_CHANGE_ALERT.button1Label}
                button2Label={FORM_CHANGE_ALERT.button2Label}
                button2Handler={revertLookupElementsHandler}
              />
            )}
          </form>
        </FormProvider>

        {/*<TabPanel value={activeStep} index={3}>*/}
        {/*  {loadedTabs.includes(3) && (*/}
        {/*    <Submission*/}
        {/*      data={allTabsData.submissions}*/}
        {/*      activeStep={activeStep}*/}
        {/*      getShowPopupStatus={getShowPopupStatus}*/}
        {/*      changeTabToTombstone={changeTabToTombstone}*/}
        {/*      requestFormHandler={requestFormHandler}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</TabPanel>*/}
        {activeStep === 4 && (
          <Submission
            data={allTabsData.submissions}
            activeStep={activeStep}
            getShowPopupStatus={getShowPopupStatus}
            changeTabToTombstone={changeTabToTombstone}
            requestFormHandler={requestFormHandler}
            procData={procData}
            updateProcurementData={updateProcurementData}
            reqFormNumber={reqFormNumber}
          />
        )}
        {popupStatus && (
          <SubmissionAlertComponent
            popupStatus={popupStatus}
            setPopupStatus={setPopupStatus}
            popupStatusMessage={popupStatusMessage}
            activeStep={activeStep}
            changeTabToTombstone={changeTabToTombstone}
          />
        )}
        {navBarCancelClickedStatus && (
          <DialogAlertComponent
            descriptionHeader={NAV_BAR_DIALOG_ALERT.descriptionHeader}
            descriptionBody={NAV_BAR_DIALOG_ALERT.descriptionBody}
            popupStatus={navBarCancelClickedStatus}
            button1Handler={stayPageHandler}
            button1Label={NAV_BAR_DIALOG_ALERT.button1Label}
            button2Label={NAV_BAR_DIALOG_ALERT.button2Label}
            button2Handler={leavePageHandler}
          />
        )}
        {navBarDeleteClickedStatus && (
          <DialogAlertComponent
            descriptionHeader={
              getDisabledState()
                ? NAV_BAR_DIALOG_UNDELETE_ALERT.descriptionHeader
                : NAV_BAR_DIALOG_DELETE_ALERT.descriptionHeader
            }
            descriptionBody={
              getDisabledState()
                ? NAV_BAR_DIALOG_UNDELETE_ALERT.descriptionBody
                : NAV_BAR_DIALOG_DELETE_ALERT.descriptionBody
            }
            popupStatus={navBarDeleteClickedStatus}
            button1Handler={deleteHandler}
            button1Label={
              getDisabledState()
                ? NAV_BAR_DIALOG_UNDELETE_ALERT.button1Label
                : NAV_BAR_DIALOG_DELETE_ALERT.button1Label
            }
            button2Label={NAV_BAR_DIALOG_DELETE_ALERT.button2Label}
            button2Handler={stayPageHandler}
          />
        )}

        {/*{snackBarOpenStatus && (*/}
        {/*  <SnackBarAlertComponent*/}
        {/*    alertMessage={SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_TOMBSTONE[severityLevel](*/}
        {/*      snackbarMessage*/}
        {/*    )}*/}
        {/*    snackBarOpenStatus={snackBarOpenStatus}*/}
        {/*    severityLevel={severityLevel}*/}
        {/*    handleSnackBarClose={handleSnackBarClose}*/}
        {/*  />*/}
        {/*)}*/}
      </Box>
    </>
  );
}

export default DataFamilies;
// DataFamilies.propTypes = {
//   cancelClickHandler_ref: PropTypes.shape({ current: PropTypes.any })
// };
