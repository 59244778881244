import { alpha } from '@mui/system';
import styled from '@emotion/styled';
import InputBase from '@mui/material/InputBase';

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '30px',
  backgroundColor: alpha(theme.palette.common.black, 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.15)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));
const iconWrapperStyles = ({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: alpha(theme.palette.common.black, 0.9)
});

// export const SearchIconWrapper = styled('div')(iconWrapperStyles);
// export const SearchSendIconWrapper = styled('div')({
//     ...iconWrapperStyles,
//     right: 0,
//     top: 0,
// });
export const SearchIconWrapper = styled('div')(({ theme }) => ({
  ...iconWrapperStyles({ theme }),
  pointerEvents: 'none'
}));

export const SearchSendIconWrapper = styled('div')(({ theme }) => ({
  ...iconWrapperStyles({ theme }),
  right: 0,
  top: 0,
  cursor: 'pointer'
}));

//  export const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     color: alpha(theme.palette.common.black, 0.9),
// }));
// export const SearchSendIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     color: alpha(theme.palette.common.black, 0.9),
//     right:0,
//     top:0,
// }));
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.7),
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%'
      }
    }
  }
}));
// export const SearchButton = styled(Button)(({ theme }) => ({
//     background: '#2b1b44',
//     borderRadius: '31px',
//     position: 'fixed',
//     right: '10%',
//     bottom: '4%',
//     textTransform: 'none'
// }));
// export const StyledSelect = styled(Select)(({ theme }) => ({
//     color: 'black',
//     fontSize: '14px',
//     maxWidth: '250px',
//     minWidth: '250px',
//     textAlign: 'left',
//     backgroundColor: '#0000000f'
// }));
// export const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//     boxShadow: 'none',
//     border:'1px solid pink'
// }));
// export const StyledInput = styled(Input)(({ theme }) => ({
//     color: 'black',
//     background: '#d1caca6e',
//     padding: '4px'
// }));
