import { Controller, useFormContext } from 'react-hook-form';
import { registerFormElement } from '../../utils/helper';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip
} from '@mui/material';
import * as React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';

export const BinaryElementComponent = ({ inputDataObject, isDisplayed }) => {
  const { control } = useFormContext();
  // console.log(control);

  // console.log("inputDataObject", inputDataObject)
  if (!isDisplayed) {
    return null;
  }

  return (
    <>
      <Controller
        // name={getUniqueFormElementName(inputDataObject)}
        name={registerFormElement(inputDataObject)}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl sx={{ m: 0.5, display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
                // border: '1px solid red'
              }}>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                sx={(theme) => ({
                  marginRight: 'auto',
                  color: theme.palette.primary.main,
                  // border: '1px solid red',
                  p: '9px',
                  mr: '10px'
                })}>
                {inputDataObject.name}
                {inputDataObject.hasDescription && (
                  <Tooltip title={inputDataObject.description}>
                    <HelpOutlineIcon fontSize="small" sx={{ ml: 1, verticalAlign: 'middle' }} />
                  </Tooltip>
                )}
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                // onClick={(e) => {
                //   console.log('value', value);
                //   console.log('e.target.value', e.target.value, typeof e.target.value);
                //   console.log("let's see target name", e.target);
                //   onChange(e.target.value == value ? '' : e.target.value);
                // }}
                sx={{ flexDirection: 'row' }}>
                <FormControlLabel
                  // value="Y"
                  value={inputDataObject.id === 'DE188' ? 'Pass' : 'Y'}
                  sx={{ pointerEvents: 'none' }}
                  control={
                    <Radio
                      onClick={(e) => {
                        onChange(e.target.value == value ? '' : e.target.value);
                      }}
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                        pointerEvents: 'auto'
                      })}
                    />
                  }
                  label={inputDataObject.id === 'DE188' ? 'Pass' : 'Yes'}
                  // label={<span>{inputDataObject.id === 'DE188' ? 'Pass' : 'Yes'}</span>}
                />
                <FormControlLabel
                  // value="N"
                  value={inputDataObject.id === 'DE188' ? 'Fail' : 'N'}
                  sx={{ pointerEvents: 'none' }}
                  control={
                    <Radio
                      onClick={(e) => {
                        onChange(e.target.value == value ? '' : e.target.value);
                      }}
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                        pointerEvents: 'auto'
                      })}
                    />
                  }
                  label={inputDataObject.id === 'DE188' ? 'Fail' : 'No'}
                  // label={<span>{inputDataObject.id === 'DE188' ? 'Fail' : 'No'}</span>}
                />
              </RadioGroup>
            </Box>
          </FormControl>
        )}
      />
    </>
  );
};
BinaryElementComponent.propTypes = {
  inputDataObject: PropTypes.object,
  isDisplayed: PropTypes.bool
};
