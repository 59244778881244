import { Box } from '@mui/material';
import DataFamilies from 'features/procurementAttributes/datafamilies';
// import PropTypes from 'prop-types';

function Common() {
  return (
    <>
      <Box>
        {' '}
        <DataFamilies />
      </Box>
    </>
  );
}

export default Common;
// Common.propTypes = {
//   cancelClickHandler_ref: PropTypes.shape({ current: PropTypes.any })
// };
