import { Box, Button, Drawer, Stack, Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
// import TabPanel from '../../features/procurementAttributes/tabpanel';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DrawerTabSubmission from '../DrawerTabSubmission';
import DrawerTabEvaluation from '../DrawerTabEvaluation';
import DrawerTabContract from '../DrawerTabContract';
import PropTypes from 'prop-types';
import './customStyles.css';
import {
  StyledBox,
  StyledTabsBox,
  StyledStickyBox,
  StyledTab,
  StyledTabs,
  StyledButton
} from './styled';
import SubmissionList from '../SubmissionList';
import DialogAlertComponent from '../DialogAlertComponent';
import {
  // DIALOG_SUBMISSION_CONTRACT_ALERT,
  // DIALOG_SUBMISSION_EVALUATION_ALERT,
  NAV_BAR_DIALOG_ALERT,
  NAV_BAR_DIALOG_SUBMISSION_DELETE_ALERT,
  NAV_BAR_DIALOG_SUBMISSION_UNDELETE_ALERT,
  SEVERITY_WISE_ALERT_MESSAGE_ON_DELETE_SUBMISSION,
  SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_SUBMISSION,
  SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_SUBMISSION
} from '../../constants';
import { protectedResources } from '../../features/auth/config';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
// import { ErrorMessage } from '@hookform/error-message';

import {
  formatSubmissionDatesRecursively,
  handleFloatValidationChecker,
  handleIntValidationChecker,
  handleIntValidationCheckerContractAmendment,
  handleValidationChecker,
  handleValidationCheckerContractAmendment,
  resetForm
} from '../../utils/helper';
import { ThemeContext } from '../../ThemeContext';
// import { contractAmendmentArray } from 'constants/contractAmendment';
// import { ErrorMessageComponent } from '../ErrorMessageComponent';
// import EditableGrid from '../EditableGrid';

const mySubmissionDefaultValues = {
  submission: {
    teamMembers: [],
    evaluation: {},
    contract: { contractAmendment: [] }
  }
};
// const contractAmendmentObject = {
//   isDisplayed: false,
//   data: contractAmendmentArray
// };
function RenderDrawerComponent({
  data,
  requestFormHandler,
  procData,
  updateProcurementData,
  reqFormNumber
}) {
  // console.log('rendermodaldatacomponent', data);
  const submisssionElementArray = data.data;
  // const [open, setOpen] = React.useState(false);
  const [drawerState, setDrawerState] = React.useState(false);
  const [submissionSchemaObject, setSubmissionSchemaObject] = React.useState({});
  const [evaluationSchemaObject, setEvaluationSchemaObject] = React.useState({});
  const [contractSchemaObject, setContractSchemaObject] = React.useState({});
  const [contractAmendmentSchemaObject, setContractAmendmentSchemaObject] = React.useState({});
  const [submissionSchema, setSubmissionSchema] = React.useState({});
  const [navBarCancelClickedStatus, setNavBarCancelClickedStatus] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  // const [snackBarOpenStatus, setSnackBarOpenStatus] = useState(false);
  // const [severityLevel, setSeverityLevel] = useState('success');
  const [submissionData, setSubmissionData] = useState(null);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
  const [vendorName, setVendorName] = useState(null);
  const { setCommonVendorOptions, setTeamMemberOptions } = useContext(ThemeContext);
  const { setSnackbarMessage, setSnackBarOpenStatus, setSeverityLevel } = useContext(ThemeContext);
  const [navBarDeleteClickedStatus, setNavBarDeleteClickedStatus] = useState(false);
  const [deleteSubmissionList, setDeleteSubmissionList] = useState([]);
  const [myDeleteFlag, setMyDeleteFlag] = useState(false);
  const [savedContractAmendments, setSavedContractAmendments] = useState(null);
  // const [resetClickedStatus, setResetClickedStatus] = useState(false);
  // const [evaluationContractResetDialogHeader, setEvaluationContractResetDialogHeader] =
  //   useState(false);
  // const [formErrorMessage, setFormErrorMessage] = useState(true);
  const [update, setUpdate] = useState(false);
  const { execute } = useFetchWithMsal();
  const endOfPageRef = useRef(null);

  // const { setValue } = useFormContext();
  // const { control } = useFormContext();

  useEffect(() => {
    // const regex = /^\$?\d{1,8}(,\d{3})*(\.\d{2})?$|^\d{1,8}(,\d{3})*(\.\d{2})?\$$/;
    // const regex = /^\$?((\d){1,11})(\.\d{2})?$|^\d{1,11}(,\d{3})*(\.\d{2})?\$$/;
    // const regex = /^\$?((\d){1,11})(\.\d{1,2})?$|^((\d){1,11})(\.\d{1,2})?\$$/;
    // const regex = /^\$?((\d){0,11})(\.\d{1,2})?$/;
    const regex = /^\d{0,11}(?:\.\d{1,2})?$/;
    const regexFloat = /^-?\d{0,4}(?:\.\d{1,2})?$/;
    let regexInt = /^\d{0,5}$/;
    submisssionElementArray.map((inputDataObject, index) => {
      // let label = inputDataObject.name;
      // let name = getUniqueFormElementName(inputDataObject);
      let label = inputDataObject.name;
      let name = inputDataObject.id;

      switch (inputDataObject.type.toLowerCase()) {
        case 'string':
          switch (inputDataObject.family) {
            case 'submission':
              setSubmissionSchemaObject((prevState) => {
                let newSchema = yup
                  .string()
                  .max(
                    inputDataObject.validation.max.value,
                    `${label} must be at most ${inputDataObject.validation.max.value} characters.`
                  );
                if (inputDataObject.validation.isRequired) {
                  newSchema = newSchema.required(`${label} is required.`);
                }
                return {
                  ...prevState,
                  [name]: newSchema
                };
              });

              break;
            case 'evaluation':
              if (inputDataObject.validation !== null) {
                setEvaluationSchemaObject((prevState) => ({
                  ...prevState,
                  [name]: yup
                    .string()
                    .max(
                      inputDataObject.validation.max.value,
                      `${label} must be at most ${inputDataObject.validation.max.value} characters.`
                    )
                }));
              }
              break;
            case 'contract':
              if (inputDataObject.validation !== null) {
                setContractSchemaObject((prevState) => ({
                  ...prevState,
                  [name]: yup
                    .string()
                    .max(
                      inputDataObject.validation.max.value,
                      `${label} must be at most ${inputDataObject.validation.max.value} characters.`
                    )
                }));
              }
              break;
            case 'contractAmendment':
              if (inputDataObject.validation !== null) {
                setContractAmendmentSchemaObject((prevState) => ({
                  ...prevState,
                  [name]: yup
                    .string()
                    .max(
                      inputDataObject.validation.max.value,
                      `${label} must be at most ${inputDataObject.validation.max.value} characters.`
                    )
                }));
              }
              break;
            default:
          }
          break;
        case 'date':
          switch (inputDataObject.family) {
            case 'submission':
              setSubmissionSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .date()
                  .nullable()
                  .test('two-digits-year', 'Year should be at least 4 digits.', function (value) {
                    if (!value) {
                      return true;
                    }
                    const year = value.getFullYear().toString();
                    return year.length === 4;
                  })
                  // .max(new Date(), 'Date cannot be in the future.')
                  .typeError('Please enter a valid Date.')
              }));
              break;
            case 'evaluation':
              setEvaluationSchemaObject((prevState) => {
                let newStagingSchemaObject = {
                  ...prevState,
                  [name]: yup
                    .date()
                    .nullable()
                    .test('two-digits-year', 'Year should be at least 4 digits.', function (value) {
                      if (!value) {
                        return true;
                      }
                      const year = value.getFullYear().toString();
                      return year.length === 4;
                    })
                    // .max(new Date(), 'Date cannot be in the future.')
                    .typeError('Please enter a valid Date.')
                };
                return newStagingSchemaObject;
              });
              break;
            case 'contract':
              setContractSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .date()
                  .nullable()
                  .test('two-digits-year', 'Year should be at least 4 digits.', function (value) {
                    if (!value) {
                      return true;
                    }
                    const year = value.getFullYear().toString();
                    return year.length === 4;
                  })
                  // .max(new Date(), 'Date cannot be in the future.')
                  .typeError('Please enter a valid Date.')
              }));
              break;
            case 'contractAmendment':
              setContractAmendmentSchemaObject((prevState) => {
                let newContractAmendmentSchemaObject = {
                  ...prevState,
                  [name]: yup
                    .date()
                    .nullable()
                    .test('two-digits-year', 'Year should be at least 4 digits.', function (value) {
                      if (!value) {
                        return true;
                      }
                      const year = value.getFullYear().toString();
                      return year.length === 4;
                    })
                    .typeError('Please enter a valid Date.')
                  // .max(new Date(), 'Date cannot be in the future.')
                };
                if (name !== 'DE237') {
                  newContractAmendmentSchemaObject[name] = newContractAmendmentSchemaObject[
                    name
                  ].max(new Date(), 'Date cannot be in the future.');
                }
                if (
                  inputDataObject.family.toLowerCase() === 'contractamendment' &&
                  inputDataObject?.validation?.sequence === true
                ) {
                  let prevDataElementLabel = submisssionElementArray[index - 1].name;
                  let prevDataElementName = submisssionElementArray[index - 1].id;
                  if (name === 'DE237') {
                    prevDataElementLabel = 'Contract Amendment Documents Procurement Approval Date';
                    prevDataElementName = 'DE232';
                  } else if (name === 'DE238') {
                    prevDataElementLabel = 'Contract Amendment Documents Received Date';
                    prevDataElementName = 'DE230';
                  } else if (name === 'DE241') {
                    prevDataElementLabel = 'Amendment Lead Assignment Date';
                    prevDataElementName = 'DE238';
                  } else if (name === 'DE233') {
                    prevDataElementLabel = 'Amendment Lead Start Date';
                    prevDataElementName = 'DE241';
                  } else if (name === 'DE242') {
                    prevDataElementLabel = 'Contract Amendment Execution Date';
                    prevDataElementName = 'DE233';
                  }
                  newContractAmendmentSchemaObject[name] = newContractAmendmentSchemaObject[name]
                    .min(
                      yup.ref(`${prevDataElementName}`),
                      `${label} must be on or after ${prevDataElementLabel}.`
                    )
                    .test(
                      'previous-date-exists',
                      `${prevDataElementLabel} must be entered.`,
                      function (value) {
                        // console.log('lets see value', value);
                        if (!value) {
                          return true;
                        }
                        const previousDateValue = this.parent[prevDataElementName];
                        return previousDateValue !== null;
                      }
                    );
                }
                return newContractAmendmentSchemaObject;
              });
              break;
            default:
          }
          break;
        case 'currency':
          switch (inputDataObject.family) {
            case 'submission':
              setSubmissionSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test(
                    'numeric',
                    'Please enter a valid number.',
                    function (value, { createError, path }) {
                      // console.log(`lets print Currency Value ${name}`, value, typeof value);
                      if (value === undefined || value === null || value === '') {
                        return true; // pass validation if value is empty or undefined
                      }
                      if (value) {
                        return handleValidationChecker(regex, value, createError, path, 'currency');
                      }
                      // return true; // pass validation if all checks pass
                    }
                  )
              }));
              break;
            case 'evaluation':
              setEvaluationSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test(
                    'numeric',
                    'Please enter a valid number.',
                    function (value, { createError, path }) {
                      if (value === undefined || value === null || value === '') {
                        return true; // pass validation if value is empty or undefined
                      }
                      if (value) {
                        return handleValidationChecker(regex, value, createError, path, 'currency');
                      }
                      // return true; // pass validation if all checks pass
                    }
                  )
              }));
              break;
            case 'contract':
              setContractSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test(
                    'numeric',
                    'Please enter a valid number.',
                    function (value, { createError, path }) {
                      if (value === undefined || value === null || value === '') {
                        return true; // pass validation if value is empty or undefined
                      }
                      if (value) {
                        return handleValidationChecker(regex, value, createError, path, 'currency');
                      }
                    }
                  )
              }));
              break;
            case 'contractAmendment':
              setContractAmendmentSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test(
                    'numeric',
                    'Please enter a valid number.',
                    function (value, { createError, path }) {
                      if (value === undefined || value === null || value === '') {
                        return true; // pass validation if value is empty or undefined
                      }
                      if (value) {
                        return handleValidationCheckerContractAmendment(
                          /^-?\d{0,11}(?:\.\d{1,2})?$/,
                          value,
                          createError,
                          path,
                          'currency'
                        );
                      }
                    }
                  )
              }));
              break;
            default:
          }
          break;
        case 'float':
          switch (inputDataObject.family) {
            case 'submission':
              setSubmissionSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test('is-valid-float', function (value, { createError, path }) {
                    if (value === '' || value === null) return true;
                    if (value) {
                      // const regex = /^\d{0,11}(?:\.\d{1,2})?$/;
                      return handleFloatValidationChecker(
                        regexFloat,
                        value,
                        createError,
                        path,
                        'float'
                      );
                    }
                    // return true;
                  })
              }));
              break;
            case 'evaluation':
              setEvaluationSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test('is-valid-float', function (value, { createError, path }) {
                    // console.log('lets print Value of float', value);
                    if (value === '' || value === null) return true;
                    if (value) {
                      return handleFloatValidationChecker(
                        regexFloat,
                        value,
                        createError,
                        path,
                        'float'
                      );
                    }
                    // return true;
                  })
              }));
              break;
            case 'contract':
              setContractSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test('is-valid-float', function (value, { createError, path }) {
                    if (value === '' || value === null) return true;
                    if (value) {
                      return handleFloatValidationChecker(
                        regexFloat,
                        value,
                        createError,
                        path,
                        'float'
                      );
                    }
                  })
              }));

              break;
            default:
          }
          break;
        case 'integer':
          switch (inputDataObject.family) {
            case 'submission':
              setSubmissionSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test('is-valid-float', function (value, { createError, path }) {
                    // console.log('LETS TEST VALUE IN SUBMISSION OF INTEGER SCHEMA', value);
                    if (value === '' || value === null) return true;
                    if (value) {
                      return handleIntValidationChecker(
                        regexInt,
                        value,
                        createError,
                        path,
                        'integer'
                      );
                    }
                  })
              }));
              break;
            case 'evaluation':
              setEvaluationSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test('is-valid-float', function (value, { createError, path }) {
                    // console.log('LETS TEST VALUE IN EVALUATION OF INTEGER SCHEMA', value);
                    if (value === '' || value === null) return true;
                    if (value) {
                      return handleIntValidationChecker(
                        regexInt,
                        value,
                        createError,
                        path,
                        'integer'
                      );
                    }
                    return true;
                  })
              }));

              break;
            case 'contract':
              setContractSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test('is-valid-float', function (value, { createError, path }) {
                    // console.log('LETS TEST VALUE IN CONTRACT OF INTEGER SCHEMA', value);
                    if (value === '' || value === null) return true;
                    if (value) {
                      return handleIntValidationChecker(
                        regexInt,
                        value,
                        createError,
                        path,
                        'integer'
                      );
                    }
                    return true;
                  })
              }));
              break;
            case 'contractAmendment':
              setContractAmendmentSchemaObject((prevState) => ({
                ...prevState,
                [name]: yup
                  .string()
                  .nullable()
                  .test('is-valid-float', function (value, { createError, path }) {
                    // console.log('LETS TEST VALUE IN CONTRACT OF INTEGER SCHEMA', value);
                    if (value === '' || value === null) return true;
                    let message;
                    let isNegativeAllowed = true;
                    if (name === 'DE222') {
                      regexInt = /^\d{0,3}$/;
                      message = 'Must have at most 3 digits.';
                      isNegativeAllowed = false;
                    } else if (name === 'DE227') {
                      // regexInt = /^\d{0,4}$/;
                      regexInt = /^-?\d{0,4}$/;
                      message = 'Must have at most 4 digits.';
                      isNegativeAllowed = true;
                    }
                    if (value) {
                      // console.log('lets see regex', regexInt, name);
                      return handleIntValidationCheckerContractAmendment(
                        regexInt,
                        value,
                        createError,
                        path,
                        'integer',
                        message,
                        isNegativeAllowed
                      );
                    }
                    return true;
                  })
              }));
              if (name === 'DE222') {
                setContractAmendmentSchemaObject((prevState) => ({
                  ...prevState,
                  [name]: prevState[name].required('Contract Amendment Number is required.')
                }));
              }
              break;
            default:
          }
          break;
        case 'enumerated':
          break;
        default:
      }
    });
    // setSubmissionSchemaObject(yup.object().shape(submissionSchemaObject));
  }, []);
  if (Object.keys(mySubmissionDefaultValues.submission).length === 3) {
    submisssionElementArray.map((inputObject) => {
      switch (inputObject.type.toLowerCase()) {
        case 'string':
          switch (inputObject.family) {
            case 'submission':
              mySubmissionDefaultValues[inputObject.family][inputObject.id] = '';
              break;
            case 'evaluation':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = '';
              break;
            case 'contract':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = '';
              break;
            default:
          }

          break;
        case 'date':
          switch (inputObject.family) {
            case 'submission':
              mySubmissionDefaultValues[inputObject.family][inputObject.id] = null;
              break;
            case 'evaluation':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = null;
              break;
            case 'contract':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = null;
              break;
            default:
          }

          break;
        case 'currency':
          switch (inputObject.family) {
            case 'submission':
              mySubmissionDefaultValues[inputObject.family][inputObject.id] = null;
              break;
            case 'evaluation':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = null;
              break;
            case 'contract':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = null;
              break;
            default:
          }
          break;
        case 'enumerated':
          switch (inputObject.family) {
            case 'submission':
              mySubmissionDefaultValues[inputObject.family][inputObject.id] = 0;
              break;
            case 'evaluation':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = 0;
              break;
            case 'contract':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = 0;
              break;
            default:
          }
          break;
        case 'binary':
          switch (inputObject.family) {
            case 'submission':
              mySubmissionDefaultValues[inputObject.family][inputObject.id] = '';
              break;
            case 'evaluation':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = '';
              break;
            case 'contract':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = '';
              break;
            default:
          }
          break;
        case 'float':
          switch (inputObject.family) {
            case 'submission':
              mySubmissionDefaultValues[inputObject.family][inputObject.id] = null;
              break;
            case 'evaluation':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = null;
              break;
            case 'contract':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = null;
              break;
            default:
          }
          break;
        case 'integer':
          switch (inputObject.family) {
            case 'submission':
              mySubmissionDefaultValues[inputObject.family][inputObject.id] = null;
              break;
            case 'evaluation':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = null;
              break;
            case 'contract':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = null;
              break;
            default:
          }
          break;
        case 'multienumerated':
          switch (inputObject.family) {
            case 'submission':
              mySubmissionDefaultValues[inputObject.family][inputObject.id] = [];
              break;
            case 'evaluation':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = [];
              break;
            case 'contract':
              mySubmissionDefaultValues['submission'][inputObject.family][inputObject.id] = [];
              break;
            default:
          }
          break;
        default:
        // code block
      }
    });
  }
  useEffect(() => {
    setSubmissionSchema(() => {
      // console.log('validationSchemaObjecttesting:', validationSchemaObject);
      return yup.object().shape({
        submission: yup.object().shape({
          ...submissionSchemaObject,
          evaluation: yup.object().shape({ ...evaluationSchemaObject }),
          contract: yup.object().shape({
            ...contractSchemaObject,
            contractAmendment: yup.array().of(
              yup.object().shape({
                ...contractAmendmentSchemaObject
              })
            )
          }),
          teamMembers: yup.array().of(
            yup.object().shape({
              DE060: yup
                .string()
                .required('Team Member-Name is required.')
                .max(100, 'Cannot exceed 100 characters.')
            })
          )
        })
      });
    });
  }, [
    submissionSchemaObject,
    evaluationSchemaObject,
    contractSchemaObject,
    contractAmendmentSchemaObject
  ]);
  const resolver = useCallback(yupResolver(submissionSchema), [submissionSchema]);
  // const resolver = useCallback(() => yupResolver(submissionSchema), [submissionSchema]);

  const methods = useForm({
    resolver,
    // ...(Object.keys(submissionSchemaObject).length && {
    //   resolver: yupResolver(submissionSchemaObject)
    // }),
    defaultValues: mySubmissionDefaultValues,
    mode: 'onChange'
  });
  // const { replace } = useFieldArray({
  //   control: methods.control,
  //   name: 'submission.teamMembers'
  // });

  const [rows, setRows] = useState(() => {
    const currentTeamList = methods.getValues('submission.teamMembers');
    // console.log('lets see currentTeamList', currentTeamList);
    return currentTeamList.map((team, index) => {
      return {
        id: index + 1,
        DE060: team.DE060,
        DE061: team.DE061,
        DE062: team.DE062,
        selected: false
      };
    });
  });

  useEffect(() => {
    // console.log('lets also test isDirty in useEffect', methods.formState.isDirty);
  }, [methods.formState.isDirty]);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // console.info('Tombstone data:', data);
  const updateSubmissionId = async (subId) => {
    setSelectedSubmissionId(subId);
    // console.log('i am in submission id');
    // console.log('lets see procData', procData, subId);
    if (procData.tombstone.submission.length > 0) {
      const elementIndex = procData.tombstone.submission.findIndex((el) => {
        // console.log("lets print both subid's", typeof el.subId, typeof subId, el.subId, subId);
        return el.subId == subId;
      });
      // console.log('lets print element Index', elementIndex);
      if (elementIndex !== -1) {
        const currentSubmissionData = procData.tombstone.submission[elementIndex];
        //Below is to show formErrorMessage after get
        // setFormErrorMessage(true);

        let deepCopy = JSON.parse(JSON.stringify(currentSubmissionData));
        deepCopy = { submission: { ...deepCopy } };
        //START OF TEST
        // const contractAmendment = [
        //   {
        //     ...CONTRACT_AMENDMENT_ROW,
        //     DE222: 123,
        //     DE223: 'abc',
        //     id: '400e271b-17e7-4747-a3b2-2dec6e637f4f'
        //   },
        //   {
        //     ...CONTRACT_AMENDMENT_ROW,
        //     DE222: 456,
        //     DE223: 'def',
        //     id: '400e271b-17e7-4747-a3b2-2fgr6e637f4f'
        //   }
        // ];
        // deepCopy.submission.contract.contractAmendment = contractAmendment;
        // END OF TEST
        // console.log('lets see deep copy', deepCopy);
        try {
          if (deepCopy && deepCopy.submission && deepCopy.submission['DE052'].length > 0) {
            const EnumeratedId = deepCopy.submission['DE052'].join(',');
            const vendorOptions = await execute(
              'GET',
              protectedResources.apiCreateList.getCommonVendorLookupById(EnumeratedId)
            );
            setCommonVendorOptions(vendorOptions);
          } else {
            setCommonVendorOptions([]);
          }
          // Below new Set is required as we have multiple rows in editableGrid
          if (deepCopy && deepCopy.submission && deepCopy.submission['teamMembers'].length > 0) {
            // console.log('lets see whether if condition is successful');
            const teamMemberId = deepCopy.submission.teamMembers.reduce((acc, curr) => {
              // const values = Object.values(curr)[1];
              const values = curr['DE061'];
              return acc.concat(values);
            }, []);
            const uniqueTeamMemberId = [...new Set(teamMemberId)].join(',');
            // console.log("lets see unique team id's", uniqueTeamMemberId);
            const teamMemberOptions = await execute(
              'GET',
              protectedResources.apiCreateList.getCommonVendorLookupById(uniqueTeamMemberId)
            );
            setTeamMemberOptions(teamMemberOptions);
          } else {
            setTeamMemberOptions([]);
          }

          // const teamMemberOptions = await execute(
          //   'GET',
          //   protectedResources.apiCreateList.getCommonVendorLookupById(procId)
          // );
        } catch (error) {
          console.log('lets print error', error);
        }

        setVendorName(deepCopy['submission']['DE051']);
        setSavedContractAmendments(deepCopy['submission']['contract']);
        // deepCopy['submission']['DE063'] = String(deepCopy['submission']['DE063']);
        methods.reset(deepCopy);
        // setMyDE189Value(deepCopy.submission.contract.DE189);
        // String(deepCopy['submission']['DE063']);
        // setSubmissionData(deepCopy);
        // console.log('print form STATE in uPdateSUbmissionID ', methods.formState.isDirty);
      }

      // console.log('lets print procData', procData);
    }
  };

  const toggleDrawer = (anchor, open) => (event, reason) => {
    // console.log('lets test drawer reason', reason);
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (reason === 'backdropClick') {
      return;
    }

    setDrawerState(open);
    setTabValue(0);
    // setFormErrorMessage(true);
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Below gets executed when we click on add button of submission
  const resetFormHandler = () => {
    if (requestFormHandler()) {
      // setMyDE189Value('');
      methods.reset();
    }
  };
  const stayPageHandler = () => {
    setNavBarCancelClickedStatus(false);
    setNavBarDeleteClickedStatus(false);
    // setResetClickedStatus(false);
    setDeleteSubmissionList([]);
  };
  //Below is useful to scroll to bottom of page when user adds contract amendment
  const scrollToBottomHandler = () => {
    setTimeout(() => {
      endOfPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
    }, 100);
  };
  const leavePageHandler = (e) => {
    setNavBarCancelClickedStatus(false);
    // console.log('lets test get values of submission', methods.getValues());
    resetForm(methods, mySubmissionDefaultValues);
    setRows([]);
    //Below need to reset if the user clicks on cancel to reset options passed to multi enumerated elements
    setTeamMemberOptions([]);
    setCommonVendorOptions([]);
    setUpdate(false);
    setVendorName(null);
    setSavedContractAmendments(null);
    setSelectedSubmissionId(null);
    // console.log('lets test get values of submission', methods.getValues());
    // console.log('lets also check default values object', mySubmissionDefaultValues);
    setSnackBarOpenStatus(false);
    setSubmissionData(null);
    toggleDrawer('right', false)(e);
  };
  const cancelClickHandler = (e) => {
    // console.log('lets test get values of submission', methods.getValues());
    // console.log('lets test isDirty of submission', methods.formState.isDirty);
    // console.log('lets test submissionData', submissionData);
    // console.log('lets see dirty fields', methods.formState.dirtyFields);
    if (methods.formState.isDirty && submissionData === null) {
      setNavBarCancelClickedStatus(true);
      return;
    }
    if (methods.formState.isDirty && update) {
      // console.log('I am in cancel if ');
      setNavBarCancelClickedStatus(true);
      return;
    }
    //Reset the form
    resetForm(methods, mySubmissionDefaultValues);
    setRows([]);
    //Below need to reset if the user clicks on cancel to rest options passed to multi enumerated elements
    setTeamMemberOptions([]);
    setCommonVendorOptions([]);
    setVendorName(null);
    setSavedContractAmendments(null);
    setSelectedSubmissionId(null);
    setSnackBarOpenStatus(false);
    setUpdate(false);
    // methods.reset({}, { keepDirty: false });
    setSubmissionData(null);
    toggleDrawer('right', false)(e);
  };
  // const handleSnackBarClose = () => {
  //   setSnackBarOpenStatus(false);
  // };
  const navbarDeleteClickHandler = (selected, buttonType) => {
    // console.log('letts see navbarDeleteclick handler');
    if (buttonType === 'delete') {
      setMyDeleteFlag(false);
    } else {
      setMyDeleteFlag(true);
    }
    setDeleteSubmissionList(selected);
    setNavBarDeleteClickedStatus(true);
  };
  const deleteHandler = async () => {
    setNavBarDeleteClickedStatus(false);
    // console.log('deletHandler executed');
    // Logic to impliment delete
    if (deleteSubmissionList.length > 0) {
      const list = deleteSubmissionList.join();
      try {
        // const response = await execute(
        //   'DELETE',
        //   protectedResources.apiCreateList.getDeleteSubmission(
        //     // Number(deleteSubmissionList[0])
        //     list
        //   )
        // );
        let response;
        if (myDeleteFlag) {
          response = await execute(
            'DELETE',
            protectedResources.apiCreateList.getUndeleteSubmission(list)
          );
        } else {
          response = await execute(
            'DELETE',
            protectedResources.apiCreateList.getDeleteSubmission(list)
          );
        }
        // console.log(response);

        if (response.status && response.status !== 200) {
          setSnackBarOpenStatus(true);
          setSeverityLevel('error');
          // setSnackbarMessage(procurementDetails.message);
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_DELETE_SUBMISSION['error'](response.message)
          );
        } else {
          setSnackBarOpenStatus(true);
          setSeverityLevel('success');
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_DELETE_SUBMISSION['success'](response.message)
          );
          updateProcurementData(procData['tombstone']['DE001']);
        }
      } catch (error) {
        // console.log("let's see error", error);
        setSnackBarOpenStatus(true);
        setSeverityLevel('error');
        setSnackbarMessage(SEVERITY_WISE_ALERT_MESSAGE_ON_DELETE_SUBMISSION['error']());
      }
    }
  };
  // const resetClickHandler = (event) => {
  //   setResetClickedStatus(true);
  //   if (event.target.id === 'evaluation') {
  //     setEvaluationContractResetDialogHeader(true);
  //   } else {
  //     setEvaluationContractResetDialogHeader(false);
  //   }
  // };
  const resetContractAmendmentHandler = () => {
    const customEvent = {
      nativeEvent: {
        submitter: {
          id: 'customSubmitterId'
        }
      }
    };
    methods.handleSubmit((data) => onSaveDetails(data, customEvent))();
  };
  // const resetHandler = (event) => {
  //   const myValues = methods.getValues();
  //   // Below if else to handle reset click button on Evaluation and Contract TAB
  //   if (event.target.id === 'evaluation') {
  //     methods.reset({
  //       submission: {
  //         ...myValues.submission,
  //         evaluation: {
  //           ...mySubmissionDefaultValues.submission.evaluation
  //         }
  //       }
  //     });
  //   } else {
  //     methods.reset({
  //       submission: {
  //         ...myValues.submission,
  //         contract: {
  //           ...mySubmissionDefaultValues.submission.contract,
  //           contractAmendment: myValues.submission.contract.contractAmendment
  //         }
  //       }
  //     });
  //     // setMyDE189Value(myValues.submission.contract.DE189);
  //   }
  //   const customEvent = {
  //     ...event,
  //     nativeEvent: {
  //       ...event.nativeEvent,
  //       submitter: {
  //         id: event.target.id
  //       }
  //     }
  //   };
  //   methods.handleSubmit((data) => onSaveDetails(data, customEvent))();
  //   setResetClickedStatus(false);
  // };

  const onSaveDetails = async (data, event) => {
    // console.info('Submission data onSave: ', data);
    // console.info('Submission data onSave: ', event);
    let dataCopy = formatSubmissionDatesRecursively(data);
    // console.log('lets check dataCopy', dataCopy);
    if (selectedSubmissionId) {
      try {
        const response = await execute(
          'PUT',
          protectedResources.apiCreateList.getUpdateSubmission(
            procData.tombstone['DE001'],
            selectedSubmissionId
          ),
          dataCopy
        );
        // START
        // setSnackBarOpenStatus(true);
        // setSeverityLevel('success');
        // setSnackbarMessage(SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_SUBMISSION['success']());
        // setSubmissionData(response);
        // END
        if (response && response.submission) {
          setSnackBarOpenStatus(true);
          setSeverityLevel('success');
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_SUBMISSION['success'](response.message)
          );
          setSubmissionData(response);
          // START
          // const myValues = methods.getValues();
          // let deepCopy = JSON.parse(JSON.stringify(myValues));
          // methods.reset({
          //   submission: {
          //     ...deepCopy.submission,
          //     evaluation: {
          //       ...deepCopy.submission.evaluation
          //     },
          //     contract: {
          //       ...deepCopy.submission.contract
          //     },
          //     teamMembers: [...deepCopy.submission.teamMembers]
          //   }
          // });
          // END
          methods.reset({
            submission: {
              // ...deepCopy.submission,
              ...response['submission'],
              evaluation: {
                // ...deepCopy.submission.evaluation
                ...response['submission']['evaluation']
              },
              contract: {
                // ...deepCopy.submission.contract
                ...response['submission']['contract']
              },
              // teamMembers: [...deepCopy.submission.teamMembers]
              teamMembers: [...response['submission'].teamMembers]
            }
          });
          // setMyDE189Value(deepCopy.submission.contract.DE189);
          setVendorName(response['submission']['DE051']);
          setSavedContractAmendments(response['submission']['contract']);
          setUpdate(true);
          // Below is to update the list when new submission gets added
          updateProcurementData(procData['tombstone']['DE001']);
        } else {
          setSnackBarOpenStatus(true);
          setSeverityLevel('error');
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_SUBMISSION['error'](response.message)
          );
          return;
        }
        if (event.nativeEvent['submitter']['id'] === 'saveAndClose') {
          resetForm(methods, mySubmissionDefaultValues);
          // replace([]);
          setRows([]);
          //Below need to reset if the user clicks on cancel to reset options passed to multi enumerated elements
          setTeamMemberOptions([]);
          setCommonVendorOptions([]);
          setVendorName(null);
          setSavedContractAmendments(null);
          setSelectedSubmissionId(null);
          // setSnackBarOpenStatus(false);
          setSubmissionData(null);
          toggleDrawer('right', false)(event);
        }
        // console.log('execute method');
        // console.log('response', response);
      } catch (error) {
        // console.log('lets see the ereor', error);
        setSnackBarOpenStatus(true);
        setSeverityLevel('error');
        setSnackbarMessage(SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_SUBMISSION['error']());
      }
    } else {
      try {
        const response = await execute(
          'POST',
          protectedResources.apiCreateList.getSubmissionEndpoint(procData.tombstone['DE001']),
          dataCopy
        );
        // START
        // setSnackBarOpenStatus(true);
        // setSeverityLevel('success');
        // setSnackbarMessage(SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_SUBMISSION['success']());
        // setSubmissionData(response);
        // END
        if (response && response.submission) {
          setSnackBarOpenStatus(true);
          setSeverityLevel('success');
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_SUBMISSION['success'](response.message)
          );
          setSubmissionData(response);
          setVendorName(response['submission']['DE051']);
          setSavedContractAmendments(response['submission']['contract']);
          setSelectedSubmissionId(String(response['submission']['subId']));
          // const myValues = methods.getValues();
          // let deepCopy = JSON.parse(JSON.stringify(myValues));
          methods.reset({
            submission: {
              // ...deepCopy.submission,
              ...response['submission'],
              evaluation: {
                // ...deepCopy.submission.evaluation
                ...response['submission']['evaluation']
              },
              contract: {
                // ...deepCopy.submission.contract
                ...response['submission']['contract']
              },
              // teamMembers: [...deepCopy.submission.teamMembers]
              teamMembers: [...response['submission'].teamMembers]
            }
          });
          // END
          setUpdate(true);
          //START
          updateProcurementData(procData['tombstone']['DE001']);
          // END
        } else {
          setSnackBarOpenStatus(true);
          setSeverityLevel('error');
          setSnackbarMessage(
            SEVERITY_WISE_ALERT_MESSAGE_ON_UPDATE_SUBMISSION['error'](response.message)
          );
          return;
        }
        if (event.nativeEvent['submitter']['id'] === 'saveAndClose') {
          resetForm(methods, mySubmissionDefaultValues);
          // replace([]);
          setRows([]);
          //Below need to reset if the user clicks on cancel to reset options passed to multi enumerated elements
          setTeamMemberOptions([]);
          setCommonVendorOptions([]);
          setVendorName(null);
          setSavedContractAmendments(null);
          setUpdate(false);
          setSelectedSubmissionId(null);
          setSnackBarOpenStatus(false);
          setSubmissionData(null);
          toggleDrawer('right', false)(event);
        }
        // console.log('execute method');
        // console.log('response', response);
      } catch (error) {
        // console.log('lets see the ereor', error);
        setSnackBarOpenStatus(true);
        setSubmissionData(null);
        setSeverityLevel('error');
        setSnackbarMessage(SEVERITY_WISE_ALERT_MESSAGE_ON_SAVE_SUBMISSION['error']());
      }
    }

    // methods.setValue(`submission.DE051`, 'hello');
    // methods.setValue(`submission.teamMembers`, [
    //   { DE060: 'h', DE061: ['3'], DE062: '2', selected: false },
    //   { DE060: 'hello', DE061: ['4'], DE062: '3', selected: false }
    // ]);
  };

  return (
    <div>
      {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
      <FormProvider {...methods}>
        <SubmissionList
          toggleDrawer={toggleDrawer}
          resetFormHandler={resetFormHandler}
          procData={procData}
          updateSubmissionId={updateSubmissionId}
          navbarDeleteClickHandler={navbarDeleteClickHandler}
        />
        <Drawer
          anchor="right"
          open={drawerState}
          onClose={toggleDrawer('right', false)}
          // sx={{maxWidth:500}}
          sx={{
            width: '10vw',
            maxWidth: '80vw',
            flexShrink: 0
            // border: '1px solid red'
          }}>
          <form onSubmit={methods.handleSubmit(onSaveDetails)}>
            <Box
              sx={{
                // border: '1px solid red',
                // height: 'calc(100vh - 60px)',
                height: '100vh',
                // maxWidth: '80vw',
                maxWidth: '100vw',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                  width: 0,
                  height: 0
                }
                // border: '2px solid orange'
              }}
              // ref={endOfPageRef}
            >
              {/* START */}
              <StyledStickyBox
                sx={{
                  // maxWidth: '80vw',
                  maxWidth: '100vw'
                }}
                top="0px">
                <StyledBox>
                  <Box
                    className="textHeader"
                    sx={{
                      width: '100%',
                      height: '100%',
                      flexWrap: 'nowrap',
                      wordWrap: 'break-word',
                      overflow: 'scroll'
                    }}>
                    <Typography>{vendorName ? vendorName : '--'}</Typography>
                  </Box>
                </StyledBox>
              </StyledStickyBox>
              {/* END */}
              <StyledTabsBox>
                <StyledStickyBox top="60px">
                  <StyledTabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    // sx={{ border: '1px solid black', padding: '0px 2rem' }}
                  >
                    <StyledTab label="Submission" />
                    <StyledTab label="Evaluation" />
                    <StyledTab label="Contract" />
                  </StyledTabs>
                </StyledStickyBox>

                {/*{formErrorMessage && <ErrorMessageComponent />}*/}

                <Box
                  sx={{
                    minHeight: '400px',
                    //  maxWidth: '80vw',
                    maxWidth: '100vw',
                    p: 2,
                    paddingBottom: '55px'
                  }}
                  ref={endOfPageRef}>
                  {tabValue === 0 && (
                    <DrawerTabSubmission
                      data={data}
                      rows={rows}
                      setRows={setRows}
                      selectedSubmissionId={selectedSubmissionId}
                    />
                  )}
                  {tabValue === 1 && (
                    <DrawerTabEvaluation
                      data={data}
                      // resetClickHandler={resetClickHandler}
                    />
                  )}
                  {tabValue === 2 && (
                    <DrawerTabContract
                      data={data}
                      // resetClickHandler={resetClickHandler}
                      selectedSubmissionId={selectedSubmissionId}
                      reqFormNumber={reqFormNumber}
                      scrollToBottomHandler={scrollToBottomHandler}
                      resetContractAmendmentHandler={resetContractAmendmentHandler}
                      savedContractAmendments={savedContractAmendments}
                      // myDE189Value={myDE189Value}
                    />
                  )}
                </Box>
              </StyledTabsBox>
              {/* START */}
              <StyledStickyBox bottom="0px">
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="flex-end"
                  // sx={{ p: 2, border: '1px solid blue', height: '20px' }}
                >
                  <Button
                    type="button"
                    size="small"
                    onClick={(e) => {
                      cancelClickHandler(e);
                    }}>
                    <Typography>Cancel</Typography>
                  </Button>
                  <Button id="saveAndClose" variant="outlined" type="submit" size="small">
                    <Typography>
                      {selectedSubmissionId ? 'Update & Close' : 'Save & Close'}
                    </Typography>
                  </Button>
                  <StyledButton variant="contained" type="submit" size="small">
                    <Typography>{selectedSubmissionId ? 'Update' : 'Save'}</Typography>
                  </StyledButton>
                </Stack>
              </StyledStickyBox>
              {/* END */}
            </Box>

            {navBarCancelClickedStatus && (
              <DialogAlertComponent
                descriptionHeader={NAV_BAR_DIALOG_ALERT.descriptionHeader}
                descriptionBody={NAV_BAR_DIALOG_ALERT.descriptionBody}
                popupStatus={navBarCancelClickedStatus}
                button1Handler={stayPageHandler}
                button1Label={NAV_BAR_DIALOG_ALERT.button1Label}
                button2Label={NAV_BAR_DIALOG_ALERT.button2Label}
                button2Handler={leavePageHandler}
              />
            )}
          </form>
        </Drawer>
        {navBarDeleteClickedStatus && (
          <DialogAlertComponent
            descriptionHeader={
              myDeleteFlag
                ? NAV_BAR_DIALOG_SUBMISSION_UNDELETE_ALERT.descriptionHeader
                : NAV_BAR_DIALOG_SUBMISSION_DELETE_ALERT.descriptionHeader
            }
            descriptionBody={
              myDeleteFlag
                ? NAV_BAR_DIALOG_SUBMISSION_UNDELETE_ALERT.descriptionBody
                : NAV_BAR_DIALOG_SUBMISSION_DELETE_ALERT.descriptionBody
            }
            popupStatus={navBarDeleteClickedStatus}
            button1Handler={deleteHandler}
            button1Label={
              myDeleteFlag
                ? NAV_BAR_DIALOG_SUBMISSION_UNDELETE_ALERT.button1Label
                : NAV_BAR_DIALOG_SUBMISSION_DELETE_ALERT.button1Label
            }
            button2Label={NAV_BAR_DIALOG_SUBMISSION_DELETE_ALERT.button2Label}
            button2Handler={stayPageHandler}
          />
        )}
        {/* {resetClickedStatus && (
          <DialogAlertComponent
            descriptionHeader={
              evaluationContractResetDialogHeader
                ? DIALOG_SUBMISSION_EVALUATION_ALERT.descriptionHeader
                : DIALOG_SUBMISSION_CONTRACT_ALERT.descriptionHeader
            }
            descriptionBody={
              evaluationContractResetDialogHeader
                ? DIALOG_SUBMISSION_EVALUATION_ALERT.descriptionBody
                : DIALOG_SUBMISSION_CONTRACT_ALERT.descriptionBody
            }
            popupStatus={resetClickedStatus}
            button1Handler={resetHandler}
            button1Label={DIALOG_SUBMISSION_EVALUATION_ALERT.button1Label}
            button2Label={DIALOG_SUBMISSION_EVALUATION_ALERT.button2Label}
            button2Handler={stayPageHandler}
            id={evaluationContractResetDialogHeader ? 'evaluation' : 'contract'}
          />
        )} */}
        {/*<Button type="submit">Submit</Button>*/}
        {/*</form>*/}
      </FormProvider>
    </div>
  );
}

export default RenderDrawerComponent;
RenderDrawerComponent.propTypes = {
  data: PropTypes.object,
  requestFormHandler: PropTypes.func,
  procData: PropTypes.object,
  updateProcurementData: PropTypes.func,
  reqFormNumber: PropTypes.string
};
