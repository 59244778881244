const stagingDataElementArray = [
  {
    id: 'DE026',
    name: 'Cancellation Date',
    family: 'staging',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description: 'The date on which the procurement was cancelled, if applicable.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE211',
    name: 'Targeted Award Date',
    family: 'staging',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description: `The targeted date for the award of contracts based on pre-transactional discussions between the Business Unit and Procurement. Not to be updated after a Procurement Lead has been assigned and to be used exclusively for administrative resourcing purposes.`,
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE027',
    name: 'Document Development Phase Start Date',
    family: 'staging',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      isNextDateValid: true,
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description:
      'The date on which Business Units first engage, or are first engaged by, the Procurement Lead for the purposes of drafting RFx documents or agreements. Engagement may include formal kick-off meetings, emails, phone calls, etc.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE028',
    name: 'Document Development Phase End Date',
    family: 'staging',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      isNextDateValid: true,
      sequence: true,
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description:
      'The date on which the RFx documents or agreements are finalized, including approvals.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE029',
    name: 'Market Phase Start Date',
    family: 'staging',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      isNextDateValid: true,
      sequence: true,
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description:
      'The date on which RFx documents are posted to a tendering platform. Platforms include AWARD, MERX, Biddingo, email, etc.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE030',
    name: 'Market Phase End Date',
    family: 'staging',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      isNextDateValid: true,
      sequence: true,
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description:
      'The last date on which IO will accept vendor submissions, also referred to as the ‘submission deadline’. Procurements without formal submission deadlines, single/sole sourced procurements, are not required to record a Market End date unless one has been formally included with the RFx document or agreement.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE031',
    name: 'Evaluation Phase Start Date',
    family: 'staging',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      isNextDateValid: true,
      sequence: true,
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description:
      'The date on which the assessment of submission compliance and/or completeness begins.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE032',
    name: 'Evaluation Phase End Date',
    family: 'staging',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      isNextDateValid: true,
      sequence: true,
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description:
      'The date on which all evaluations have been approved by the Evaluation Committee or, in cases where no Evaluation Committee exists, the date on which the Procurement Lead finalizes the evaluation results.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE033',
    name: 'Award Phase Start Date',
    family: 'staging',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      isNextDateValid: true,
      sequence: true,
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description:
      'The date after the finalized evaluations results were presented to Project Delivery Steering Committee (PDSC), or equivalent, or the date of the final PCAR2 approval.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE034',
    name: 'Award Phase End Date',
    family: 'staging',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      isNextDateValid: true,
      sequence: true,
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description:
      'The date on which the procurement was finalized, excluding debriefs. For procurements producing a contact or agreement, the Award End date is the date of contract/agreement execution. For procurements producing notification to vendors of their prequalified status the Award End date is the date of such notification.',
    hasDescription: true,
    isDisplayed: true
  }
];
export default stagingDataElementArray;
