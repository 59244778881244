import { useState, useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import FileImport from 'components/FileImport';
import FileExport from 'components/FileExport';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Drawer, Snackbar, Alert } from '@mui/material';
import {
  SharedIcon,
  StyledCard,
  StyledCardContent,
  StyledCardHeaderTypogarphy,
  StyledCardTypogarphy
} from './styled';
import { UserContext, USER_TYPE_SUPER } from 'features/auth/userContext';

export default function ActionAreaCard() {
  const [drawerState, setdrawerState] = useState({ state: false, component: '' });
  const [errorAlert, seterrorAlert] = useState(false);
  const userinfo = useContext(UserContext);
  const toggleDrawer =
    (stateType = false, componentType = '') =>
    (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      if (
        userinfo.userType != USER_TYPE_SUPER &&
        ['IMPORT', 'EXPORT'].indexOf(componentType) != -1
      ) {
        seterrorAlert(true);
        return;
      }
      setdrawerState({ state: stateType, component: componentType });
    };
  const onSuccess = () => {
    setdrawerState({ state: false, component: '' });
  };
  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center',
          flex: 1,
          minHeight: 300
        }}>
        <StyledCard component={Link} to="/common">
          <SharedIcon />
          <StyledCardContent>
            <StyledCardHeaderTypogarphy gutterBottom variant="h3" component="div">
              Add Procurement
            </StyledCardHeaderTypogarphy>
            <StyledCardTypogarphy variant="body2">Create a new Procurement</StyledCardTypogarphy>
            {/*<StyledCardTypogarphy variant="body2" color="text.primary">*/}
            {/*  Maximum file size 10 MB and maximum on the number of procurements per file.*/}
            {/*</StyledCardTypogarphy>*/}
          </StyledCardContent>
        </StyledCard>
        <StyledCard component={Link} to="/" onClick={toggleDrawer(true, 'IMPORT')}>
          <SharedIcon as={UploadIcon} />
          <StyledCardContent>
            <StyledCardHeaderTypogarphy gutterBottom variant="h3" component="div">
              Import
            </StyledCardHeaderTypogarphy>
            {/*<StyledCardTypogarphy variant="body2" color="text.primary">*/}
            {/*  Maximum file size 10 MB and maximum on the number of procurements per file.*/}
            {/*</StyledCardTypogarphy>*/}
            <StyledCardTypogarphy variant="body2" color="text.primary">
              Import Procurement
            </StyledCardTypogarphy>
          </StyledCardContent>
        </StyledCard>
        <StyledCard component={Link} to="/" onClick={toggleDrawer(true, 'EXPORT')}>
          {/*<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>*/}
          {/*  <StyledIconBox>*/}
          {/*    <CiExport />*/}
          {/*  </StyledIconBox>*/}
          {/*</Box>*/}
          {/*<DownloadIcon sx={{ width: '40px', height: '40px', border: '1px solid blue' }} />*/}
          <SharedIcon as={DownloadIcon} />
          <StyledCardContent>
            <StyledCardHeaderTypogarphy gutterBottom variant="h3" component="div">
              Export
            </StyledCardHeaderTypogarphy>
            {/*<StyledCardTypogarphy variant="body2" color="text.primary">*/}
            {/*  Maximum file size 10 MB and maximum on the number of procurements per file.*/}
            {/*</StyledCardTypogarphy>*/}
            <StyledCardTypogarphy variant="body2" color="text.primary">
              Export Procurement
            </StyledCardTypogarphy>
          </StyledCardContent>
        </StyledCard>
      </Box>
      <Drawer anchor={'right'} open={drawerState.state}>
        <Box sx={{ width: '100vh' }} role="presentation">
          {drawerState.component == 'IMPORT' && (
            <FileImport onCancel={toggleDrawer} onSuccess={onSuccess} />
          )}
          {drawerState.component == 'EXPORT' && (
            <FileExport onCancel={toggleDrawer} onSuccess={onSuccess} />
          )}
        </Box>
      </Drawer>
      <Snackbar open={errorAlert} onClose={() => seterrorAlert(false)} autoHideDuration={5000}>
        <Alert
          onClose={() => seterrorAlert(false)}
          variant="filled"
          severity="error"
          sx={{ width: '100%' }}>
          You are not authorized to perform this action.
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
