import * as React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import ImportModalSuccessView from './ImportModalSuccessView';
import ImportModalFailureView from './ImportModalFailureView';
import ImportModalInProgressView from './ImportModalInProgressView';
import './styles.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 580,
  borderRadius: '10px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export default function ImportModal({
  open,
  isTxnCompleted,
  successFlag,
  totalCount,
  successCount,
  failureCount,
  onPopupClose,
  reason,
  rejectReasonList
}) {
  const handleClose = () => {
    onPopupClose();
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          {isTxnCompleted == false && <ImportModalInProgressView />}
          {isTxnCompleted == true && successFlag == true && (
            <ImportModalSuccessView
              failureCount={failureCount}
              successCount={successCount}
              totalCount={totalCount}
              rejectReasonList={rejectReasonList}
            />
          )}
          {isTxnCompleted == true && successFlag == false && (
            <ImportModalFailureView reason={reason} />
          )}
          {isTxnCompleted == true && (
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ color: 'white', background: '#01a8c4', marginTop: '5vh' }}>
              OK
            </Button>
          )}
        </Box>
      </Modal>
    </div>
  );
}

ImportModal.propTypes = {
  open: PropTypes.bool,
  isTxnCompleted: PropTypes.bool,
  successFlag: PropTypes.bool,
  totalCount: PropTypes.number,
  successCount: PropTypes.number,
  failureCount: PropTypes.number,
  onPopupClose: PropTypes.func,
  reason: PropTypes.string,
  rejectReasonList: PropTypes.array
};
