import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './styles.css';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import ImportModalRejectReasonView from './ImportModalRejectReasonView';

export default function ImportModalSuccessView({
  totalCount,
  successCount,
  failureCount,
  rejectReasonList
}) {
  const [rejectReasonViewOpen, setrejectReasonViewOpen] = React.useState(false);

  const onRejectReasonViewOpen = () => {
    setrejectReasonViewOpen(true);
  };
  const onRejectReasonViewClose = () => {
    setrejectReasonViewOpen(false);
  };

  return (
    <React.Fragment>
      <CheckCircleIcon className="sucessIcon" />
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Success
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Your file has been imported
      </Typography>
      <Box className="spacingTextImport">
        <Typography sx={{ mt: 2, fontSize: '16px', fontWeight: '500' }}>
          {`Total number of procurements processed`}
        </Typography>
        <Typography sx={{ mt: 2, fontSize: '16px', fontWeight: '500' }}>{totalCount}</Typography>
      </Box>

      <Box className="spacingTextImport">
        <Box display={'flex'}>
          <DoneIcon sx={{ fontSize: '20px', color: 'green' }} />
          <Typography sx={{ fontSize: '15px', color: 'green' }}>{`Sucessfull records`}</Typography>
        </Box>
        <Typography sx={{ fontSize: '15px', color: 'green' }}>{successCount}</Typography>
      </Box>

      <Box className="spacingTextImport">
        <Box display={'flex'}>
          <CloseIcon sx={{ fontSize: '20px', color: 'red' }} />
          <Typography sx={{ fontSize: '15px', color: 'red' }}>{`Rejected records`}</Typography>
        </Box>
        <Typography sx={{ fontSize: '15px', color: 'red' }}>{failureCount}</Typography>
      </Box>

      <hr className="hr" />
      <Box sx={{ cursor: 'pointer' }} onClick={onRejectReasonViewOpen} display={'flex'}>
        <Typography sx={{ fontSize: '15px', fontWeight: '500', marginRight: '5px' }}>
          {`Rejected Reason`}
        </Typography>
        <LaunchIcon sx={{ fontSize: '18px' }} />
      </Box>
      <ImportModalRejectReasonView
        open={rejectReasonViewOpen}
        rejectReasonList={rejectReasonList}
        onrejectResonViewClose={onRejectReasonViewClose}
      />
    </React.Fragment>
  );
}

ImportModalSuccessView.propTypes = {
  open: PropTypes.bool,
  isTxnCompleted: PropTypes.bool,
  successFlag: PropTypes.bool,
  totalCount: PropTypes.number,
  successCount: PropTypes.number,
  failureCount: PropTypes.number,
  rejectReasonList: PropTypes.array
};
