import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import theme from 'theme';
import { RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import AppRouter from 'routes';
// import { PublicClientApplication } from '@azure/msal-browser';
// import { MsalProvider } from '@azure/msal-react';
// import { msalConfig } from 'features/auth/config';
import ThemeContextProvider from './ThemeContext';
import { UserContext } from 'features/auth/userContext';
import { useEffect, useState } from 'react';
//import { getAuth } from 'features/auth/getAuth';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'features/auth/config';
const { getUserURL } = protectedResources.apiCreateList.getAuthConfig();
import { processAuthResponse } from 'features/auth/processAuthResponse';

function App() {
  const [user, setUser] = useState();
  const { execute } = useFetchWithMsal();
  const getAuth = async () => {
    try {
      const response = await execute('GET', getUserURL);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAuth()
      .then((response) => {
        setUser(processAuthResponse(response));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <ThemeContextProvider>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          {/*<MsalProvider instance={msalInstance}>*/}
          <UserContext.Provider value={user}>
            <RouterProvider router={AppRouter} />
          </UserContext.Provider>
          {/*</MsalProvider>*/}
        </MuiThemeProvider>
      </ThemeContextProvider>
    </>
  );
}

export default App;
